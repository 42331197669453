import RdcSelect from './RdcSelect';

export type RdcOptionsType = Array<RdcOptionType>;

export type RdcOptionType = {
	id: ValueType,
	title?: string
} | string | number;


export type ValuesType = ValueType | ValueType[];
export type ValueType = string | number;

export enum UpdateListStates {
	Idle, 
	Loading,
	Updated
};

export default RdcSelect;
