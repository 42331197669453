import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SearchInput, EmptyPanel } from '..';
import { ITreeListProps, TreeListItemType, useListExpandedByCurrent, useListSearchedItems } from '.';
import TreeListItem from './TreeListItem/TreeListItem';
import './TreeList.style.scss';

/** Древовидный список */
const TreeList: React.FC<ITreeListProps> = ( props ) => {
	
	const { id, items, className, paddingEnclose = 24, disable = false,
		selectedItem = "", searchEnable = false, autoScroll = false,
		onClick, renderToolbar 
	} = props;

	const [ search, setSearch ] = useState<string>("");
	const preparedItems = useListExpandedByCurrent( items, selectedItem );
	const filtering = useListSearchedItems(preparedItems, search);

	/** Тут смотрим за элементами, выбранным элементом и скролиим к нему */
	useEffect( () => {
		if ( autoScroll && !!selectedItem ) {
			const element = document.getElementById( "treelist_item_active" );
			if ( !!element ) {
				element.scrollIntoView(	{behavior: "smooth", block: "start", inline: "start"});
			}
		}
	}, [ autoScroll, filtering, selectedItem ] );

	/** Вызов внешнего метода выбора реестра  */
	const onClickHandler = ( item: TreeListItemType ) => !!onClick && onClick(item);

	const renderSelected = () => {
		const exist = filtering.findIndex( e => e.id === selectedItem ) !== -1;
		if ( exist ) return null;
		const selected = preparedItems.find( p => p.id === selectedItem );
		if ( !!selected ) {
			const index = filtering.length;
			return (
				<TreeListItem 
					index={ index }
					paddingEnclose={paddingEnclose}
					activePreset={selectedItem}
					key={ !!selected.id ? selected.id : index } 
					item={selected} 
					onClick={onClickHandler}
					renderToolbar={renderToolbar}
				/>	
			);
		}
	};

	/** Отрисовка списка */
	const renderList = () => (
		<ul id={id} className={classNames( "treelist", { "searching": searchEnable }) }>
			{ renderSelected() }
			{
				filtering.map( (i, index) => (
					<TreeListItem 
						index={index}
						paddingEnclose={paddingEnclose}
						activePreset={selectedItem}
						key={ !!i.id ? i.id : index } 
						item={i} 
						onClick={onClickHandler}
						renderToolbar={renderToolbar}
					/>
				))
			}
			{ !filtering.length && <EmptyPanel text="Не найдено" /> }
		</ul>
	);

	return (
		<div className={classNames("treelist-wrapper", className, { "disabled": disable } )}>
			{ 
				!!searchEnable && 
				<SearchInput 
					id="input_search-reestr"
					placeholder='Введите название реестра' 
					minLength={1}
					onSearch={setSearch}
					debounceDelay={500}
				/> 
			}
			{ renderList() }
		</div>
	);
};

export default TreeList;
