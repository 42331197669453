import API from ".";

export default class CashApi {

	static reset = () => {
		let keys = Object.keys(localStorage);
		console.log( keys );
		for(let key of keys) {
			window.RDC.localStorage.removeItem( key );
		}
		API.Toast.show( true, "Кэш браузера сброшен. Перезагрузка страницы" );
		setTimeout( () => {
			window.location.reload();
		}, 1500 );
	};

};