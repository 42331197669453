import classNames from 'classnames';
import ClearBtn from 'src/Components/ClearBtn/ClearBtn';
import { ReactComponent as SearchIcon } from 'src/Themes/assets/search.svg';
import TextHint from 'src/Components/TextHint';
import './TextBox.scss';
import React from 'react';

interface ITextBoxProps extends React.HTMLAttributes<HTMLDivElement> {
	isPlaceHolder?: boolean;
	value: string;
	setValue: Function;
	onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	readOnly?: boolean;
	autoFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	isClearBtn?: boolean;
	withoutIcon?: boolean;
	isField?: boolean;
}

/**
 * Текстовое поле ввода
 */
const TextBox: React.FC<ITextBoxProps> = (props) => {

	const { isPlaceHolder = false, id, value, setValue, disabled = false, readOnly = false, className, autoFocus = false,
		isClearBtn = true, withoutIcon = false, placeholder, isField = false, onClick, onClear, ...otherProps 
	} = props;

	const onChangeValueHandler = (e: any) => {
		e.persist();
		if ( !readOnly || e.target.value === '' )
			setValue(e.target.value);
	}

	const onClearHandler = ( e: any ) => {
		e.stopPropagation();
		e.preventDefault();
		if ( isPlaceHolder )
			!!onClear && onClear(e);
		else 
			setValue('');
	}

	const renderContent = () => {
		if ( isPlaceHolder ) {
			return (
				<TextHint className="rdc_control__value" >
					{ value }
				</TextHint>
			);
		}
		else {
			return (
				<input
					id={ "input-" + id}
					readOnly={readOnly || isPlaceHolder}
					value={value || ''}
					autoFocus={autoFocus}
					className={ classNames('rdc-control__input', className )}
					type='text'
					onChange={onChangeValueHandler}
					disabled={disabled}
					placeholder={placeholder}
					onClick={onClick}
				/> 
			);
		}
	};

	return (
		<div className={classNames("rdc-control", className, { "rdc-control-placeholder": isPlaceHolder, "rdc-control-field": isField })} {...otherProps}>
			{ !withoutIcon && <SearchIcon className="rdc-control__ico" /> }
			{ renderContent() }
			{
				(!!isClearBtn && !!value) &&
				<ClearBtn id={"clear-" + id} className='rdc-control__clear' onClick={onClearHandler} />
			}
		</div>
	);
}

export default TextBox;
