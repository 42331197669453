import { RefObject, useEffect, useRef } from 'react';

const useOutsideClick = <T>( callback: (event: MouseEvent) => void, type: any = "mousedown" ) => {

	const ref = useRef<T>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !(ref.current as any).contains(event.target as Node)) {
				callback(event);
			}
		};
		document.addEventListener( type, handleClickOutside);
		return () => {
			document.removeEventListener( type, handleClickOutside);
		};
	// eslint-disable-next-line
	}, [callback]);

	return ref as RefObject<T>;
};

export default useOutsideClick;
