import { Component, ReactNode } from "react";
import st from "./ErrorBoundary.module.scss";

interface IErrorBoundaryProps {
	children: ReactNode;
};

interface IErrorBoundaryState {
	error: any;
	errorInfo: any;
};

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
	
	constructor( props: IErrorBoundaryProps ) {
		super(props);
		this.state = { error: null, errorInfo: null };
	};
	
	componentDidCatch( error: any, errorInfo: any ) {
		this.setState({
			error: error,
			errorInfo: errorInfo
	  	})
	};
	
	renderError = () => {
		if ( this.state.errorInfo ) {
			return (
				<div className={st.error_wrapper}>
					<div className={st.error_scroll_wr}>
						<div className={st.error_block}>
							<div className={st.error_header}>
								<h2>Произошла ошибка клиентской части приложения :(</h2>
								<button onClick={() => this.setState({ error: null, errorInfo: null })}>X</button>
							</div>
							<details style={{ whiteSpace: 'pre-wrap' }}>
								{ this.state.error && this.state.error.toString() }
								<br />
								{ this.state.errorInfo.componentStack }
							</details>
						</div>
					</div>
				</div>
			);
		}
		else 
			return null;
	};

	render = () => (
		<>
			{ this.renderError() }
			{ this.props.children }
		</>
	);
};

export default ErrorBoundary;
