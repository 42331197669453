
import { useEffect, useState } from "react";
import classNames from "classnames";
import { TextHint, ClearBtn} from "src/Components";
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import CheckBox from "../CheckBox/CheckBox";
import { isEmpty } from "src/Utils";
import { createPortal } from "react-dom";
import { useOutsideClick } from "src/Hooks";
import '../Placeholder.scss';
import "./SelectModal.scss";

export type EnumsType = Array<EnumItemType>;
export type EnumItemType = {
	id: string|number,
	title?: string
} | string;

interface SelectProps extends React.HTMLAttributes<HTMLDivElement> {
	isPlaceholder?: boolean;
	multiple?: boolean;
	isWait?: boolean;
	defaultOpen?: boolean;
	showClearBtn?: boolean;
	placeholder?: string;
	disabled?: boolean;

	isOwnerList?: boolean;
	isBlockArrow?: boolean;

	searchEnable?: boolean;
	minSearchLength?: number;
	onSearch?: ( tern: string ) => void;
	enums?: EnumsType;
	value?: string | Array<string>;
	setValue?: ( value: string|Array<string> ) => void;
	onClear?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onStateChange?: ( type: string, props: any ) => void;
	onClose?: () => void;
};

const Select: React.FC<SelectProps> = (props) => {
	const { id, defaultOpen = false, disabled = false, placeholder, searchEnable = false, showClearBtn = false, isPlaceholder= false, isOwnerList = false, isBlockArrow = false,
		setValue, onSearch, onStateChange, value, enums = [], multiple = false, isWait = false, minSearchLength = 3, onClear, className, onClose, ...otherProps
	} = props;

	const wrapperRef = useOutsideClick<HTMLDivElement>( () => {
		onStateChange && onStateChange( "open", false );
	});
	const [ isOpen, setIsOpen ] = useState<boolean>( !disabled && defaultOpen);
	const [ search, setSearch ] = useState<string>("");
	const [ filteredData, setFilteredData ] = useState<EnumsType>([]);

	useEffect(() => {
		setFilteredData( enums.filter( onApplyFilter ) );
	// eslint-disable-next-line
	}, [enums]);

	useEffect(() => {
		if ( !!onSearch && ( !search || minSearchLength === -1 || search.length >= minSearchLength ) ) {
			onSearch(search);
		} 
		else {
			setFilteredData( enums.filter( onApplyFilter ) );
		}
	// eslint-disable-next-line
	}, [search] );

	const onApplyFilter = ( (item: EnumItemType) => {
		const term = search.toLowerCase() ?? "";
		if ( !term ) return true;
		else if ( typeof item === "string" ) {
			return item.toLocaleLowerCase().indexOf( term ) !== -1;
		} 
		else {
			return item?.title?.toLocaleLowerCase().indexOf( term ) !== -1;
		}
	});

	const getPlaceHolderText = () => {
		const length = 3 - search.length;
		if ( isWait ) 
			return "Ведется поиск...";
		if ( length > 0 && length < 3 ) 
			return `Введите ещё ${length} символа`;
		else 
			return "";
	};

	const getIsChecked = ( item: EnumItemType ): boolean => {
		const id = typeof item === "string" ? item : item?.id;
		if ( !!multiple ) {
			const array = (value as Array<any>) || [] ;
			return array.includes(id);
		} 
		return value === id;
	};

	const onChangeHandler = ( id: string ) => {
		if ( !!setValue ) {
			if ( multiple ) {
				const valueArray = !value ? [] : [ ...(value as string[]) ];
				const index = valueArray.indexOf( id );
				if ( index === -1 ) {
					valueArray.push(id);
				}
				else {
					valueArray.splice( index, 1);
				}
				setValue(valueArray);
			}
			else {
				setValue( id );
			}
		}
	};

	const getTitle = ( id: string): string => {
		const item = enums.find( i => typeof i !== "string" ? i.id === id : i === id ) || id;
		return typeof item === "string" ? item : item.title ?? item.id.toString();
	};

	const getVisibleValue = (): string => {
		if ( !value ) return placeholder || "";
		if ( Array.isArray(value) ) {
			const tmp: Array<string> = [];
			value.forEach( valueItem => {
				tmp.push( getTitle(valueItem) );
			});
			return tmp.join(',');
		}
		return getTitle(value || "");
	};

	const onShowListChange = () => {
		const newValue = !isOpen;
		if ( !disabled && !isBlockArrow ) {
			setIsOpen( newValue );
		}
		if ( !newValue && !!onClose ) {
			onClose();
		}
	}

	const renderOption = ( item: EnumItemType | string, index: number ) => {
		let val: any, title;
		if ( typeof item === "string" ) {
			val = item;
			title = item;
		} 
		else {
			val = item.id;
			title = item.title;
		}
		const isChecked = getIsChecked(val);
		const itemClassses = classNames( "rdcc__listitem", {
			"item-checked": isChecked
		});
		return (
			<li key={index} className={itemClassses} id={ "selectItemFor_" + id + "_val-" + (!!val ? val : "empty") } onClick={ () => onChangeHandler(val)}>
				{ 
					!!multiple &&
					<CheckBox name={val} value={isChecked} />
				}
				<TextHint onParent={true}>
					{title || ""}
				</TextHint>
			</li>
		);
	};

	const renderList = () => {
		if ( multiple ) {
			const selected = enums.filter( i => getIsChecked(i) );
			const available = filteredData.filter( i => !getIsChecked(i) );
			return (
				<div className="wrapper">
					<ul className={classNames("rdcc__body__listitems", { "hr": !!selected.length && !!available.length } )}>
						{ selected.map( renderOption )}
					</ul>
					<ul className="rdcc__body__listitems">
						{ available.map( renderOption )}
					</ul>
				</div>
			);
		} 
		else {
			return (
				<ul className="rdcc__body__listitems">
					{ filteredData.map( renderOption )}
				</ul>
			);
		}
	};

	const getStylesForList = () => {
		if ( !isOwnerList )
			return {};
		const styles = { 
			position: "absolute",
			left: 0, 
			top: 0, 
			zIndex: '350',
			width: 100
		};
		if ( !!wrapperRef.current ) {
			styles.left = wrapperRef.current.offsetLeft;
			styles.top = ( wrapperRef.current.offsetTop + wrapperRef.current.clientHeight);
			styles.width = wrapperRef.current.clientWidth;
		}
		return styles;
	};

	const renderBody = () => {
		if ( isOpen ) {
			return (
				<div className="rdcc__body" style={getStylesForList()}>
					{
						!!getPlaceHolderText() &&
						<p className="rdcc__body__placeholder">{getPlaceHolderText()}</p>
					}
					{
						!filteredData.length &&
						<p className="rdcc__body__placeholder error">По Вашему запросу ничего не найдено</p>
					}
					{renderList()}
				</div>
			);
		}
	};

	if ( isPlaceholder ) {
		return (
			<div className={classNames("rdcc__placeholder", className)} {...otherProps}>
				<ArrowIcon className="rdcc__placeholder__ico" />
				<TextHint isList>{getVisibleValue()}</TextHint>
				<div className="rdcc__header-toolbar">
					{ !!isWait && <div className="spiner" /> }
					{ (!!showClearBtn && !isEmpty(value) ) && <ClearBtn onClick={onClear}/> }
				</div>
			</div>
		);
	}

	return (
		<div className={classNames("rdcc_list-wrapper", className)} ref={wrapperRef} {...otherProps}>
			<div className={"rdcc__header" + (isOpen ? " opened" : "")} >
				<ArrowIcon onClick={onShowListChange} />
				{
					( !!searchEnable || !!placeholder ) &&
					<input 
						autoFocus 
						value={search} 
						disabled={disabled} 
						readOnly={!searchEnable} 
						onChange={ e => setSearch(e.target.value)} 
						placeholder={ !searchEnable ? placeholder : "Поиск по списку" } 
					/>
				}
				<div className="rdcc__header-toolbar">
					{
						!!isWait &&
						<div className="spiner" />
					}
					{
						!!showClearBtn &&
						<ClearBtn 
							disable={!search && !value} 
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								if ( !!search ) setSearch('');
								else if ( !!value && !!setValue ) setValue("")
							}}
						/>
					}
					
				</div>
			</div>
			{ isOwnerList ? createPortal( renderBody(), document.body) : renderBody() }
		</div>
	);
};

export default Select;
