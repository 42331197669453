import classNames from "classnames";
import st from "./Alert.module.scss";

interface IAlertProps extends React.HTMLAttributes<HTMLDivElement> {
    color?: "red";
};

const Alert: React.FC<IAlertProps> = ({ color = "red", className, children, ...otherProps }) => (
    <div className={classNames( st.rdcAlert, className, `rdc-alert-${color}`)} {...otherProps}>
        { children }
    </div>
);

export default Alert;
