import { ReactNode } from "react";
import RdcSubMenu from "./RdcSubMenu";

export type CreateBtnDescType = {
    id?: string;
    title: string;
    action: ( args: any ) => void;
};

export type ToolbarPlaces = "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "footerLeft"; 

export type RenderToolbarHandler = ( place: ToolbarPlaces, otherProps: any ) => ReactNode | JSX.Element;

export default RdcSubMenu;
