import { FC } from "react";
import moment from "moment";
import Badge from "../Badge";
import st from './DateBadge.module.scss';

interface IDateBadgeProps {
	date: Date | string;
};

const DateBadge: FC<IDateBadgeProps> = ( { date } ) => {

	const now =  moment(moment.now()).set( "hour", 0).set( "milliseconds", 0);
	const dueDate = moment(date).set( "milliseconds", 0);
	const diff = now.diff( dueDate, 'days' );

	const getColor = () => {
		if ( diff > 7 )
			return "red";
		else if ( diff >= 3 )
			return "yellow";
		
		return "white";
	};

	return (
		<Badge className={st.badge} align='center' color={getColor()} pill hint={dueDate.format("DD.MM.YYYY, HH:mm")}>
			{ getDayText( diff ) }
		</Badge>
	);
};

const getDayText = ( diff: number ): string => {
	if ( diff === 0 ) 
		return "Сегодня";
	if ( diff > 30 ) 
		return "Более месяца назад";
	if ( diff > 7 )
		return "Более недели назад";
	if ( diff === 1 )
		return "День назад"
	else if ( diff <= 7 && diff > 4 ) 
		return `${diff} дней назад`;
	else if ( diff <= 4 )
		return `${diff} дня назад`;

	return `${diff}`;
};

export default DateBadge;
