import { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Button } from "src/Components";
import { ReactComponent as ScanIcon } from "./assets/skan.svg";
import { ScanConfigType, ScanFileType } from "src/Types/Scan";
import API from "src/API";
import ScanModal from "./ScanModal";

interface IScanProps {
	enable?: boolean;
	setBlobFunction: ( file: ScanFileType, count: number ) => void;
};

const Scan: FC<IScanProps> = ( props ) => {

	const { enable = true, setBlobFunction } = props;

	const [ isWait, setIsWait ] = useState<boolean>(false);
	const [ config, setConfig ] = useState<ScanConfigType>();
	const [ showModal, setShowModal ] = useState<boolean>(false);

	useEffect( () => {
		if ( !enable ) return;
		setIsWait( true );
		API.Scan.getScanConfig()
			.then( result => {
				setConfig( result );
			})
			.finally( () => setIsWait(false) );
	}, [ enable ] );

	const available = enable && !!config && ( !!config.scanModuleInstallUrl || !!config.scanModuleWorkUrl );

	const setBlobFunctionHandler = async ( response: ScanFileType, count: number ) => {
		setBlobFunction( response, count );
		setShowModal( false );
	};

	const renderModal = () => {
		if ( !config ) return null;
		return (
			<ScanModal
				config={config}
				setBlobFunction={setBlobFunctionHandler}
				onClose={ () => setShowModal(false) }
			/>
		);
	};

	return (
		<>
			<Button outline style={{ padding: '2px 12px 2px 6px' }} disable={!available} isWait={isWait} onClick={ () => setShowModal(true) }>
				<ScanIcon />
				Сканировать
			</Button>
			{ showModal && createPortal( renderModal(), document.body ) }
		</>
	);
};

export default Scan;
