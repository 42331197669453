import { FC, ReactNode, useEffect } from "react";
import classNames from "classnames";
import { Button, ClearBtn } from "src/Components";
import { useToggle } from 'src/Hooks';
import st from "./InfoHintContainer.module.scss";
import { ReactComponent as InfoIcon } from 'src/Themes/assets/info.svg';

interface IInfoHintContainerProps {
	id?: string;
	children?: ReactNode;
	title: string;
	maxWidth?: string;
	defaultView?: boolean;
	renderHeader?: ( toggleShow: () => void ) => ReactNode;
	renderModal?: () => ReactNode;
};

/** Компонента-контейнер для отрисовки кнопки-иконки и всплывающего окна/подсказки */
const InfoHintContainer: FC<IInfoHintContainerProps> = ( props ) => {

	const { id = "infoModal", children, title, 
		maxWidth = "35vw", defaultView = false,
		renderHeader, renderModal 
	} = props;
	const [ isShow, toggleShow, setIsShow ] = useToggle(false);

	useEffect( () => {
		setIsShow( defaultView );
	// eslint-disable-next-line
	}, [ defaultView ] );

	const renderInnerHeader = () => {
		if ( !!renderHeader ) {
			return renderHeader( toggleShow );
		} 
		else {
			return (
				<div className={st.modal__header}>
					<h3 className={ classNames( "headline-large", st.modal__header_title) }>
						{ title }
					</h3>
					<ClearBtn isClose onClick={toggleShow} />
				</div>
			);
		}
	};

	return (
		<div className={st.wrapper}>
			<Button 
				id={ id + "_Btn" }
				onClick={ () => toggleShow()}
				className={ classNames( st.btn, 'hint', { [st.active]: isShow } )} 
				outline
				data-hint={title}
			>
				<InfoIcon />
			</Button>
			{
				isShow &&
				<div className={st.modal} style={{ maxWidth: maxWidth }}>
					{ renderInnerHeader() }
					<div className={st.modal__body}>
						{ !!children && children }
						{ renderModal && renderModal() }
					</div>
				</div>
			}
		</div>
	);
};

export default InfoHintContainer;
