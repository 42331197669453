import { useRef } from "react";
import copy from "copy-to-clipboard";
import classNames from "classnames";
import { Button } from "src/Components";
import { TreeListInfoItemType } from ".";
import { ReactComponent as CopyIcon } from 'src/Themes/assets/copy.svg';

interface ITreeListInfoItemProps extends React.HTMLAttributes<HTMLLIElement>, TreeListInfoItemType {
	allowCopy?: boolean;
};

export const TreeListInfoItem: React.FC<ITreeListInfoItemProps> = ( props ) => {
	
	const { subTitle, notMarkerSubTitle = false, allowCopy = false, children, value, className, ...otherProps } = props;
	const valueRef = useRef<HTMLSpanElement>(null);

	/** Метод копирование значения */
	const onCopy = () => {
		if ( !!valueRef.current ) {
			copy( valueRef.current.innerText, {
				debug: window.isDev
			});
		}
	};

	return (
		<li className={classNames("tree-info__item", className)} {...otherProps}>
			<div className="tree-info__item__header">
				{ 
					!!subTitle && 
					<span className={ "tree-info__item__sub-title " + (notMarkerSubTitle ? "" : "tree-info__item__sub-title-mrk" )}>
						{subTitle}
					</span>
				}	
				{ !!allowCopy && <Button outline size="min" onClick={() => onCopy()}><CopyIcon /></Button> }
			</div>
			
			<span ref={valueRef} className="tree-info__item__value">
				{ !!children ? children : (value || "") }
			</span>
		</li>
	);
};
