import { useAppDispatch } from ".";
import { EQAuthParamType } from "src/Types";
import { BeginPauseParamsType, EQueueType } from "src/Types/ReDoc/EQueue";
import { DamaskRedirectParamType } from "src/Types/ReDoc/Damask";
import { 
	damaskGetConfig, damaskSaveConfig, damaskLogin, damaskStart, damaskGetOfficeStations, damaskDrop,
	damaskGetQueueState, damaskGetUserPult, damaskGetPauseReasons, damaskExit, damaskEndServing, damaskCall, damaskRedirect, 
	damaskDelay, damaskBeginServing, damaskRecall, damaskEndPause, damaskBeginPause, damaskCallById, damaskGetStations 
} from 'src/Slicers/eQueue/DamaskThunks';
import { enterGetConfig, enterStart, enterSaveConfig, enterGetStatus, enterCall, enterGetPauseReasons, enterBeginPause, enterEndPause, enterExit, 
	enterGetQueue, enterCallById, enterRecall, enterReject, enterBeginServing, enterGetDenyReasons, enterDelay, enterComplete, enterReason 
} from "src/Slicers/eQueue/EnterThunks";



/** Возвращает объект с методами работы ЭО */
const useEQDispatch = ( type: EQueueType ) => {
	const dispatch = useAppDispatch();
	if ( type === EQueueType.None ) {
		throw new Error( "Не верно указан тип ЭО!" );
	}
	const empty =  { type: "empty" };
	const isDamask = type === EQueueType.Damask;
	const rootAction = isDamask ? "eQueueDamask/" : "eQueueEnter/";
	return {
		// Common
		dispatch: ( action: string, payload?: any ) => dispatch( { type: rootAction + action, payload } ),
		dispatchStd: dispatch,
		getConfig: () => dispatch( isDamask ? damaskGetConfig() : enterGetConfig() ),
		saveConfig: ( payload: EQAuthParamType ) => dispatch( isDamask ? damaskSaveConfig( payload ) : enterSaveConfig( payload ) ),
		// Damask
		getOfficeStations: ( payload: string ) => dispatch( isDamask ? damaskGetOfficeStations( payload ) : empty ),
		getStations: () => dispatch( isDamask ? damaskGetStations() : empty ),
		getUserPult: () => dispatch( isDamask ? damaskGetUserPult() : empty ),
		login: () => dispatch( isDamask ? damaskLogin() : empty ),
		redirect: ( payload: DamaskRedirectParamType ) => dispatch( isDamask ? damaskRedirect(payload) : empty ),

		// Enter
		getQuery: () => dispatch( isDamask ? empty : enterGetQueue() ),
		getDenyReasons: () => dispatch( isDamask ? empty : enterGetDenyReasons() ),
		reason: ( payload: string ) => dispatch( isDamask ? empty : enterReason(payload) ),

		start: ( payload: string ) => dispatch( isDamask ? damaskStart(payload) : enterStart(payload) ),
		getQueueState: () => dispatch( isDamask ? damaskGetQueueState() : enterGetStatus() ),
		getPauseReasons: () => dispatch( isDamask ? damaskGetPauseReasons() : enterGetPauseReasons() ),
		beginPause: ( payload: BeginPauseParamsType ) => dispatch( isDamask ? damaskBeginPause(payload) : enterBeginPause(payload) ),
		endPause: () => dispatch( isDamask ? damaskEndPause() : enterEndPause() ),
		call: () => dispatch( isDamask ? damaskCall() : enterCall() ),
		callById: ( payload: string ) => dispatch( isDamask ? damaskCallById(payload) : enterCallById(payload) ),
		recall: () => dispatch( isDamask ? damaskRecall() : enterRecall() ),
		drop: ( payload: string ) => dispatch( isDamask ? damaskDrop(payload) : enterReject(payload) ),
		beginServing: () => dispatch( isDamask ? damaskBeginServing() : enterBeginServing() ),
		delay: ( payload: string ) => dispatch( isDamask ? damaskDelay(payload) : enterDelay(payload) ),
		endServing: (  payload: string ) => dispatch( isDamask ? damaskEndServing(payload) : enterComplete(payload) ), 

		exit: ( payload: string ) => {
			dispatch ( isDamask ? damaskExit( payload ) : enterExit( payload ) )
		}
	} as const;
};

export default useEQDispatch;
