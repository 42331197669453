import axios from "axios";
import { EditPlainTaskAnswerType, EditPlainTaskRequestBody, ParticipantType, TaskChangeStatusAnswerType, TaskChangeStatusRequestBodyType } from "src/Types/Task";
import BaseApi from "./BaseApi";

/**
 * API для работы с Задачами
 */
export default class APITask extends BaseApi {


	static changeStatus = async ( requestBody: TaskChangeStatusRequestBodyType  ): Promise<TaskChangeStatusAnswerType> => {
		const url = window.host + "/PlainTaskModule/ChangeTaskStatus";
		try {
			const request = await axios.post( url , requestBody, { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка запроса смены статуса." );
			}
			else {
				return request.data;
			}
		}
		catch (error: any) {
			throw new Error("Ошибка запроса смены статуса" );
		}
	};

	static getParticipants = async ( search: string  ): Promise<Array<ParticipantType>> => {
		const url = `${window.host}/WebInterfaceModule/Search/GetParticipants?term=${search}&takeCount=3&skipPageCount=0`;
		try {
			const request = await axios.get( url , { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка пользователей." );
			}
			else {
				return request.data;
			}
		}
		catch (error: any) {
			throw new Error("Ошибка при получении списка пользователей" );
		}
	};

	static changeParticipant = async ( requestBody: Partial<EditPlainTaskRequestBody>  ): Promise<EditPlainTaskAnswerType> => {
		const url = window.host + "/PlainTaskModule/EditPlainTask";
		try {
			const request = await axios.post( url , requestBody, { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка запроса редактирования задачи." );
			}
			else {
				return request.data;
			}
		}
		catch (error: any) {
			throw new Error("Ошибка запроса редактирования задачи." );
		}
	};

};