import { combineReducers } from '@reduxjs/toolkit';

import infoModalSlice from './infoModalSlice';
import waitingSlicer from './waitSlicer';
import authSlicer from './authSlice';
import pluginInfoSlicer from './pluginsSlice';
import panelSlicer from './panelSlicer';
import damaskSlicer from './eQueue/DamaskSlicer';
import enterSlicer from './eQueue/EnterSlicer';

const rootReducer = () =>
	combineReducers({
		infomodal: infoModalSlice.reducer,
		waiting: waitingSlicer.reducer,
		auth: authSlicer.reducer,
		pluginInfo: pluginInfoSlicer.reducer,
		panelState: panelSlicer.reducer,
		eQueueDamask: damaskSlicer.reducer,
		eQueueEnter: enterSlicer.reducer,
	});

export default rootReducer();
