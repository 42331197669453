
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import CheckBox from "../CheckBox/CheckBox";
import classNames from "classnames";
import "./simpleSelect.scss";
import ClearBtn from "src/Components/ClearBtn/ClearBtn";
import { EnumItemType, EnumsType, ValueType } from ".";

interface SimpleSelectProps extends React.AreaHTMLAttributes<HTMLDivElement> {
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	searchEnable?: boolean;
	allowNew?: boolean;
	
	value?: ValueType | Array<ValueType> | null;
	setValue: React.Dispatch<React.SetStateAction<ValueType|ValueType[]>>;

	minSearchLength?: number;
	debouncedDelay?: number;
	onSearch?: ( tern: string ) => void;

	onClose?: () => void;

	options?: EnumsType;
	multiple?: boolean;
	isWait?: boolean;
	defaultOpen?: boolean;
	autoFocus?: boolean;
	showClearBtn?: boolean;
	rtl?: boolean;
	isOwnerList?: boolean;
};

const SimpleSelect: React.FC<SimpleSelectProps> = (props) => {
	
	const { 
		name, allowNew = false, defaultOpen = false, rtl = false, disabled = false, placeholder, showClearBtn = false, className, isOwnerList = false,
		setValue, value, options = [], multiple = false, isWait = false, autoFocus = false,	debouncedDelay = 50, onClose, ...other
	} = props;

	const getTitleValue = ( forPlaceHolder: boolean = false): string => {
		if ( !value && value !== 0 && forPlaceHolder )
			return placeholder || "";
		else if ( typeof options[0] === "object" ) {
			const opt = options.find( (o: any)  => o.id === value ) as any;
			if ( !!opt )
				return opt.title;
			return '';
		}
		else 
			return value?.toString() || "";
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	const [ isOpen, setIsOpen ] = useState<boolean>( !disabled && defaultOpen);
	const [ textValue, setTextValue ] = useState<string>(getTitleValue());

	useEffect(() => {
		/** Слушаем все клики на документе  */
		const onClick = (event: MouseEvent) => {
			// Если клик был не в обалсти контейнера списка
			if ( !wrapperRef.current?.contains(event.target as Node) ) {
				// Закрываем его
				event.stopPropagation();
				!!onClose && onClose();
				setIsOpen(false);
			}
		}
		document.addEventListener('click', onClick);
		return () => document.removeEventListener('click', onClick);
	// eslint-disable-next-line
	}, []);

	const getIsChecked = ( item: EnumItemType ): boolean => {
		const id = typeof item !== "object" ? item : item?.id;
		if ( !!multiple ) {
			return (value as Array<any>).includes(id);
		} 
		return value === id;
	};

	const onChangeHandler = ( id: string, title: string ) => {
		if ( multiple ) {
			const valueArray = [ ...(value as string[]) ];
			const index = valueArray.indexOf( id );
			if ( index === -1 ) {
				valueArray.push(id);
			}
			else {
				valueArray.splice( index, 1);
			}
			setValue(valueArray);
		}
		else {
			setValue( id );
			!!onClose && onClose();
			setIsOpen(false);
		}
		setTextValue(title);
	};

	const onChangeTextHandler = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const val = e.target.value;
		setTextValue(val);
		if ( typeof options[0] === "object" ) {
			const item = options.find( (o: any) => o.title === val ) as any;
			if ( !!item ) {
				onChangeHandler( item.id, item.title );
			} 
			else {
				setTimeout(() => setValue( val ), debouncedDelay);
			}
		}
		else {
			if ( options.includes(val) ) {
				onChangeHandler( val, val );
			}
			else {
				setTimeout(() => setValue( val ), debouncedDelay);
			}
		}
	}; 

	const onShowListChange = () => {
		const newValue = !isOpen;
		if ( !disabled ) {
			setIsOpen( newValue );
		}
		if ( !newValue && !!onClose ) {
			onClose();
		}
	}

	const onClearHandler = ( e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setValue( multiple ? [] : '');
		setTextValue("");
	};

	const renderOption = ( item: EnumItemType | string, index: number ) => {
		let val: any, title: string;
		if ( typeof item !== "object" ) {
			val = item;
			title = item.toString();
		} 
		else {
			val = item.id;
			title = item.title || item.id.toString();
		}
		const isChecked = getIsChecked(val);
		const itemClassses = classNames( "rdccs__listitem", {
			"item-checked": isChecked
		});
		return (
			<li key={index} className={itemClassses} id={ "selectItemFor_" + name + "_val-" + (!!val ? val : "empty") } onClick={ () => onChangeHandler(val, title)}>
				{ 
					!!multiple &&
					<CheckBox name={val} value={isChecked} />
				}
				<span className="body" title={title}>{title}</span>
			</li>
		);
	};

	const renderPlaceholder = () => {
		if ( allowNew ) {
			return (
				<input 
					value={textValue}
					placeholder={placeholder}
					type="text" 
					autoFocus={autoFocus}
					className="rdccs__header__input"
					onChange={onChangeTextHandler}
				/>
			);
		}
		else 
			return (<span className="rdccs__header__placeholder">{getTitleValue(true)}</span>);
	};

	const getStylesForList = () => {
		if ( !isOwnerList )
			return {};
		const styles = { 
			left: 0, 
			top: 0, 
			zIndex: '350',
			width: 100
		};
		if ( !!wrapperRef.current ) {
			styles.left = wrapperRef.current.offsetLeft;
			styles.top = ( wrapperRef.current.offsetTop + wrapperRef.current.clientHeight);
			styles.width = wrapperRef.current.clientWidth;
		}
		return styles;
	};

	const renderList = () => (
		<div className="rdccs__body" style={getStylesForList()}>
			<ul className="rdccs__body__listitems">
				{ options.map( renderOption )}
			</ul>
		</div>
	);

	return (
		<div {...other} ref={wrapperRef} className={classNames("rdccs_list-wrapper", className, { "rtl": rtl }) } id={name}>
			<div className={classNames("rdccs__header", { "opened": isOpen }) } onClick={onShowListChange} >
				<ArrowIcon height={33} width={32} onClick={onShowListChange}/>
				{ renderPlaceholder() }
				<div className="rdccs__header-toolbar">
					{
						!!isWait && <div className="spiner" />
					}
					{
						( !!showClearBtn && !!value ) &&
						<ClearBtn onClick={onClearHandler} />
					}
				</div>
			</div>
			{ isOpen && ( isOwnerList ? createPortal( renderList(), document.body) : renderList() ) }
		</div>
	);
};

export default SimpleSelect;
