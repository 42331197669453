import { FC } from "react";
import classNames from "classnames";
import { IconButton } from "src/Components";
import { ReactComponent as ChevronIcon } from 'src/Themes/assets/chevron-left.svg';
import st from "./RdcSubMenuFooter.module.scss";

interface IRdcSubMenuFooterProps {
	id: string;
	expanded?: boolean;
	disabled?: boolean;
	toggleExpand: () => void;
	renderFooter?: FC<IRdcSubMenuFooterProps>;
};

const RdcSubMenuFooter: FC<IRdcSubMenuFooterProps> = ( props ) => {

	const { id, expanded = true, disabled = false, toggleExpand, renderFooter } = props;
	const btnExHint = expanded ? "Свернуть " : "Развернуть ";

	return (
		<div id={ id + "_footer" } className={st.footer}>
			<div className={st.footer__userPart}>
				{ renderFooter && renderFooter( props ) }
			</div>
			<IconButton 
				id={ id + "_expandBtn" }
				className={classNames(st.footer__expandBtn, { [st.notExpanded]: !expanded } )} 
				outline 
				disable={disabled} 
				onClick={toggleExpand}
				data-tooltip-id="rdc_tooltip" 
				data-tooltip-content={btnExHint}
			>
				<ChevronIcon />
			</IconButton>
		</div>
	);
};

export default RdcSubMenuFooter;
