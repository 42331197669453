import { TaskRequestType } from "Src/Types/Task";
import API from ".";
import { ListJsonResponseType, ResponseCountType } from "src/Types";
import { ReceiptArchiveType } from "src/Types/ReceiptArchive";
import { RDC_Response } from "src/Types/ReDoc/RDC_Response";

type getTaskListType = (body: Partial<TaskRequestType>) => Promise<ListJsonResponseType<ReceiptArchiveType>>;
type getCountType = (body: Partial<TaskRequestType>) => Promise<ResponseCountType>;

/**
 * API для работы с Задачами
 */
export default class APIReceiptArchive {

	static _handleErrors = (response: any) => {
		if ( API.checkUnauthorized( response ) ) {
			throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
		}
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	};

	/** Возвращает список задач  */
	static searchList: getTaskListType = async ( body ) => {

		const params: any = {
			method: "POST",
			credentials: "include",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body)
		};

		const url = window.host + "/Nvx.ReDoc.ResultNoteArchive/NewGetList";
		return fetch(url, params)
			.then(this._handleErrors)
			.then(response => response.json())
			.then(result => {
				if (!!result.hasError) {
					throw new Error(result.errorMessage);
				}
				return result;
			})
			.catch((e) => { 
				console.warn(e);
				throw new Error('Сервер не доступен. Попробуйте перезагрузить страницу ( Ctrl + F5 )');
			});
	}; 

	/** Запрос на получение количества задач */
	static getCount: getCountType = async (filter) => {
		const url = window.host + '/Nvx.ReDoc.ResultNoteArchive/NewGetListCount';
		try {
			const request = await fetch( url, { 
				method:"POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(filter)
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении кол-ва записей. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении кол-ва записей. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				result: []
			}
		}
	};

	/** Возвращает последнею ошибку архива */
	static getLastError = async (): Promise<RDC_Response<null>> => {
		const url = window.host + "/Nvx.ReDoc.ResultNoteArchive/GetLastError";
		try {
			const request = await fetch( url, {
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении ошибок работы ФЗ. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении ошибок работы ФЗ. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

	/** Запрос на добавление расписки */
	static saveResultNote = async ( fio: string, date: string, fileNumber: string, serviceText: string, fileData: any ): Promise<RDC_Response<null>> => {
		const url = window.host + "/Nvx.ReDoc.ResultNoteArchive/ResultNoteArchiveController";
		const formData = new FormData();
		formData.append("name", fio );
		formData.append("date", date );
		formData.append("fileNumber", fileNumber );
		formData.append("service", serviceText );
		formData.append('file', fileData );
		try {
			const request = await fetch( url, {
				method: "POST",
				credentials: "include",
				body: formData
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при добавление расписки. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при добавление расписки. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				result: null
			}
		}
	};

};