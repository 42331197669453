
import { useEffect, useState } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

/** Хук для работы с параметрами запроса + локальное хранилище */
const useUrlParams = ( storeName: string = "", initState: URLSearchParamsInit = {} ) => {

	const [ searchParams, setSearchParams ] = useSearchParams();
	const [ stringifyValue, setStringifyValue ] = useState<string>();

	useEffect( () => {
		if ( !!storeName ) {
			const str = window.RDC.localStorage.getItem( storeName ) || "";
			if ( !!str ) {
				setSearchParams( new URLSearchParams(str) );
				setStringifyValue( searchParams.toString() );
			}
		}
	// eslint-disable-next-line
	}, [ storeName ] );

	const get = ( fieldName: string ): string => searchParams.get( fieldName ) || "";
	const set = ( fieldName: string, value: string ) => {
		if ( searchParams.has( fieldName ) ) {
			if ( !!value )
				searchParams.set( fieldName, value );
			else 
				searchParams.delete( fieldName );
		}
		else if ( !!value )
			searchParams.append( fieldName, value );
		setSearchParams( searchParams );
		setStringifyValue( searchParams.toString() );
		if ( !!storeName )
			window.RDC.localStorage.setItem( storeName, searchParams.toString() );
	};

	return [ stringifyValue, get, set, setSearchParams ] as const;
};

export default useUrlParams;
