import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PluginsType } from '../Types';
import api from '../API';
import { RootState } from '../store';

interface IPluginInfo {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error?: string;
    source: any;
    plugins: PluginsType;
}

const initialState: IPluginInfo = {
    loading: 'idle',
    source: {},
    plugins: {
        DamaskWebApiModule: false,
        EnterEQ: false,
        RedesignedSections: []
    }
};

export const getPluginInfo = createAsyncThunk('pluginInfo/Get', async () => {
      const response = await api.Plugins.get();
      return response;
    }
);

export const pluginInfoSlicer = createSlice({
	name: 'pluginInfo',
	initialState,
	reducers: {},
    extraReducers: (builder) => {
        builder.addCase( getPluginInfo.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase( getPluginInfo.fulfilled, (state, action) => {
            if ( !!action.payload && typeof action.payload !== "string" ) {
                window.RDC.localStorage.setItem( 'plugins', JSON.stringify(action.payload) );
                return {
                    source: { ...action.payload },
                    plugins: {
                        DamaskWebApiModule: action.payload["Nvx.ReDoc.DamaskWebApiModule"],
                        EnterEQ: action.payload["Nvx.ReDoc.EnterEQ"],
                        RedesignedSections: action.payload.RedesignedSections,
                        version: action.payload.version
                    },
                    loading: 'succeeded'
                };
            }
            return state;
        });
        builder.addCase( getPluginInfo.rejected, (state, action) => {
            window.RDC.localStorage.removeItem( 'plugins');
            state.error = action.error.message;
            state.loading = "pending";
        });
    }
});

export const getUserInfoSelector = (store:RootState):IPluginInfo => store.pluginInfo; 

export default pluginInfoSlicer;