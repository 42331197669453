import { useEffect, useState } from "react";
import { CheckBox } from "src/Components/Controls";
import { IconButton, Modal } from "src/Components";
import SimpleSelect, { EnumItemType, ValueType } from "src/Components/Controls/SimpleSelect";
import { IModalProps } from "..";
import { oListItemType } from "src/Types/ReDoc";
import API from "src/API";
import "../modals.scss";
import "./RedirectModal.scss";


interface IRedirectModalProps extends IModalProps {};

const RedirectModal: React.FC<IRedirectModalProps> = ( { isOpen, onClose, onAction } ) => {

	const [ winNumber, setWinNumber ] = useState<ValueType|ValueType[]>("");
	const [ prior, setPrior ] = useState<ValueType|ValueType[]>("Default");
	const [ isRedirect, setIsRedirect ] = useState<boolean>(false);
	const [ stations, setStations ] = useState<Array<EnumItemType>>([]);

	useEffect( () => {
		API.DamaskEQ.getStations()
			.then( data => {
				if ( !data.hasError ) {
					setStations( getEnumsByChoiceses(data.result) );
				}
			});
	}, [] );

	const renderBody: React.FC = ( props ) => (
		<div className="modal_wrapper modal_wrapper-redirect">
			<p className="headline">Выберите номер окна</p>
			<SimpleSelect isOwnerList options={stations} value={winNumber} setValue={setWinNumber} placeholder="Выберите или введите номер окна" />
			<p className="headline">Выберите приоритет вызова</p>
			<SimpleSelect isOwnerList options={PriorList} value={prior} setValue={setPrior} />
			<CheckBox value={isRedirect} setValue={setIsRedirect} name="save_acc" isBlock>Заявитель должен вернуться после обслуживания</CheckBox>
		</div>
	);

	const renderModalToolbar = () => {
		const payload = { 
			window: +winNumber, 
			priority: prior, 
			callback: isRedirect 
		};
		return (
			<>
				<IconButton outline onClick={onClose}>Закрыть</IconButton>
				<IconButton onClick={ () => onAction('redirect', payload )} >Перенаправить</IconButton> 
			</>
		)
	};

	return (
		<Modal
			isOpen={isOpen}
			title="Перенаправить посетителя"
			onClose={onClose}
			color="light"
			className="not_scrolled"
			renderFooter={renderModalToolbar()}
			renderBody={renderBody}
		/>
	);
};

const PriorList: Array<EnumItemType> = [
	{
		id: "Low",
		title: "Низкий (разместить в конец очереди)"
	},
	{
		id: "Default",
		title: "Cохранить прежний приоритет"
	},
	{
		id: "High",
		title: "Высокий (поместить в начало очереди)"
	}
];

const getEnumsByChoiceses = ( items: oListItemType[] ): Array<EnumItemType> => {
    return items.map( (item: any) => ({
        id: item.id,
        title: !!item.text ? item.text : item.name 
    }));
};

export default RedirectModal;
