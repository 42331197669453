import React from "react";
import { Field } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label, Input } from 'reactstrap'
import { getValue } from "..";


interface ICheckBoxFieldProps {
	name: string;
	title: string;
	setData: Function;
	errors: any;
	touched: any;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	inline?: boolean;
}

/**
 * Текстовое поле
 */
const CheckBoxField = (props: ICheckBoxFieldProps) => {
	const { name, title, setData, errors, touched, isRequired = false, inline = false, disabled = false } = props;


	const onChangeValueHandler = (e: any, form: any): any => {
		e.persist();
		form.setFieldValue(name, e.target.checked);
		!!setData && setData((data: any) => {
			return { ...data, [name]: e.target.checked }
		});
	}

	const error = !!errors && getValue(errors, name);
	const touch = !!touched && getValue(touched, name);

	return (
		<Field id={name} name={name}>
			{(props: any) => (
				<FormGroup check className={classNames("form-group", { "inline": inline } )}>
					{
						!!title &&
						<Label htmlFor={"input_" + name}>
							<b className={isRequired ? 'field_asterix' : ''}>
								{title}
							</b>
						</Label>
					}
					<Input
						{...props.field}
						id={ "input_" + name }
						className={classNames({ 'is-invalid': (error && touch) })}
						type="checkbox"
						onChange={e => onChangeValueHandler(e, props.form)}
						disabled={disabled}
						checked={!!props.field.value}
					/>&nbsp;
					{(error && touch) && FeedbackMessage(error)}
				</FormGroup>
			)}
		</Field>
	);
}

export default CheckBoxField;