
export enum EQueueType {
	None,
	Enter,
	Damask
};

export type EQueueConfigType = {
	contactId: string;
	login: string;
	password: string;
	officeId: number;
	pultId: string;
	pultName: string | null;
	queueServerAddress: string | null;
};

export enum WidgetType {
	None,
	Waiting, 
	Calling, 
	Working, 
	OnPause,
	Unknown
};

export type BeginPauseParamsType = {
	reason: string;
	idQ?: string;
};

export type eQueueStatuses = "Offline" | "Idle" | "Waiting" | "Calling" | "Working" | "OnPause";
