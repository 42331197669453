import axios from "axios";
import BaseApi from "./BaseApi";
import { Rotate, ScanConfigType, ScanFileType, ScanSettingType, ScanVersionType } from "src/Types/Scan";
import { Response } from "Src/Types/ReDoc";

/**
 * API для работы с Задачами
 */
class APIScan extends BaseApi {

	/** Получение конфигурации модуля сканирования */
	static getScanConfig = async (): Promise<ScanConfigType> => {
		const url = window.host + "/WebInterfaceModule/Default/CommonScanSettings";
		try {
			const request = await axios.get(url , { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении конфигурации модуля сканирования." );
			}
			else {
				return request.data;
			}
		}
		catch (error: any) {
			throw new Error("Ошибка при получении конфигурации модуля сканирования." );
		}
	};

	/** Получение настроек модуля сканирования */
	static getSettings = async (): Promise<ScanSettingType> => {
		const url = window.host + "/WebInterfaceModule/Default/LoadScaningSettings";
		try {
			const request = await axios.get(url , { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении настроек модуля сканирования." );
			}
			else {
				return request.data;
			}
		}
		catch (error: any) {
			throw new Error("Ошибка при получении настроек модуля сканирования." );
		}
	};

	/** Сохранение настроек */
	static saveSettings = async ( settings: ScanSettingType ): Promise<Response<null>> => {
		const url = window.host + "/WebInterfaceModule/Default/SaveScaningSettings";
		try {
			const request = await axios.post(url , settings, { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при сохранении настроек модуля сканирования." );
			}
			else {
				return request.data;
			}
		}
		catch (error: any) {
			throw new Error("Ошибка при сохранении настроек модуля сканирования." );
		}
	};

	/** 
	 * функция проверки модуля сканирования на доступность
	 * если отозвался, то доступен 
	*/
	static checkModule = async ( config: ScanConfigType ): Promise<boolean> => new Promise( resolve => {
		const url = config.scanModuleWorkUrl + '/api/scan/checkalive';
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url , true);
		xhr.onload = () => resolve(true);
		xhr.onerror = ( e ) => {
			console.info( 'Ошибка ' + xhr.status );
			resolve(false);
		};
		xhr.send();
	});

	/** Получаем версию модуля сканирования */
	static getVersion =  async ( config: ScanConfigType ): Promise<ScanVersionType> => {
		const url = config.scanModuleWorkUrl + '/api/scan/getversion';
		try {
			const request = await this.fetch( url );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении версии модуля сканирования. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении версии модуля сканирования. " + error );
		}
	};

	/** Получения списка сканеров  */
	static getSourceList = async ( config: ScanConfigType ): Promise<string[]> => {
		const url = config.scanModuleWorkUrl + '/api/scan/getsourcelist';
		try {
			const request = await this.fetch( url );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении спика доступных сканеров. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении спика доступных сканеров. " + error );
		}
	};

	/** Обновление списка сканеров */
	static updateList = async ( config: ScanConfigType ): Promise<boolean> => {
		const url = config.scanModuleWorkUrl + '/api/scanners/refresh';
		try {
			const request = await this.fetch( url, { method: "PATCH" } );
			if ( request.status === 200 ) {
				return true;
			}
			throw new Error("Ошибка при получении спика доступных сканеров. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении спика доступных сканеров. " + error );
		}
	};

	/** Сканирование */
	static scan = async ( config: ScanConfigType, settings: Partial<ScanSettingType> ): Promise<string[]> => {
		const url = config.scanModuleWorkUrl + '/api/scan/create';

		const requestBody = {
			ADFMode: parseInt(settings.ADFMode || "0"),
			Dpi: settings.Dpi || "300",
			Format: parseInt( settings.Format || "2" ),
			Mode: parseInt( settings.Mode || "1" ),
			Quality: parseInt( settings.Quality || "50" ),
			SourceName: settings.SourceName,
			UseADF: !!settings.UseADF
		};
		try {
			const request = await this.fetch( url, { 
				method: "POST",
				headers: {
					"Content-Type": 'application/json',
				},
				body: JSON.stringify(requestBody) 
			});
			if ( request.status === 200 ) {
				const res = await request.json();
				return res.id;
			}
			throw new Error("Ошибка при получении спика доступных сканеров. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении спика доступных сканеров. " + error );
		}
	};

	/** Сканирование */
	static clear = async ( config: ScanConfigType, idList: Array<string>, id?: string ): Promise<boolean> => {
		const url = config.scanModuleWorkUrl + '/api/scan/clear' + ( !!id ? ("/" + id) : "" );
		try {
			const request = await this.fetch( url, { 
				method: "POST",
				headers: {
					"Content-Type": 'application/json',
				},
				body: JSON.stringify(idList) 
			});
			if ( request.status === 200 ) {
				return true;
			}
			throw new Error("Ошибка при получении спика доступных сканеров. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении спика доступных сканеров. " + error );
		}
	};

	/** Поворот документа / страницы */
	static rotate = async ( config: ScanConfigType, to: Rotate, idList: string[], id?: string ): Promise<any> => {
		const url = config.scanModuleWorkUrl + ( !!id ? '/api/scan/rotatepage' : '/api/scan/rotatedocument' );
		
		let angle: number = to;
		let angleSuffix = '';

		if ( !!id ) {
			// Найдём угол, на который уже повёрнуто изображение
			const regex = /(?<==)\d{2,3}$/;
			angleSuffix = id.match(regex) + '';
			angle = parseInt(angleSuffix);
			if ( !angle || isNaN(angle) )
				angle = 0;
			angle = angle + to;
			if ( angle === -90 ) {
				angle = 270;
			} 
			else if ( angle === 360 ) {
				angle = 0;
			}
		}

		const requestBody = {
			Id: !!id ? [ id.replace('=' + angleSuffix, '') ] : idList, 
			Angle: angle
		};
		try {
			const request = await this.fetch( url, { 
				method: "POST",
				headers: {
					"Content-Type": 'application/json',
				},
				body: JSON.stringify(requestBody) 
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении спика доступных сканеров. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении спика доступных сканеров. " + error );
		}
	};

	/** Поворот документа / страницы */
	static createDocument = async ( config: ScanConfigType, settings: ScanSettingType, idList: string[] ): Promise<ScanFileType> => {
		const url = config.scanModuleWorkUrl + '/api/scan/getresult';
		const requestBody = {
			DocName: settings.docName,
			format: Number(settings.Format),
			images: idList,
			quality: settings.Quality
		};
		try {
			const request = await this.fetch( url, { 
				method: "POST",
				headers: {
					"Content-Type": 'application/json',
				},
				body: JSON.stringify(requestBody) 
			});
			if ( request.status === 200 ) {
				let filename = "";
				let filename_utf8 = "";
				const disposition = request.headers.get( 'Content-Disposition' );
				if ( disposition && disposition.indexOf('attachment') !== -1 ) {
					const filenameRegex = /(filename[^;=\n]*)=((['"]).*?\3|[^;\n]*)/g;
					let matches = filenameRegex.exec(disposition);
					while ( !!matches ) {
						if (matches[1] === 'filename*') {
							filename_utf8 = matches[2].replace("UTF-8''", "");
							filename_utf8 = decodeURIComponent(filename_utf8);
						} 
						else {
							filename = matches[2].replace(/['"]/g, '');
							//для кирилицы и спец. символов
							filename = decodeURIComponent(filename);
						}
						matches = filenameRegex.exec(disposition);
                    }							
				} 
				else {
					filename = settings.docName || "file";
				}

				return {
					hasError: false,
					result: await request.blob(),
					filename: filename,
					filename_utf8: filename_utf8
				};
			}
			throw new Error("Ошибка при получении спика доступных сканеров. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			console.warn( error );
			throw new Error("Ошибка при получении спика доступных сканеров. " + error );
		}
	};

};

export default APIScan;
