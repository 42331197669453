import { FC, HTMLAttributes } from "react";
import cn from "classnames";
import st from "./GroupControl.module.scss";

interface IHintProps extends HTMLAttributes<HTMLDivElement> {
    label?: string;
};


const Hint: FC<IHintProps> = ( { label = "", children } ) => (
    <div className={ cn( st.iconInfo, st.PopupBtn )}>
		<div className={ cn(st.PopUp, st.WithArrow)}>
			<div className={st.PopUpWrapper}>
				<h6>{label}</h6>
				<p>{children}</p>
			</div>
		</div>
	</div>
);

export default Hint;
