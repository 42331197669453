import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IUserInfo } from '../Types/ReDoc/Auth';
import api from '../API';
import { RootState } from '../store';

interface IAuthInfo extends IUserInfo {
	loading: 'idle' | 'pending' | 'succeeded' | 'failed';
	error?: string;
}

const initialState: IAuthInfo = {
	avatarUrl: null,
	mortal: false,
	roles: [],
	rules: [],
	userContactId: "",
	userLoggedStatus: false,
	userName: "",
	version: "",
	versionDate: "",
	loading: 'idle'
};

export const getUserInfo = createAsyncThunk('userInfo/Get', async () => {
	  const response = await api.Auth.getUserInfo();
	  return response;
	}
);

export const authSlicer = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase( getUserInfo.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( getUserInfo.fulfilled, (state, action) => {
			if ( !!action.payload ) {
				window.RDC.localStorage.setItem( 'userInfo', JSON.stringify(action.payload) );
				return {
					...action.payload,
					loading: 'succeeded'
				};
			}
		});
		builder.addCase( getUserInfo.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
	}
});

export const getUserInfoSelector = (store:RootState):IAuthInfo => (store.auth); 
// export const { getUserInfo } = authSlicer.actions;
// export const authSlicerActions = authSlicer.actions;

export default authSlicer;