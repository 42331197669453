import { FC } from "react";
import { IconButton } from 'src/Components';
import { ReactComponent as PlusIcon } from 'src/Themes/assets/create_plus.svg';
import { CreateBtnDescType, RenderToolbarHandler } from "..";
import st from "./RdcSubmenuHeader.module.scss";

interface IRdcSubmenuHeaderProps {
	id: string;
	title: string;
	expanded?: boolean;
	disabled?: boolean;
	createBtn?: CreateBtnDescType;
	isRenderCreateButton?: boolean;
	renderToolbar?: RenderToolbarHandler; 
	renderFooter?: FC<IRdcSubmenuHeaderProps>;
};

const RdcSubmenuHeader: FC<IRdcSubmenuHeaderProps> = ( props ) => {

	const { title, expanded, createBtn, isRenderCreateButton = true, renderToolbar } = props;

	return (
		<div className={st.header}>
			{ 
				isRenderCreateButton &&
				<div className={st.header__CreateBtn}>
				{ 
					( expanded && !!createBtn ) && 
					<IconButton id={createBtn.id || "create_btn"} color='yellow' block onClick={ () => createBtn.action(props) } >
						<PlusIcon />
						{ createBtn.title }
					</IconButton>
				}
				</div>
			}
			<div className={st.header__toolbar}>
				<div className={st.header__main__part}>
					{ !!renderToolbar && renderToolbar( "topLeft", props )	}
				</div>
				<div className={st.header__main__part}>
					{ !!renderToolbar && renderToolbar( "topRight", props )	}
				</div>
			</div>
			<div className={st.header__main}>
				<div className={st.header__main__part}>
					{ !!renderToolbar && renderToolbar( "bottomLeft", props )	}
					{ !!title && <h2 className="headline-large"> { title } </h2> }
				</div>
				<div className={st.header__main__part}>
					{ !!renderToolbar && renderToolbar( "bottomRight", props )	}
				</div>
			</div>
		</div>
	);
};

export default RdcSubmenuHeader;
