import React from "react";
import classNames from "classnames";
import "./styles.scss";

interface IBlockProps extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	height?: string;
	dir?: "row" | "col";
	center?: boolean;
	variant?: "default" | "white";
	children?: React.ReactNode;
	renderBody?: ( props: IBlockProps ) => React.ReactNode;
}

const Block: React.FC<IBlockProps> = ( props ) => {
	const { title, dir = "column",  height = "100%", center = true, children, variant = "", className, ...otherProps } = props;
	const classes = classNames(	"block", className,`rdc_block-${dir}`,  variant, {
		"centered": center
	});
	return (
		<div className={classes} {...otherProps} >
			{ 
				!!title && 
				<h3 className="block__header rdc_block__title headline-default">
					{title}
				</h3> 
			}
			<div className='block__body body' style={{ height }}>
				{props.children}
				{!!props.renderBody && props.renderBody(props)}
			</div>
		</div>
	)
};

export default Block;
