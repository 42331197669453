import React from "react";
import { SelectBoxField, PasswordField, TextField, RadioField, DateTimeField, FileField, FilesField, CheckBoxField, MaskField, HelpBlock, TextAreaField } from "./Fields";
import { RenderField } from "./UniversalForm";
import RelationFormField from "./Fields/RelationField";
import { RelationFieldType } from "src/Types/Form";

export enum FieldTypes {
    text = 'text',
    textArea = 'textArea',
    dateTime = 'dateTime',
    number = 'number',
    checkBox = 'checkBox',
    select = 'select',
    radio = 'radio',
    file = 'file',
    files = 'files',
    masked = "masked",
    help = "helpBlock",
    password = "password",
    relation = "relation"
};

export interface IOptions {
    value: any;
    display: string;
    disable?: boolean;
}

interface IBaseField {
    name: string;
    type: FieldTypes | string;
    title?: string;
    defaultValue?: any;
    isFullRow?: boolean;
    isRequired?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    regex?: RegExp | string;
    placeHolder?: string;
    visible?: boolean;
    relation?: RelationFieldType;
}

export interface IField extends IBaseField {
    options?: IOptions[];
    visible?: boolean;
    withTime?: boolean;
    needParse?: boolean;
    accept?: string;
    multiple?: boolean;
    mask?: string;
    body?: React.ReactNode;
    maskChar?: string;
    setData?: ( newValue: any ) => void;
    inline?: boolean;
}

export interface IFieldProps {
    name: string;
    title: string;
    placeholder?: string;
    setData: Function;
    errors: any;
    touched: any;
    isRequired: boolean;
    disabled: boolean;
    options: IOptions[];
}

export interface ISection {
    title?: string;
    fields: IField[];
    isFull: boolean;
}

export const FieldsComponent: any = {
    "text": TextField,
    "select": SelectBoxField,
    'radio': RadioField,
    'checkBox': CheckBoxField,
    "dateTime": DateTimeField,
    "file": FileField,
    "files": FilesField,
    "masked": MaskField,
    "helpBlock": HelpBlock,
    "textArea": TextAreaField,
    "password": PasswordField,
    "relation": RelationFormField,
};

/**  */
export const getValue = ( obj: any, key: string ): string => {
    if ( !obj ) return '';
    if ( key.indexOf('.') !== -1 ) {
        const keys = key.split('.');
        let o = obj;
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            o = o[k];
            if ( !o ) return '';			
        }
        return o;
    }
    return obj[key];
};

export { RenderField };

export { default as FieldGroup } from './FieldGroup';
export { default as UniversalForm } from './UniversalForm';
export { default as HelperBlock } from './Fields/HelpBlock/HelperBlock';