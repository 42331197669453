import { FC, HTMLAttributes, MouseEventHandler } from "react";
import classNames from "classnames";
import { ButtonSizes, ButtonVariants } from ".";
import Icon, { IconTypes } from "../Icon/Icon";
import st from "./Button.module.scss";

interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
	type?: "button" | "submit" | "reset" | undefined;
	iconClass?: string;
	variant?: ButtonVariants;
	size?: ButtonSizes;
	outline?: boolean;
	disable?: boolean;
	bordered?: boolean;
	block?: boolean;
	isWait?: boolean;
};

/**
 * Компонента для отрисовки кнопки
 */
const Button: FC<IButtonProps> = ( props ) => {

	const { variant = "blue", disable = false, outline = false, bordered = false, iconClass,
		isWait = false, block = false, type = "button", size = "std", onClick,
		children, className, ...otherProps 
	} = props;

	const onClickHandler: MouseEventHandler<HTMLButtonElement> = e => {
		if ( !disable && !!onClick ) {
			onClick(e);
		}
	};

	const classes = classNames( st.button, className, "button-font",
		st[`button_${variant}`], st[size], {
			[st.block]: block,
			[st.outline]: outline,
			[st.disable]: disable,
			[st.bordered]: bordered,
			[st.onlyIcon]: !children
		}
	);

	return (
		<button type={type} className={classes} onClick={onClickHandler} {...otherProps}>
			{ ( !isWait && !!iconClass ) && <span className={classNames( "icon", iconClass) } /> }
			{ isWait && <span className={st.trobber} /> }
			<div style={{ opacity: isWait ? 0 : 1 }}> { children } </div>
		</button>
	);
};

interface IButtonIconProps extends IButtonProps {
	iconName?: IconTypes;
};

/**
 * Компонента для отрисовки кнопки с использованием компоненты иконки
 */
export const ButtonIcon: React.FC<IButtonIconProps> = ( { children, iconName,  ...props } ) => (
	<Button {...props}>
		{ ( !props.isWait && !!iconName ) && <Icon name={iconName} /> }
		{ children }
	</Button>
);

export default Button;
