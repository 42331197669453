import { FC, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'src/Components';
import { Rotate, ScanConfigType, ScanSettingType } from 'src/Types/Scan';
import { ReactComponent as IconPlus } from './assets/plus.svg';
import { ReactComponent as IconMinus } from './assets/minus.svg';
import { ReactComponent as IconRotate } from './assets/rotate.svg';
import { ReactComponent as IconDel } from './assets/del.svg';
import st from "./ScanPreviw.module.scss";

interface IScanPreviewProps {
	isLoading: boolean;
	enable: boolean;
	config: ScanConfigType;
	settings: ScanSettingType;
	listId: Array<string>;

	/**  */
	removePage: ( id?: string ) => void;
	/**  */
	rotate: ( to: Rotate, id?: string ) => void;
};

/**  */
const ScanPreview: FC<IScanPreviewProps> = ( props ) => {

	const { isLoading, enable, config, listId, removePage, rotate } = props;

	const [ scale, setScale ] = useState<number>(0);

	if ( isLoading ) 
		return (
			<div className={classNames(st.Info, st.error)}>
				<div className="rdc_spiner-big" />
			</div>
		);
	if ( enable && !listId.length ) return renderInstruction();
	if ( !enable ) return renderError();

	const stepScale = ( isPlus: boolean = false ) => {
		setScale( prev => {
			const nextVal = isPlus ? prev + 1 : prev - 1;
			if ( nextVal >= 0 && nextVal <= 3 )
				return nextVal;
			else 
				return prev;
		});
	};

	const renderPage = ( recid: string, index: number ) => (
		<div key={ "scan_" + index } className={st.page} style={{ width: `calc( 750px / ${Scales[scale].value} )` }}>
			<img src={`${config.scanModuleWorkUrl}/api/scan/getthumbnail/${recid}`} alt={"Страница " + (index + 1)} />
			<div className={classNames( st.popup, st.pageControl )}>
				<div className={st.groupBtn}>
					<Button outline size="min" title="Повернуть страницу влево" onClick={()=> rotate(Rotate.Left, recid)}>
						<IconRotate />
					</Button>
					<Button className={st.iconRotateR} outline size="min" title="Повернуть страницу вправо" onClick={()=> rotate(Rotate.Right, recid)}>
						<IconRotate />
					</Button>
				</div>
				<Button outline variant='red' size="min" onClick={ () => removePage( recid ) } title="Удалить страницу">
					<IconDel />
				</Button>
			</div>
			<div className={classNames( st.popup, st.pageIndex )}>
				<p>Страница <span>{ index + 1 }</span></p>
			</div>
		</div> 
	);

	const renderPages = () => (
		<ul className={st.scanList}>
			{ listId.map( renderPage ) }
		</ul>
	);

	const renderPagesControl = () => (
		<div id="image-controls" className={st.controls}>
			<div className={st.groupBtn}>
				<Button outline disable={ scale >= 3 } size="min" title="Уменьшение масштаба" onClick={()=> stepScale(true)} >
					<IconMinus />
				</Button>
				<p title="Масштаб просмотра"> { Scales[scale].title } </p>
				<Button outline disable={ scale <= 0 } size="min" title="Увеличение масштаба" onClick={()=> stepScale()}>
					<IconPlus />
				</Button>
			</div>
			<div className={st.groupBtn}>
				<Button outline size="min" title="Повернуть весь документ влево" onClick={()=> rotate(Rotate.Left)}>
					<IconRotate />
				</Button>
				<Button className={st.iconRotateR} outline size="min" title="Повернуть весь документ вправо" onClick={()=> rotate(Rotate.Right)}>
					<IconRotate />
				</Button>
			</div>
			<Button outline variant='red' size="min" onClick={ () => removePage() } title="Очистить документ">
				<IconDel />
			</Button>
		</div>
	);

	return (
		<div id="preview" className={st.content}>
			{
				!!listId.length &&
				<>
					{ renderPages() }
					{ renderPagesControl() }
				</>
			}
		</div>
	);
};

const renderInstruction = () => (
	<div className={st.Info}>
		<h3 className='headline-xlarge'>Инструкция</h3>
		<svg width="128" height="107" viewBox="0 0 128 107" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.6667 10.8307H32V0.164062H10.6667C7.83769 0.164063 5.12458 1.28787 3.12419 3.28826C1.12381 5.28864 0 8.00175 0 10.8307L0 32.1641H10.6667V10.8307ZM117.333 0.164062H96V10.8307H117.333V32.1641H128V10.8307C128 8.00175 126.876 5.28864 124.876 3.28826C122.875 1.28787 120.162 0.164063 117.333 0.164062ZM10.6667 74.8307H0V96.1641C0 98.993 1.12381 101.706 3.12419 103.707C5.12458 105.707 7.83769 106.831 10.6667 106.831H32V96.1641H10.6667V74.8307ZM117.333 96.1641H96V106.831H117.333C120.162 106.831 122.875 105.707 124.876 103.707C126.876 101.706 128 98.993 128 96.1641V74.8307H117.333V96.1641ZM21.3333 32.1641V74.8307C21.3333 77.6597 22.4571 80.3728 24.4575 82.3732C26.4579 84.3736 29.171 85.4974 32 85.4974H96C98.829 85.4974 101.542 84.3736 103.542 82.3732C105.543 80.3728 106.667 77.6597 106.667 74.8307V32.1641C106.667 29.3351 105.543 26.622 103.542 24.6216C101.542 22.6212 98.829 21.4974 96 21.4974H32C29.171 21.4974 26.4579 22.6212 24.4575 24.6216C22.4571 26.622 21.3333 29.3351 21.3333 32.1641ZM32 74.8307V53.4974H96V74.8307H32ZM96 32.1641V42.8307H32V32.1641H96Z"></path>
		</svg>
		<ol className="body">
			<li>Для начала сканирования нажмите кнопку "Сканировать".</li>
			<li>Для последующего сканирования каждого листа используйте кнопку "Сканировать".</li>
			<li>Когда все листы документа будут отсканированы, нажмите кнопку "Сформировать документ".</li>
		</ol>
	</div>
);

const renderError = () => (
	<div className={classNames(st.Info, st.error)}>
		<h3 className='headline-xlarge'>Ошибка</h3>
		<svg width="128" height="107" viewBox="0 0 128 107" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.6667 10.8307H32V0.164062H10.6667C7.83769 0.164063 5.12458 1.28787 3.12419 3.28826C1.12381 5.28864 0 8.00175 0 10.8307L0 32.1641H10.6667V10.8307ZM117.333 0.164062H96V10.8307H117.333V32.1641H128V10.8307C128 8.00175 126.876 5.28864 124.876 3.28826C122.875 1.28787 120.162 0.164063 117.333 0.164062ZM10.6667 74.8307H0V96.1641C0 98.993 1.12381 101.706 3.12419 103.707C5.12458 105.707 7.83769 106.831 10.6667 106.831H32V96.1641H10.6667V74.8307ZM117.333 96.1641H96V106.831H117.333C120.162 106.831 122.875 105.707 124.876 103.707C126.876 101.706 128 98.993 128 96.1641V74.8307H117.333V96.1641ZM21.3333 32.1641V74.8307C21.3333 77.6597 22.4571 80.3728 24.4575 82.3732C26.4579 84.3736 29.171 85.4974 32 85.4974H96C98.829 85.4974 101.542 84.3736 103.542 82.3732C105.543 80.3728 106.667 77.6597 106.667 74.8307V32.1641C106.667 29.3351 105.543 26.622 103.542 24.6216C101.542 22.6212 98.829 21.4974 96 21.4974H32C29.171 21.4974 26.4579 22.6212 24.4575 24.6216C22.4571 26.622 21.3333 29.3351 21.3333 32.1641ZM32 74.8307V53.4974H96V74.8307H32ZM96 32.1641V42.8307H32V32.1641H96Z" />
		</svg>
		<p className="body">
			Модуль сканирования не готов к работе или недоступен. Обратитесь к администраторам для установки и настройки модуля сканирования на Ваш компьютер.
		</p>
	</div>
);

const Scales = [
	{
		title: "100%",
		value: 0.8
	},
	{
		title: "75%",
		value: 1.55
	},
	{
		title: "50%",
		value: 2
	},
	{
		title: "25%",
		value: 3.3
	}
];

export default ScanPreview;
