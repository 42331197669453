import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type UseCashedArrayHandlers<T> = [
	Array<T>,
	Dispatch<SetStateAction<T[]>>,
	() => void,
];

/**
 * 
 * @param cashKey 
 * @param initialValue 
 */
export const useCashedArray = <T>( cashKey: string, initValue: Array<T> = [] ): UseCashedArrayHandlers<T> => {

	const getObjectFromCash = () => {
		const strData = window.RDC.localStorage.getItem(cashKey);
		if ( !!strData ) {
			const data = JSON.parse(strData) as Array<T>;
			return data;
		}
		else if ( !!initValue ) {
			return initValue;
		}
		return [];
	};

	const [ data, setData ] = useState<T[]>(getObjectFromCash());

	useEffect( () => {
		window.addEventListener("localStorageUpdate", onUpdateStorage );
		return () => window.removeEventListener("localStorageUpdate", onUpdateStorage );
	// eslint-disable-next-line
	}, [] );

	const onUpdateStorage = ( event: CustomEventInit ) => {
		const { type, key, value } = event.detail;
		if ( key !== cashKey ) return;
		if ( type === "update" ) {
			if ( !value ) {
				setData( [] );
			}
			else {
				setData( JSON.parse(value) );
			} 
		}
	};

	const setDataHandler: Dispatch<SetStateAction<T[]>> = ( value ) => {
		let newData: Array<T>;
		if ( typeof value === "function" ) {
			/// TODO: Костыль! Надобно разобраться с типами и сделать по человечески
			newData = (value as any)(data);
		} 
		else {
			newData = value as Array<T>;
		}
		const strData = JSON.stringify(newData);
		window.RDC.localStorage.setItem( cashKey, strData );
		return newData;
	};

	const resetDictionary = () => window.RDC.localStorage.setItem(cashKey, '[]' );

	return [ data, setDataHandler, resetDictionary ];
};

export default useCashedArray;
