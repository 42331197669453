import { ReestrListType, ReestrType } from "src/Types";
import { IResult } from "src/Types";
import API from ".";

/**
 * API для работы с реестрами
 */
export default class APIReestr {

	static getReestrList = async (): Promise<IResult<ReestrListType>> => {
		const url = window.host + '/registry?section=files';
		const request = await fetch( url, { credentials: 'include' });
		return API.prepareJSONResult( request );
	};

	static getReestrItem = async ( id: string): Promise<IResult<ReestrType>> => {
		const url = window.host + '/registry/' + id;
		const request = await fetch( url, { credentials: 'include' } );
		return API.prepareJSONResult( request );
	};

	/**  */
	static savePreset = async ( newReestrName: string, preset: ReestrType, isReplace: boolean ): Promise<IResult<string>> => {
		const url = window.host + '/registry/' + (isReplace ? newReestrName : '');
		const result: IResult<string> = {
			success: true,
			message: '',
			data: ''
		};
		try {
			const request = await fetch( url, { 
				method: isReplace ? "PUT" : "POST", 
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify(preset)
			});
			if ( request.status === 200 ) {
				const res = await request.json();
				if ( res.hasError ) {
					result.success = false;
					result.message = `Ошибка при сохранении реестра. ${res.errorMessage}`;
				}
				return result;
			}
			else {
				result.success = false;
				result.message = `Ошибка при сохранении реестра. Сервер вернул - (${request.status}): ${request.statusText}`;
			}
		}
		catch( error: any ) {
			result.success = false;
			result.message = `Ошибка при сохранении реестра. ${error.message}`;
		}
		return result;
	};

	/**  */
	static remove = async ( id: string ): Promise<IResult<any>> => {
		const url = window.host + '/registry/' + id;
		const result: IResult<string> = {
			success: true,
			message: '',
			data: ''
		};
		try {
			const request = await fetch( url, { 
				method: "DELETE", 
				headers: {'Content-Type': 'application/json'},
				credentials: 'include'
			});
			if ( request.status === 200 ) {
				const res = await request.json();
				if ( res.hasError ) {
					result.success = false;
					result.message = `Ошибка при удалении реестра. ${res.errorMessage}`;
				}
				return result;
			}
			else {
				result.success = false;
				result.message = `Ошибка при удалении реестра. Сервер вернул - (${request.status}): ${request.statusText}`;
			}
		}
		catch( error: any ) {
			result.success = false;
			result.message = `Ошибка при удалении реестра. ${error.message}`;
		}
		return result;
	};

};
