import { useState, useEffect, useRef } from 'react';
import { Table, Column } from "@tanstack/react-table";
import { IconButton } from "src/Components";
import { ColumnType } from '../..';
import { useOutsideClick } from 'src/Hooks';
import { RelationField, SelectModal, DatePicker, TextBox } from 'src/Components/Controls';
import { getOffsetBy } from 'src/Utils';
import './filter.scss';
import { FileType } from 'Src/Types/File';

interface IFilterProps {
	isConstructor?: boolean;
	tableColumn: Column<any, unknown>; 
	table?: Table<FileType>;
	columnTypes: Array<ColumnType>;
	width?: number;
	ownerWidth?: number;
};

const BooleanEnums = [
	{
		id: "",
		title: "Все"
	},
	{
		id: "true",
		title: "Да"
	},
	{
		id: "false",
		title: "Нет"
	}
]

const Filter: React.FC<IFilterProps> = ({ tableColumn, columnTypes, width, isConstructor = false, ownerWidth = 0 }) => {

	const columnType = columnTypes.find( c => c.id === tableColumn.id );
	const columnFilterValue: any | [any,any] = tableColumn.getFilterValue();
	const sType = columnType?.type?.toString() ?? "string";
	const isBetween =  columnType?.isBetween;

	const filterRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useOutsideClick<HTMLDivElement>( () => setIsShowModal(false));
	const [ isShowModal, setIsShowModal ] = useState<boolean>(false);
	const [ value, setValue ] =	useState<any|Array<any>|[any,any]>(columnFilterValue);

	useEffect(() => {
		const initValue = !!columnFilterValue ? columnFilterValue : ( isBetween ? [] : '');
		setValue( initValue );	
	}, [columnFilterValue, isBetween]);

	// eslint-disable-next-line
	const applyValueHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent ) => {
		e.preventDefault();
		e.stopPropagation();
		setIsShowModal(false);
		tableColumn.setFilterValue(value);
	};

	useEffect( () => {
		const onKeyUp = ( e: KeyboardEvent ) => {
			if ( e.code === "Enter" ) {
				applyValueHandler(e);
			}
			else if ( e.code === "Escape" ) {
				setValue(columnFilterValue);
				setIsShowModal(false);
			}
		};
		if ( !isShowModal ) {
			window.removeEventListener( "keyup", onKeyUp );
			return;
		}
		window.addEventListener( "keyup", onKeyUp );
		return () => {
			window.removeEventListener( "keyup", onKeyUp );
		};
	// eslint-disable-next-line
	}, [ isShowModal, applyValueHandler ] );

	/** Тут высчитываем корректировку позиции модалки, при открытии модального окна */
	useEffect( () => {
		const element = wrapperRef.current;
		if ( element ) {
			const offset = getOffsetBy( element, "rdc_datagrid_wrapper");
			const rightOffset = offset.left + offset.width - offset.scroll;
			// Right side
			if ( rightOffset > ownerWidth ) {
				const result =  ownerWidth - rightOffset - 20 + 'px';
				element.style.left = result;
				return;
			} 
			// // Left side
			else if ( !!offset.scroll && offset.left < offset.scroll ) {
				element.style.left = (offset.scroll - offset.left + 10) + 'px';
			}
		}
	// eslint-disable-next-line
	}, [ isShowModal ] );

	const closeModalHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent ) => {
		e.preventDefault();
		e.stopPropagation();
		setValue(columnFilterValue);
		setIsShowModal(false);
	};

	const onClearHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		tableColumn.setFilterValue('');
	};

	const onOpenModalHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setIsShowModal(true);

	const renderInputs = ( isPlaceholder: boolean = false) => {
		let val = value;
		if ( !!columnType?.multiple ) {
			val = !!value ? [...value] : [];
		}

		if ( ["enum", "bool"].includes(sType) ) {
			return (
				<SelectModal
					id={"filter_" + columnType?.id}
					isPlaceholder={isPlaceholder}
					value={val}
					defaultOpen={true}
					isWait={false}
					setValue={setValue}
					showClearBtn
					enums={ sType === "bool" ? BooleanEnums : columnType?.enums }
					multiple={!!columnType?.multiple}
					onClear={onClearHandler}
				/>
			);
		}
		else if ( sType === "relation" && !!columnType?.relation ) {
			return (
				<RelationField
					isPlaceholder={isPlaceholder}
					id={"filter_" + columnType?.id}
					value={value}
					showClearBtn
					defaultOpen={!isPlaceholder}
					setValue={setValue}
					onClear={onClearHandler}
					multiple={!!columnType?.multiple}
					relation={columnType?.relation}
				/>
			);
		}
		else if ( ["date", "dateTime"].includes(sType) ) {
			return (
				<DatePicker
					isPlaceHolder={isPlaceholder}
					value={val}
					setValue={setValue}
					onClear={onClearHandler}
					headless={true}
					isBetween={isBetween}
				/>
			);
		}

		return (
			<TextBox
				id={columnType?.id}
				value={value}
				autoFocus
				isPlaceHolder={isPlaceholder}
				setValue={setValue}
				onClear={onClearHandler}
				placeholder={ isConstructor ? "" : `Введите ${columnType?.title}`}
				className="tablefilter-std"
			/>
		);
	};

	const getStyles = () => {
		if ( sType.includes('date') ) {
			return {
				minWidth: "360px",
				maxWidth: "390px"
			}
		}
		return {};
	};

	const renderModal = () => (
		<div className="filter__modal" ref={wrapperRef} style={getStyles()}>
			{ renderInputs() }
			<div className="filter__modal-body" style={getStyles()}>
				<IconButton id={'filter_cancel-' + tableColumn.id} onClick={closeModalHandler} outline>Отмена</IconButton>
				<IconButton id={'filter_apply-' + tableColumn.id} onClick={applyValueHandler}>Применить</IconButton>
			</div>
		</div>
	);

	return (
		<div id={"filter_placeholder-" + tableColumn.id} ref={filterRef} className="filter_wrapper" onClick={onOpenModalHandler} style={{ width: !!width ? width : ''}}>
			{ isShowModal ? renderModal() : renderInputs(true) }
		</div>
	);
};

export default Filter;
