import classNames from "classnames";
import { ContextMenuItemType } from "../types";
import { IExtendMenuItem } from "../Window/ContextMenuWindow";
import './ContextMenuItem.scss';

export interface IContextMenuItemProps {
    item: IExtendMenuItem;
    onClick: ( e: React.MouseEvent<HTMLAnchorElement|HTMLDivElement>, item: ContextMenuItemType ) => void;
};

/** Компонент пункта контексного меню */
export const ContextMenuItem: React.FC<IContextMenuItemProps> = ( { item, onClick } ) => {

	const onClickHandler = ( e: React.MouseEvent<HTMLAnchorElement|HTMLDivElement> ) => {
		if ( !item ) return;
		if ( !!onClick ) {
			onClick(e, item);
		}
	};

	const renderContent = () => (
		<div onClick={onClickHandler} className={"rdc-cmenu__item"} style={{ width: '100%'}}>
			<div className={"rdc-cmenu__item__content"} >
				<span className={ classNames( "rdc-cmenu__item-icon", item?.icon, { "icon_exist": !item?.icon } )} />
				<span className={"rdc-cmenu__item-text"}>{item?.label}</span>
			</div>
			{
				(!!item?.carret || !!item?.subItems?.length) &&
				<svg width="14" height="32" viewBox="0 0 14 32" fill="inherit">
					<path d="M4.5 21L9.5 16L4.5 11V21Z" fill="inherit"/>
				</svg>
			}
		</div>
	);

	const renderExtendItem = () => {

		if ( !!item?.subMenu ) {
			return (
				<div className="rdc-cmenu__item__submenu">
					{ item.subMenu() }
				</div>
			);
		}
	};

	if ( item?.tag === "a" ) {
		return (
			<a className={"rdc-cmenu__item"} onClick={onClickHandler} download={!!item.isDownload} href={item.href} aria-disabled={!!item.disabled}>
				{ renderContent() }
			</a>
		);
	}

	return (
		<div className={"rdc-cmenu__item"} aria-disabled={!!item?.disabled}>
			{ renderContent() }
			{ item?._extended && renderExtendItem() }
		</div>
	);
};
