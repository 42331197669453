import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './Slicers';

const preloadedState = {};

const logger = (store:any) => (next:any) => (action:any) => {
	if ( window.isDev && !!window.cfg.logRedux ) {
		console.group(action.type);
		console.info('dispatching', action);
		const result = next(action);
		console.groupEnd();
		return result;
	}
	return next(action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, logger),
	devTools:  window.isDev,
	preloadedState
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useReduxDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
