import downloadjs from 'downloadjs';
import { FileAttachmentResponseType } from 'src/Types/File/Attachment';
import { CreateFileResponseType } from "src/Types/ReDoc/File";
import API from '.';
import IFileFilterExport from 'src/Types/File/FileExport';
import BaseResponseType from 'src/Types/BaseRespounse';

type exportType = (filter: IFileFilterExport) => Promise<BaseResponseType<any>>;

type getAttachmentsType = (id: string) => Promise<FileAttachmentResponseType>;

export default class File {

	static export: exportType = async (filter) => {
		const url = window.host + '/WebInterfaceNew/Search/Export';
		const result: BaseResponseType<any> = {
			iserror: true,
   			message: "Ошибка экспорта.",
			data: null
		};
		try {
			const request = await fetch( url, {
				method: "POST",
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(filter)
			});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {

				const answerHeader = request.headers.get("Content-Type");
				if ( answerHeader?.includes("application/json") ) {
					const res = await request.json();
					result.iserror = res.hasError;
					result.message = res.errorMessage;
					return result;
				}	

				let filename = "Дела";
				const blob = await request.blob();
				const disposition = request.headers.get("Content-Disposition");
				
				if (disposition && disposition.indexOf('attachment') !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) {
						filename = matches[1].replace(/['"]/g, '');
						//для кирилицы и спец. символов
						filename = decodeURIComponent(escape(filename));
					}
				}
				if ( !!blob ) {
					downloadjs(blob, filename);
					result.iserror = false;
				}

				return result;
			}
		}
		catch( error: any ) {
			result.data = error;
			return result;
		}
		return result;
	};

	/** */
	static getAttachments: getAttachmentsType = async ( id ) => {
		const url = window.host + '/files/' + id + '/attachments';
		try {
			const request = await fetch( url, {credentials: "include"});
			if ( API.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при получении списка вложений. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message,
				result: []
			}
		}
	};

	/** */
	static create = async ( templateId?: string ): Promise<CreateFileResponseType> => {
		const url = window.host + '/WebInterfaceModule/File/New' + ( !!templateId ? ("?templateId=" + templateId) : "" ) ;
		try {
			const request = await fetch( url, { 
				method:"POST", 
				credentials: "include",
				headers: { "Content-Type": "application/json" }
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error("Ошибка при создании дела. Сервер вернул - " + request.statusText );
		}
		catch (error: any) {
			return {
				hasError: true,
				errorMessage: error.message
			}
		}
	};

}