import { useState, useEffect } from 'react';

type useLocalStorageType = ( key: string, initialValue?:string ) => [
    value: string,
    setValue: ( newValue: string ) => void
];

/**
 * Хук для использования локальной памяти браузера
 * @param key Ключ
 * @param initialValue Дефолтное значение
 * @returns Массив с текущим значением и функцией установки
 */
const useLocalStorage: useLocalStorageType = (key, initialValue = '' ) => {

    const getValue = () => {
        const val = window.RDC.localStorage.getItem(key);
        return !!val ? val : initialValue; 
    };

    const [value, setValue] = useState<string>(getValue);

    useEffect(() => {
        window.RDC.localStorage.setItem(key, value);
    }, [ key, value ] );

    return [value, setValue];
};

export default useLocalStorage;