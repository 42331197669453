import { FC, HTMLAttributes, ReactNode } from "react";
import cn from 'classnames';
import st from './Container.module.scss';

interface IContainerProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
};

const Container: FC<IContainerProps> = ( { children, className } ) => (
    <div className={cn( st.container, className)}>
        { children }
    </div>
);

export default Container;
