import { HtmlHTMLAttributes, useEffect, useMemo } from 'react';
import { useToggle, useMediaQuery } from 'src/Hooks';
import RdcSubmenuHeader from './Header/RdcSubmenuHeader';
import RdcSubMenuFooter from './Footer/RdcSubMenuFooter';
import { CreateBtnDescType, RenderToolbarHandler } from '.';
import RdcSubMenuContainer from './Container/RdcSubMenuContainer';
import st from "./RdcSubmenu.module.scss";

interface IRdcSubMenuProps extends HtmlHTMLAttributes<HTMLDivElement> {
	/** Заголовок */
	title?: string;
	/** Блокирует все действия панели */
	disabled?: boolean;

	/** Начальное состояния панели */
	isRenderHeader?: boolean;
	/** Начальное состояния панели */
	isRenderFooter?: boolean;

	/** Начальное состояния панели */
	defaultExpanded?: boolean;
	/** Флаг запрещающий сворачивать/разворачивать панель */
	disableExpand?: boolean;
	/** Строка Медиа-запроса, при срабатывании которого панель будет автоматически свернута */
    autoCollapseMedia?: string;

	/** Рендерить ли контейнер для кнопки создания */
	isRenderCreateButton?: boolean;
	/** Начальное состояния панели */
	createBtn?: CreateBtnDescType;

	/** Функция отрисовки контента */
	renderContent?: React.FC<IRdcSubMenuRenderProps>;

	renderToolbar?: RenderToolbarHandler;

	/** Функция отрисовки футера панели 
	 * ( Будет рендерится Перед кнопкой сворачивания ) 
	*/
	renderFooter?: React.FC;

	onChangeExpand?: ( newValue: boolean ) => void;

	children?: React.ReactNode;
};

interface IRdcSubMenuRenderProps extends IRdcSubMenuProps {
	expanded: boolean;
};

/** Компонент-контейнер для отображения подменю */
const RdcSubMenu: React.FC<IRdcSubMenuProps> = ( props ) => {

	const { id = "rdc_submenu", title = "", renderFooter, children, renderContent, createBtn, disabled = false, className, 
		isRenderHeader = true, isRenderFooter = true,
		autoCollapseMedia = "(min-width: 1320px)", onChangeExpand, renderToolbar,
		defaultExpanded = false, disableExpand = false, ...otherProps 
	} = props;

	const [ expanded, toggle, setExpand ] = useToggle(defaultExpanded);
	
	const matches = useMediaQuery(autoCollapseMedia);

	useEffect( () => {
		if ( !!onChangeExpand ) {
			onChangeExpand( expanded );
		}
	// eslint-disable-next-line
	}, [ expanded ] );

	useEffect( () => {
		if ( !disabled ) {
			setExpand( matches );
		}
    // eslint-disable-next-line
	}, [ matches ]);

	const innerProps: IRdcSubMenuRenderProps = useMemo( () => ({
		expanded,
		...props
	// eslint-disable-next-line
	}), [ expanded ] );

	const renderBody = () => {
		if ( !expanded ) return null;
		return (
			<div className={st.rdc_submenu__content}>
				{ !!children && children }
				{ !!renderContent && renderContent( innerProps ) }
			</div>
		);
	};

	return (
		<RdcSubMenuContainer id={id} expanded={expanded} disableResizing={disabled} {...otherProps}>
			{
				isRenderHeader && 
				<RdcSubmenuHeader 
					id={id}
					expanded={expanded} 
					disabled={disabled} 
					title={title}
					renderToolbar={renderToolbar}
					{...props}
				/>
			}
			{ renderBody() }
			{
				isRenderFooter &&
				<RdcSubMenuFooter 
					id={id} 
					expanded={expanded} 
					toggleExpand={toggle} 
					disabled={ disableExpand || disabled}
					renderFooter={renderFooter}
				/>
			}
		</RdcSubMenuContainer>
	);
};

export default RdcSubMenu;