import { FC, HTMLAttributes } from "react";
/// Icons
import { ReactComponent as OtherIcon } from './assests/other.svg';
import { ReactComponent as PDFIcon } from './assests/pdf.svg';
import { ReactComponent as ZipIcon } from './assests/zip.svg';
import { ReactComponent as WordIcon } from './assests/word.svg';
import { ReactComponent as IMGIcon } from './assests/img.svg';
import { ReactComponent as TxtIcon } from './assests/txt.svg';
import { ReactComponent as XMLIcon } from './assests/xml.svg';
import { ReactComponent as ExcelIcon } from './assests/excel.svg';
import { ReactComponent as PttIcon } from './assests/ptt.svg';
import { ReactComponent as WebIcon } from './assests/html.svg';
import { ReactComponent as ExeIcon } from './assests/exe.svg';
import { ReactComponent as DownloadIcon } from './assests/download.svg';

export type FileIcons = "download" | "text/xml" | "image/jpeg";

interface IFileIcon extends HTMLAttributes<HTMLOrSVGElement> {
    type?: FileIcons;
	fileName?: string;
	file?: File;
};

/** Компонента отображающая иконку файла */
const FileIcon: FC<IFileIcon> = ( { type, fileName, file, ...otherProps } ) => {

	const getType = () => {
		if ( !!type ) return type;
		else if ( !!fileName ) {
			const chunks = fileName.split('.');
			const ext = (chunks.pop() ?? '').toLowerCase();
			return ext;
		}
		else if ( !!file ) {
			const chunks = file.name.split('.');
			const ext = (chunks.pop() ?? '').toLowerCase();
			return ext;
		}
		throw new Error( "Для компонента иконки файла не передан не один из типов файлов!" );
	}

    switch ( getType() ) {

        case "download": return <DownloadIcon {...otherProps} />;

        case "pdf": return <PDFIcon {...otherProps} />;
		
		case "7z":
		case "rar":
		case "zip": return <ZipIcon {...otherProps} />;

		case "text/xml":
		case "xml": return <XMLIcon {...otherProps} />;

		case "bmp":
		case "tif":
		case "tiff":
		case "png":
		case "image/jpeg":
		case "jpeg":
		case "jpg": return <IMGIcon {...otherProps} />;

		case "text":
		case "txt": return <TxtIcon {...otherProps}/>;

		case "rtf":
		case "odt":
		case "docx":
		case "doc": return <WordIcon {...otherProps}/>;

		case "xlsx":
		case "excell": return <ExcelIcon {...otherProps}/>;

		case "htm":
		case "html": return <WebIcon {...otherProps}/>;

		case "bat":
		case "exe": return <ExeIcon {...otherProps}/>;

		case "odp":
		case "ppt":
		case "pptx": return <PttIcon {...otherProps}/>;

        default: return <OtherIcon {...otherProps} />
    }
};

export default FileIcon;
