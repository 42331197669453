import axios from "axios";
import BaseApi from "./BaseApi";
import { DashboardGuestType } from "src/Types/ReDoc/Dashboard";

/**
 * API для работы с Dataset
 */
class API_Dashboard extends BaseApi {


	static getToken = async ( id: string ): Promise<DashboardGuestType> => {
		
		const url =  window.host + "/Nvx.ReDoc.WebAdmin2/DashboardsAuthController/GetGuestToken";
		const requestParams = {
			resources: [
				{
					id,
					type: "dashboard" 
				}
			],
			rls: [],
			user: {
				first_name: "RDC_User",
				last_name: "Anonym",
				username: "rdc_anonym" 
			}
		};

		const res: DashboardGuestType = {
			message: "Ошибка при получении доступа к аналитической панели",
			supersetDomain: "",
			token: ""
		};
		try {
			const result = await axios.post( url, requestParams, { withCredentials: true } )
			if ( result.status !== 200 ) {
				console.warn( result );
				return res;
			}
			return result.data;
		}
		catch {
			return res;
		}
	};

};

export default API_Dashboard;
