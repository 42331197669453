import { useEffect } from 'react';
import classNames from 'classnames';
import { IconButton } from 'src/Components';
import { useToggle, useMediaQuery, useResizePanel } from 'src/Hooks';
import { ReactComponent as ChevronIcon } from 'src/Themes/assets/chevron-left.svg';
import { ReactComponent as PlusIcon } from 'src/Themes/assets/create_plus.svg';
import "./submenu.scss";

interface ISubMenuProps {
	disabled?: boolean;
	defaultExpanded?: boolean;
	children?: React.ReactNode;
	renderContent?: React.FC;
	id?: string;
	className?: string;
	title?: string;

	createBtn?: {
		id?: string;
		title: string;
		action: ( args: any ) => void;
	}

	renderTopButton?: ( isExpanded: boolean ) => React.ReactNode;
};

const SubMenu: React.FC<ISubMenuProps> = ( props ) => {

	const { id = "rdc_submenu", title, children, renderContent, createBtn, disabled = false, 
		renderTopButton,
		className, defaultExpanded = false, ...otherProps 
	} = props;

	const [expanded, toggle, setExpand] = useToggle(defaultExpanded);
	const matches = useMediaQuery("(min-width: 1320px)"); 

	const [ panelRef, onStartResize, dragging ] = useResizePanel( expanded, 20, 300, 550 );

	const onMouseDown = (e: React.MouseEvent) => {
		e.preventDefault();
		if ( !!expanded ) {
			onStartResize(e.clientX);
		}
	};
	
	const onTouchStart = (e: React.TouchEvent) => {
		if ( !!expanded ) {
			onStartResize(e.targetTouches[0].clientX);
		}
	};

	useEffect( () => {
		setExpand( matches );
	}, [matches, setExpand]);

	const getProps = () => ({
		expanded,
		...otherProps
	});

	const wrapperClasses = classNames( "rdc_submenu__wrapper", className, { "expanded": expanded } );
	const btnExHint = expanded ? "Свернуть " : "Развернуть ";
	
	return (
		<div className="rdc_submenu" id={id}>
			<div className={wrapperClasses} ref={panelRef}>
				<div className="rdc_submenu__toolbar__top">
				{ 
					( expanded && !!createBtn ) &&
					<div className="rdc_submenu__toolbar__top-btns">
						<IconButton id={createBtn.id || "create_btn"} color='yellow' block onClick={ createBtn.action } >
							<PlusIcon />
							{ createBtn.title }
						</IconButton>		
					</div>
				}
					{ !!renderTopButton && renderTopButton( expanded ) }
					{ !!title && <h2 className="submenu__header headline-large">{title}</h2> }
				</div>
				<div className="rdc_submenu__toolbar__content">
				{
					expanded &&
					<>
						<div onMouseDown={onMouseDown} onTouchStart={onTouchStart} className={classNames( "rdc_submenu-resize", { "active": dragging })} />
						{ !!children && children }
						{ !!renderContent && renderContent( getProps() ) }
					</>
				}
				</div>
				<div className="rdc_submenu__toolbar__bottom">
					
					<IconButton id="rdc_submenu-btn" className="expand_menu-btn" outline disable={disabled} onClick={toggle} data-tooltip-id="rdc_tooltip" data-tooltip-content={btnExHint}>
						<ChevronIcon />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

export default SubMenu;