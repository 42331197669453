import { URLSearchParamsInit, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from ".";

export type SectionsType = "fileList" | "file" | "newFileMenu" | "newTreatment" |
	"taskList" | "task" |
	"customerList" | "newCustomer" | "customer" |
	"serviceList" | "service" | 
	"archive" | "newArchive" | "viewArchiveAttachment" |
	"help";

const useRdcNavigate = ( isWidget: boolean = false, initParams?: URLSearchParamsInit ) => {

	const navigate = useNavigate();
	const [ searchParams, setSearchParams ] = useSearchParams(initParams);
	const pluginInfo = useAppSelector( s => s.pluginInfo );
	
	const back = ( step: number = -1 ) => navigate( step );
	const forward = ( step: number = 1 ) => navigate( step );

	const to = ( section: SectionsType, data?: any, isBlank: boolean = false, state?: any, initParams?: URLSearchParamsInit ) => {
		const redesignedSections = pluginInfo.plugins.RedesignedSections;
		switch ( section ) {
			case "file": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../fileList/" + data, { state } );
					else 
						window.open( window.host + "/new#/portal/fileList/" + data, "_blank" );
				}
				else if ( isBlank ) {
					window.open( window.host + "/WebInterfaceModule/File?fileId=" + data , "_blank");
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "/WebInterfaceModule/File?fileId=" + data
					}, "*" );
				}
				else {
					window.location.href = window.host + "/WebInterfaceModule/File?fileId=" + data;
				}
				break;
			}
			case "newFileMenu": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../newFileMenu", { state } );
					else 
						window.open( window.host + "/new#/portal/newFileMenu", "_blank" );
				}
				else if ( isBlank ) {
					window.open( window.host + "?ownerId=newFileMenu&secondaryMenuItemId=by_service", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=newFileMenu&secondaryMenuItemId=by_service"
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=newFileMenu&secondaryMenuItemId=by_service";
				}
				break;
			}
			case "newTreatment": {
				if ( !redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../newFileMenu?type=by_service&step=0&customers=" + data, { state } );
					else 
						window.open( window.host + "/new#/portal/newFileMenu?type=by_service&step=0&customers=" + data, "_blank" );
				}
				else if ( isBlank ) {
					window.open( window.host + `?wizzard=true&step=customer&id=${data}&key=0`, "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: `?wizzard=true&step=customer&id=${data}&key=0`
					}, "*" );
				}
				else {
					window.location.href = window.host + `?wizzard=true&step=customer&id=${data}&key=0`;
				}
				break;
			}
			case "taskList": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../taskList", { state } );
					else 
						window.open( window.host + "/new#/portal/taskList", "_blank" );
				}
				if ( isBlank ) {
					window.open( window.host + "?ownerId=taskList&secondaryMenuItemId=tasks_complete", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=taskList&secondaryMenuItemId=tasks_complete"
					}, "*" );	
				}
				else {
					window.location.href = window.host + "?ownerId=taskList&secondaryMenuItemId=tasks_complete";
				}
				break;
			}
			case "task": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../taskList", { state } );
					else 
						window.open( window.host + "/new#/portal/taskList", "_blank" );
				}
				else if ( isBlank ) {
					const url = `${window.host}/WebInterfaceModule/File?fileId=${data.deloId}&block=t&elementId=${data.id}`;
					window.open( url, "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: `/WebInterfaceModule/File?fileId=${data.deloId}&block=t&elementId=${data.id}`
					}, "*" );	
				}
				else {
					window.location.href = `${window.host}/WebInterfaceModule/File?fileId=${data.deloId}&block=t&elementId=${data.id}`;
				}
				break;
			}
			case "customerList": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../customerList", { state } );
					else 
						window.open( window.host + "/new#/portal/customerList", "_blank" );
				}
				else if ( isBlank ) {
					window.open( window.host + "?ownerId=customerList&secondaryMenuItemId=customers_all", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=customerList&secondaryMenuItemId=customers_all"
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=customerLis&secondaryMenuItemId=customers_all";
				}
				break;
			}
			case "customer": {
				if ( redesignedSections.includes( "customerList" ) ) {
					if ( !isBlank )
						navigate( "../customerList/" + data, { state } );
					else
						window.open( window.host + "/new#/portal/customerList/" + data );
				}
				else if ( isBlank ) {
					window.open( window.host + "?ownerId=customerList&secondaryMenuItemId=customers_all&id=" + data );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=customerList&secondaryMenuItemId=customers_all&id=" + data
					}, "*" );		
				}
				else {
					window.location.href = window.host + "?ownerId=customerList&secondaryMenuItemId=customers_all&id=" + data;
				}
				break;
			}
			case "newCustomer": {
				if ( redesignedSections.includes( "customerList" ) ) {
					if ( !isBlank )
						navigate( "../customerList/-new", { state } );
					else
						window.open( window.host + "/new#/portal/customerList/-new", "_blank" );
				}
				else if ( isBlank ) {
					window.open( window.host + "?ownerId=customerList&secondaryMenuItemId=customers_all&id=new", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=customerList&secondaryMenuItemId=customers_all&id=new"
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=customerList&secondaryMenuItemId=customers_all&id=new"	
				}
				break;
			}
			case "serviceList": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../serviceList", { state } );
					else
						window.open( window.host + "/new#/portal/serviceList", "_blank" );
				}
				else if ( isBlank ) {
					window.open( window.host + "?ownerId=serviceList&secondaryMenuItemId=services_all", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=serviceList&secondaryMenuItemId=services_all"
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=serviceList&secondaryMenuItemId=services_all";	
				}
				break;
			}
			case "service": {
				if ( redesignedSections.includes( "serviceList" ) ) {
					if ( !isBlank )
						navigate( "../serviceList/" + data, { state } );
					else
						window.open( window.host + "/new#/portal/serviceList/" + data, "_blank" );
				}
				else if ( isBlank ) {
					window.open(  window.host + "?ownerId=serviceList&secondaryMenuItemId=services_all&id=" + data, "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=serviceList&secondaryMenuItemId=services_all&id=" + data
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=serviceList&secondaryMenuItemId=services_all&id=" + data
				}
				break;
			}

			case "archive": {
				if ( redesignedSections.includes( "resultNoteArchiveItem" ) ) {
					if ( !isBlank )
						navigate( "../resultNoteArchiveItem", { state } );
					else
						window.open( window.host + "/new#/portal/resultNoteArchiveItem", "_blank" );
				}
				else if ( isBlank ) {
					window.open(  window.host + "?ownerId=resultNoteArchiveItem&secondaryMenuItemId=searchArchive", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=resultNoteArchiveItem&secondaryMenuItemId=searchArchive"
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=resultNoteArchiveItem&secondaryMenuItemId=searchArchive";
				}
				break;
			}
			case "newArchive": {
				if ( redesignedSections.includes( "resultNoteArchiveItem" ) ) {
					if ( !isBlank )
						navigate( "../resultNoteArchiveItem/-new", { state } );
					else
						window.open( window.host + "/new#/portal/resultNoteArchiveItem/-new", "_blank" );
				}
				else if ( isBlank ) {
					window.open(  window.host + "?ownerId=resultNoteArchiveItem&secondaryMenuItemId=addToArchive", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "?ownerId=resultNoteArchiveItem&secondaryMenuItemId=addToArchive"
					}, "*" );
				}
				else {
					window.location.href = window.host + "?ownerId=resultNoteArchiveItem&secondaryMenuItemId=addToArchive";
				}
				break;
			}
			case "viewArchiveAttachment": {
				/// TODO: В будущем потребуется корректировка...
				if ( redesignedSections.includes( "viewArchiveAttachment" ) ) {
					if ( !isBlank )
						navigate( "../viewArchiveAttachment/" + data, { state } );
					else
						window.open( window.host + "/new#/portal/viewArchiveAttachment/" + data, "_blank" );
				}
				else if ( isBlank ) {
					window.open(  window.host + "/Nvx.ReDoc.ResultNoteArchive/GetResultNote/" + data, "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "/Nvx.ReDoc.ResultNoteArchive/GetResultNote/" + data
					}, "*" );
				}
				else {
					window.location.href = window.host + "/Nvx.ReDoc.ResultNoteArchive/GetResultNote/" + data;
				}
				break;
			}

			default:
			case "fileList": {
				if ( redesignedSections.includes( section ) ) {
					if ( !isBlank )
						navigate( "../fileList", { state } );
					else
						window.open( window.host + "/new#/portal/fileList", "_blank" );
				}
				else if ( isBlank ) {
					window.open(  window.host + "/?ownerId=fileList&secondaryMenuItemId=files_all", "_blank" );
				}
				else if ( isWidget && !!window.top ) {
					window.top.postMessage({
						event: 'navigateTo',
						url: "/?ownerId=fileList&secondaryMenuItemId=files_all"
					}, "*" );
				}
				else {
					window.location.href = window.host + "/?ownerId=fileList&secondaryMenuItemId=files_all";
				}
				break;
			}
		}
	};

	const setParam = ( name: string, value: string ) => setSearchParams( { [name]: value } );
	const getParam = ( name: string, defaultValue: string ) => searchParams.has(name) ? searchParams.get(name) : defaultValue;
	
	return { to, back, forward, navigate, searchParams, setParam, getParam } as const;
};

export default useRdcNavigate;
