import { Field, FieldProps, FormikProps } from 'formik';
import { FormGroup } from 'reactstrap'
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FileInput } from 'src/Components/Controls';

interface IFileFiledProps {
	name: string;
	title: string;
	setData: Function;
	isRequired: boolean;
	disabled: boolean;
	defaultValue?: any;
	accept?: string;
	multiple?: boolean
	allowScan?: boolean;
};

/**
 * Файловое поле ввода
 */
const FileFiled = (props: IFileFiledProps) => {
	
	const { name, title, setData, allowScan = false, isRequired = false, multiple, disabled = false, accept = "" } = props;

	const onChangeValueHandler = ( val: any, oldValue: File[], form: FormikProps<any> ) => {
		let value: Array<File> = [];
		if ( typeof val === "function" ) {
			value = val( oldValue );
		} 
		else {
			value = val;
		}
		form.setFieldValue( name, value );
		!!setData && setData( (data: any ) => {
			return { ...data, [name]: value }
		});
	}

	const renderFiledInner = ( props: FieldProps ) => {
		const { field, form, meta } = props;
		return (
			<FormGroup className={classNames({ 'is-invalid': meta.error })}>
				<FileInput
					name={name}
					allowScan={allowScan}
					title={title} 
					isRequired={isRequired} 
					multiple={multiple}
					accept={accept}
					disabled={disabled}
					className={classNames({ 'is-invalid': meta.error })}
					values={field.value} 
					setValue={ (v) => onChangeValueHandler(v, field.value, form) } 
				/>
				{ ( !!meta.error ) && FeedbackMessage(meta.error) }
			</FormGroup>
		);
	};

	return (
		<Field id={name} name={name}>
			{ renderFiledInner }
		</Field>
	);
};



export default FileFiled;