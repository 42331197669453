import React, { useRef, useEffect, HTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import './texthint.scss';

interface TextHintProps extends HTMLAttributes<HTMLDivElement> {
	children: string;
	hint?: string;
	onParent?: boolean;
	align?: "left" | "center" | "right";
	isList?: boolean;
	disable?: boolean;
};

/** Компонента для отображения текста, при переполнении текст обрезается и показывается подсказка  */
const TextHint: React.FC<TextHintProps> = ({ children, disable = false, style, isList = false, align = "left", className, hint = '', onParent = true }) => {

	const spanRef = useRef<HTMLDivElement>(null);
	const [ width, setWidth ] = useState<number>(-1);
	
	const text = !!hint ? hint : children;

	useEffect( () => {
		if ( disable ) return;
		if ( !!spanRef && !!spanRef.current ) {
			const spanSize = spanRef.current.clientWidth;
			setWidth( ( spanSize - 50 ) );
		}
	}, [ disable ] );

	useEffect( () => {
		onRecalc();
	// eslint-disable-next-line
	}, [ children, hint, onParent, disable, isList, width ] );

	const onRecalc = () => {
		if ( disable ) return;
		if ( !!spanRef && !!spanRef.current ) {
			const textSize = spanRef.current.scrollWidth;
			const element = onParent ? spanRef.current.parentElement : spanRef.current;
			if (textSize > width) {
				element?.setAttribute( "data-tooltip-id", "rdc_tooltip" );
				if ( isList ) {
					const newText = text.replaceAll( ",", "</br>");
					element?.setAttribute( "data-tooltip-html", newText );
				} else {
					element?.setAttribute( "data-tooltip-content", text );
				}
			}
			else {
				element?.removeAttribute( "data-tooltip-content" );
				element?.removeAttribute( "data-tooltip-id" );
			}
		}
	};

	return (
		<div ref={spanRef} title={text} className={classNames("rdc_texthint", className)} style={{ textAlign: align, ...style, width }}>
			{ width !== -1 ? children : "..." }
		</div>
	);
};

export default TextHint;
