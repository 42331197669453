import React from "react";
import classNames from "classnames";
import { BlockedContainer } from "src/Containers";
import { RDCModal, RDCModalBody, RDCModalFooter, RDCModalHeader } from "src/Containers/RDCModal";
import { IconButton } from "src/Components";
import { ReactComponent as AlertIcon } from "src/Themes/assets/BigAlert.svg";
import { ReactComponent as TrashIcon } from "src/Themes/assets/BigTrash.svg";
import "./ConfirmationModal.scss";

interface IConfirmationModalProps extends React.HtmlHTMLAttributes<HTMLDivElement>{
	title: string;
	text: string;
	children?: React.ReactNode;

	renderFooterLine?: boolean;
	color?: "red" | "";
	icon?: string;
	confirmTextButton?: string;
	cancelTextButton?: string;

	isShow?: boolean;
	onClose: () => void;
	onConfirm: () => void;
};

const ConfirmationModal: React.FC<IConfirmationModalProps> = ( props ) => {

	const { isShow = true, title, text, children, className, color = "", 
		icon = "std", confirmTextButton = "Да", cancelTextButton = "Отмена",
		renderFooterLine, onClose, onConfirm, ...otherProps 
	} = props;

	const onConfirmHandler = () => {
		onClose();
		onConfirm();
	};

	if ( !isShow ) return null;
	
	const renderIcon = () => {
		if ( icon === "std" ) {
			return <AlertIcon />;
		}
		else if ( icon === "delete" ) {
			return <TrashIcon width={120} />;
		}
	};

	return (
		<BlockedContainer isFullScreen>
			<RDCModal id="rdc_ask_confirm_modal" className={classNames(className, color)} { ...otherProps }>
				<RDCModalHeader onClose={onClose}>{title}</RDCModalHeader>
				<RDCModalBody pTextCentered>
					<>
						{ renderIcon() }
						<div className="body_large">
							<p>{text}</p>
							{ !!children && children }
						</div>
					</>
				</RDCModalBody>
				<RDCModalFooter renderFooterLine={renderFooterLine}>
					<IconButton outline onClick={onClose}>{cancelTextButton}</IconButton>
					<IconButton color="red" onClick={onConfirmHandler}>{confirmTextButton}</IconButton>
				</RDCModalFooter>
			</RDCModal>
		</BlockedContainer>
	);
};

export default ConfirmationModal;
