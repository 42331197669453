
import classNames from "classnames";
import "./ClearBtn.scss";

interface IClearBtnProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
	isClose?: boolean;
	disable?: boolean;
	title?: string;
};

const ClearBtn: React.FC<IClearBtnProps> = ( { className, disable = false, title = "", isClose, ...otherProps } ) => {
	const text = !!title ? title : ( isClose ? "Закрыть" : "Сбросить" );
	return (
		<button 
			type="button" 
			disabled={disable}
			className={classNames( "rdc_clearbtn", className)} 
			data-tooltip-id={ disable ? "" : "rdc_tooltip" } 
			data-tooltip-content={ text } 
			{...otherProps}
		>
			<svg width="32" height="32" viewBox="0 0 32 32" fill="inherit">
				<path d="M22.295 11.115C22.6844 10.7256 22.6844 10.0944 22.295 9.705C21.9056 9.31564 21.2744 9.31564 20.885 9.705L16 14.59L11.115 9.705C10.7256 9.31564 10.0944 9.31564 9.705 9.705C9.31564 10.0944 9.31564 10.7256 9.705 11.115L14.59 16L9.705 20.885C9.31564 21.2744 9.31564 21.9056 9.705 22.295C10.0944 22.6844 10.7256 22.6844 11.115 22.295L16 17.41L20.885 22.295C21.2744 22.6844 21.9056 22.6844 22.295 22.295C22.6844 21.9056 22.6844 21.2744 22.295 20.885L17.41 16L22.295 11.115Z" fill="inherit"></path>
			</svg>
		</button>
	);
};

export default ClearBtn;
