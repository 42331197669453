import React from "react";
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import { FormGroup, Label } from 'reactstrap'
import { IFieldProps, getValue } from '../';

/**
 * Рендер поля выпадающего списка
 */
const SelectBoxField = (props: IFieldProps) => {

	const { name, title, setData, options, isRequired = false, disabled = false } = props;

	const onChangeValueHandler = (e: any, form: any) => {
		e.persist();
		const value = e.target.value;
		form.setFieldValue(name, value);
		!!setData && setData((data: any) => {
			return { ...data, [name]: value };
		});
	}

	const renderFields = ( props: FieldProps ) => {
		const error = !!props.form.errors && getValue(props.form.errors, name);
		// const touch = !!props.form.touched && getValue(props.form.touched, name);

		return (
			<FormGroup disabled={disabled}>
			{
				!!title &&
				<Label htmlFor={name}>
					<b className={isRequired ? 'field_asterix' : ''}>
						{title}
					</b>
				</Label>
			}
			<fieldset className={classNames("rdcc-radio__list", { 'is-invalid': error })}>
			{
				options.map((o, index) => (
					<div key={index} className='rdcc-radio__item'>
						<input
							type="radio"
							id={"options_"+ o.value}
							{...props.field}
							defaultChecked={o.value === props.field.value}
							value={o.value}
							onChange={e => onChangeValueHandler(e, props.form)}
						/>
						<label htmlFor={"options_" + o.value}>{o.display}</label>
					</div>
				))
			}
			</fieldset>
			{ error && FeedbackMessage( error ) }
			</FormGroup>
		);
	};

	return (
		<Field id={name} name={name}>
			{ renderFields }
		</Field>
	);
}

export default SelectBoxField;