import { Dispatch, FC, HTMLAttributes, SetStateAction, useEffect, useState } from "react";
import classNames from "classnames";
import { CheckBox, GroupControl, SimpleSelect, TextBox, RdcSelect } from "src/Components/Controls";
import { ScanConfigType, ScanSettingType } from "src/Types/Scan";
import API from "src/API";
import { EnumItemType } from "src/Components/Controls/SimpleSelect";
import { UpdateListStates } from "src/Components/Controls/RdcSelect";
import st from "./Scan.module.scss";

interface ISettingsPanelProps extends HTMLAttributes<HTMLDivElement> {
	enable: boolean;
	version?: string;
	config: ScanConfigType;
	setting: ScanSettingType;
	setSettings: Dispatch<SetStateAction<ScanSettingType>>;
};

/** Тут рисуем контролы настроек */
const SettingsPanel: FC<ISettingsPanelProps> = ( props ) => {

	const { enable, version = "", config, setting, setSettings } = props;

	const [ availableSource, setAvailableSource ] = useState<string[]>([]);
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	
	// Получение настроек и списка устройств
	useEffect( () => {
		if ( enable ) {
			getSettings();
			getSourceList();
		}
	// eslint-disable-next-line
	}, [ enable ] );

	useEffect( () => {
		if ( !!availableSource.length ) {
			if ( !!window.sessionStorage ) {
				const lastScanId = window.sessionStorage.getItem('scandialog_sourcename');
				if ( !!lastScanId && availableSource.includes( lastScanId ) ) {
					setSetting( "SourceName", lastScanId );
				}
			}
		}
	// eslint-disable-next-line
	}, [ availableSource ] );

	const getSettings = () => {
		setIsLoading( true );
		API.Scan.getSettings()
			.then( result => {
				setSettings( {
					Mode: result.Mode || "1",
					ADFMode: result.ADFMode || "0",
					Format: result.Format || "2",
					Quality: result.Quality || "50",
					Dpi: result.Dpi || "300",
					UseADF: (result as any).UseADF === "true",
					SourceName: "",
					docName: "document"
				});
			})
			.finally( () => setIsLoading(false) );
	};

	const getSourceList = () => {
		setIsLoading( true );
		API.Scan.getSourceList( config )
			.then( data => {
				if ( !!data && Array.isArray( data ) ) {
					setAvailableSource( data );
				}
			})
			.finally( () => setIsLoading(false) );
	};

	const setSetting = ( fieldName: string, value: string | boolean ) => {
		setSettings( prev => ({
			...prev,
			[fieldName]: value
		}))
	};

	const onUpdateScanList = ( updateState: (updState: UpdateListStates) => void ) => {
		// Устанавливаем состояние в "запрос отправлен" и собственно отправляем запрос
		updateState( UpdateListStates.Loading);
		API.Scan.updateList( config )
			.then( result => {
				if ( !result ) {
					// Если обновить не получилось показываем ошибку
					API.Toast.show( false, "Ошибка при попытки обновить список сканеров." );
					return;
				}
				API.Scan.getSourceList( config )
					.then( data => {
						if ( !!data && Array.isArray( data ) ) {
							setAvailableSource( data );
							// Устанавливаем состоние в "успешное обновление"
							updateState(UpdateListStates.Updated);
							// Через 2 минуты сбрасываем состояние, можно опять обновляться
							setTimeout(() => {
								updateState(UpdateListStates.Idle);
							}, 120000);
						}
					});
			})
			.finally( () => updateState( UpdateListStates.Idle ) );
	};

	const onSetSourceName = ( value: string ) => {
		setSetting( "SourceName", value );
		saveSource( value );
	};

	const onSetUseADF = ( value: SetStateAction<boolean> ) => {
		let val: boolean;
		if ( typeof value === "function" )
			val = value( !!setting.UseADF );
		else 
			val = value;
		setSetting( "UseADF", val );
	};

	const saveSource = ( name?: string | null) => {
		if ( !!window.sessionStorage ) {
			if ( !name )
				window.sessionStorage.removeItem('scandialog_sourcename');
			else
				window.sessionStorage.setItem('scandialog_sourcename', name );
		}
	};

	return (
		<div className={ classNames( st.settings, { [st.disabled]: !enable} )}>

			<div className={st.settingsFields}>

				<GroupControl title="Настройка сканирования" name="printer">
					<RdcSelect 
						id="printer"
						isInvalid={!setting.SourceName}
						isWait={isLoading}
						showClearBtn
						disabled={!enable}
						placeholder="Сканер не выбран"
						options={availableSource} 
						search={{
							placeholder: "Поиск по списку...",
						}}
						value={setting.SourceName} 
						setValue={ v => onSetSourceName( v.toString() )} 
						onUpdateList={onUpdateScanList}
					/>
				</GroupControl>

				<GroupControl title="Качество сканирования" name="quality">
					<SimpleSelect 
						id="quality" 
						isOwnerList 
						disabled={!enable}
						options={QualityTypes}
						value={setting.Quality} 
						setValue={ v => setSetting("Quality", v.toString())} 
					/>
				</GroupControl>

				<GroupControl title="Цветовая схема" name="cmode">
					<SimpleSelect 
						id="cmode" 
						isOwnerList 
						disabled={!enable}
						options={ModesTypes} 
						value={setting.Mode} 
						setValue={ v => setSetting( "Mode", v.toString())} 
					/>
				</GroupControl>
				
				<GroupControl row>
					<GroupControl title="Формат" name="format" hint="Если отсканировано более 1 страницы, то документ автоматически сохранится в формат PDF.">
						<SimpleSelect 
							id="format" 
							rtl 
							isOwnerList 
							disabled={!enable}
							options={FormatTypes} 
							value={setting.Format} 
							setValue={ v => setSetting( "Format", v.toString())} 
						/>
					</GroupControl>
					<GroupControl title="Разрешение" name="dpi">
						<SimpleSelect 
							id="dpi" 
							rtl 
							isOwnerList 
							disabled={!enable}
							options={DpiTypes} 
							value={setting.Dpi} 
							setValue={ v => setSetting( "Dpi", v.toString())} 
						/>
					</GroupControl>
				</GroupControl>
				
				<GroupControl title="Лоток подачи">
					<CheckBox name="UseADF" label="Использовать" value={setting.UseADF} setValue={onSetUseADF} />
					{
						setting.UseADF &&
						<SimpleSelect 
							style={{ marginTop: '5px' }}
							options={ADFModeTypes}
							disabled={!enable}
							value={setting.ADFMode}
							setValue={v => setSetting( "ADFMode", v.toString())}
						/>
					}
				</GroupControl>

				<GroupControl title="Имя документа">
					<TextBox 
						withoutIcon 
						isField
						disabled={!enable}
						value={setting.docName || ""} 
						setValue={ ( v: string ) => setSetting( "docName", v) } 
						isClearBtn={false}/>
				</GroupControl>

			</div>

			<div className={st.version}>v.{version || "0"}</div>
		</div>
	);
};

const QualityTypes: Array<EnumItemType> = [
	{
		id: "25",
		title: "25% - низкое качество изображения"
	},
	{
		id: "50",
		title: "50% - среднее качество изображения"
	},
	{
		id: "75",
		title: "75% - высокое качество изображения"
	},
	{
		id: "100",
		title: "100% - максимальное качество изображения"
	}
];

const ModesTypes: Array<EnumItemType> = [
	{
		id: "0",
		title: "Черно-белое"
	},
	{
		id: "1",
		title: "Цветное"
	}
];

const FormatTypes: Array<EnumItemType> = [
	{
		id: "0",
		title: "Jpeg"
	},
	{
		id: "1",
		title: "Tiff"
	},
	{
		id: "2",
		title: "Pdf"
	}
];

const DpiTypes: Array<EnumItemType> = [
	{
		id: "75",
		title: "75"
	},
	{
		id: "100",
		title: "100"
	},
	{
		id: "150",
		title: "150"
	},
	{
		id: "200",
		title: "200"
	},
	{
		id: "300",
		title: "300"
	},
	{
		id: "350",
		title: "350"
	},
	{
		id: "600",
		title: "600"
	},
];

const ADFModeTypes: Array<EnumItemType> = [
	{
		id: "0",
		title: "Односторонний"
	},
	{
		id: "1",
		title: "Двусторонний"
	}
];

export default SettingsPanel;
