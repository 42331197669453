import { LocalStorageEventDetailsType } from "./LocalStorage";

const RDC: RDCType = {

	log: ( message?: any, ...optionalParams: any[] ) => {
		if ( window.isDev ) {
			console.log(message, ...optionalParams);
		}
	},

	localStorage: {
		getItem: ( key: string ) => window.localStorage.getItem( key ) || "",
		setItem: ( key: string, value: string ) => {
			const event = new CustomEvent<LocalStorageEventDetailsType>( "localStorageUpdate", { detail: { type: "update", key, value } } );
			window.localStorage.setItem( key, value );
			window.dispatchEvent( event );
		},
		removeItem: ( key: string ) => {
			const event = new CustomEvent<LocalStorageEventDetailsType>( "localStorageUpdate", { detail: { type: "delete", key } } );
			window.localStorage.removeItem( key );
			window.dispatchEvent( event );
		}
	}

};

export type RDCType = {
	log: ( message?: any, ...optionalParams: any[] ) => void;
	localStorage: {
		getItem: ( key: string ) => string;
		setItem: ( key: string, value: string ) => void;
		removeItem: ( key: string ) => void;
	}
};

export default RDC;