
export type ScanConfigType = {
    scanModuleInstallUrl: string | null;
    scanModuleVersion: string;
    scanModuleWorkUrl: string | null;
};

export type ScanSettingType = {
    ADFMode?: string;
    Dpi: string;
    Format: string;
    Mode: string;
    Quality: string;
    SourceName?: string;
    UseADF?: boolean;
    docName?: string;
};

export type ScanVersionType = {
    version: number;
    versionAsse: string;
};

export const enum Rotate {
    Left = -90,
    Right = 90
};

export type ScanFileType = {
    filename: string;
    filename_utf8: string;
    hasError: boolean;
    result: Blob;
};
