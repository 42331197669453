import { useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import { Field, FieldInputProps, FieldProps, FormikProps } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import { useOutsideClick, useToggle } from "src/Hooks";
import FeedbackMessage from "./FeedBackMessage/FeedbackMessage";
import Calendar, { PositionType } from "src/Components/Calendar/Calendar";
import { ReactComponent as CalIcon } from "src/Themes/assets/calendar.svg";
import DateFormater from "src/Utils/DateFormater";
import { getFullOffsetBy } from "src/Utils";

interface IDateTimeFieldProps {
	name: string;
	title: string;
	setData?: Function;
	isRequired?: boolean;
	disabled?: boolean;
	defaultValue?: any;
	withTime?: boolean;
	needParse?: boolean;

	minDate?: string | Date;
	maxDate?: string | Date;
};

/**
 * Текстовое поле
 */
const DateTimeField = (props: IDateTimeFieldProps) => {

	const { name, title, setData, isRequired = false, disabled = false, needParse = false } = props;

	const [ isOpened, toggle, setIsOpened ] = useToggle(false);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const modalRef = useOutsideClick<HTMLDivElement>( () => setIsOpened(false) );
	const [ position, setPosition ] = useState<PositionType>(null);

	useLayoutEffect( () => {
		const calcOffsetModalPosition = () => {
			const wr = wrapperRef.current;
			if ( !!wr ) {
				const offset = getFullOffsetBy( wr, "rdc");
				let top = ( offset.top - offset.scrollTop ) + wrapperRef.current.offsetTop + 5;
				if ( top < wrapperRef.current.offsetTop * 2 + 10 ) {
					setIsOpened(false);
				}
				const modalBottom = top + 370;
				if ( modalBottom > document.body.clientHeight ) {
					top -= ( 370 + wrapperRef.current.offsetTop + 10 );
				}
				setPosition( { top, left: offset.left } );
			}
		};
		const scrollContainer = window.document.querySelector( ".scrolled" );
		if ( !!scrollContainer ) {
			scrollContainer.addEventListener( "scroll", calcOffsetModalPosition);
		}

		calcOffsetModalPosition();

		return () => {
			if ( !!scrollContainer ) {
				scrollContainer.removeEventListener( "scroll", calcOffsetModalPosition)
			}
		};
	// eslint-disable-next-line
	}, [ isOpened ] );

	const onChangeValueHandler = (form: FormikProps<any>, value: Date ) => {
		form.setFieldValue(name, needParse ? DateFormater.toShortString(value) : value );
		form.setFieldTouched(name, true);
		!!setData && setData((data: any) => {
			return { ...data, [name]: value }
		});
		toggle();
	};

	const onChangeHandler = (e: any, form: any): any => {
		e.persist();
		form.setFieldTouched(name, true);
		form.setFieldValue(name, e.target.value);
		!!setData && setData((data: any) => {
			return { ...data, [name]: e.target.value }
		});
	};

	const getMaxDate = (): Date|undefined => {
		const val = props.maxDate;
		if ( !val ) return undefined;
		if ( val === "today" ) {
			const tmp = new Date( Date.now() );
			return new Date( tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), 0,0,0,0 );
		}
	};

	const renderBody = ( form: FormikProps<any>, field: FieldInputProps<any> ) => {
		const value = DateFormater.parseDate(field.value, needParse);
		const values =  !!value ? [value] : []
		return (
			<div ref={modalRef} className="calendar_wrapper">
				<Calendar
					disabled={disabled}
					maxDate={getMaxDate()}
					values={values}
					onSelectDate={ val => onChangeValueHandler(form, val)}
					position={position}
				/>
			</div>
		);
	};

	const renderInnerField = ( { form, meta, field }: FieldProps ) => {
		const value = getDateValue(field.value, needParse);
		return (
			<FormGroup>
				{
					!!title &&
					<Label htmlFor={name}>
						<b className={isRequired ? 'field_asterix' : ''}>
							{title}
						</b>
					</Label>
				}
				<div id="rdc-calendar_wrapper" className="rdc-field__wrapper">
					{ isOpened && createPortal( renderBody( form, field ), document.body) }
				</div>
				<div id="rdc-field__placeholder"ref={wrapperRef} className={cn("rdc-field__placeholder",  { 'is-invalid': (meta.error && meta.touched) } )} onClick={() => toggle() }>
					<CalIcon fill={ !!value ? "var(--rdc-blue-color, #2196F3)" : "var(--rdc-gray-dark, #748398)" } />
					<InputMask 
						{...field}
						alwaysShowMask={false}
						placeholder="ДД.ММ.ГГГГ"
						value={value} 
						onChange={ (e:any) => onChangeHandler(e, form)}
						mask="99.99.9999" 
					/>
				</div>
				{(meta.error && meta.touched) && FeedbackMessage(meta.error)}
			</FormGroup>
		);
	};

	return (
		<Field id={name} name={name}>
			{ renderInnerField }
		</Field>
	);
};

/** Возвращает строкое представление значения */
const getDateValue = ( value: Date | string, needParse: boolean = false ): string => {
	if ( typeof value === "object" && !!value.getTime ) {
		return DateFormater.toShortString(value);
	} 
	else if ( !needParse ) {
		const date = new Date(value);
		return DateFormater.toShortString(date);
	}
	return value?.toString() || "";
};

export default DateTimeField;
