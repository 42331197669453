import SimpleSelect from './SimpleSelect';

export type EnumsType = Array<EnumItemType>;

export type EnumItemType = {
	id: ValueType,
	title?: string
} | string | number;


export type ValuesType = ValueType|ValueType[];
export type ValueType = string | number;

export interface SimpleSelectProps {
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	searchEnable?: boolean;

	value?: ValueType | Array<ValueType> | null;
	setValue: React.Dispatch<React.SetStateAction<string|Array<string>>>;

	minSearchLength?: number;
	onSearch?: ( tern: string ) => void;

	options?: EnumsType;
	multiple?: boolean;
	isWait?: boolean;
	defaultOpen?: boolean;
	showClearBtn?: boolean;
};

export default SimpleSelect;
