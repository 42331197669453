import { FC, HTMLAttributes } from "react";
import classNames from "classnames";
import Hint from "./Hint";
import st from "./GroupControl.module.scss";

interface IGroupControlProps extends HTMLAttributes<HTMLDivElement> {
	name?: string;
	row?: boolean;

	hint?: string;
};

const GroupControl: FC<IGroupControlProps> = ( { name, row = false, hint = "", children, className, title, ...otherProps } ) => (
	<div className={classNames( st.groupControl, className, { [st.row]: row } ) } { ...otherProps } >
		{
			!!title && 
			<div className={st.groupControlHeader}>
				<span>{title}</span>
				{ !!hint && <Hint label={title}>{hint}</Hint> }
			</div>
		}
		{ children }
	</div>
);

export default GroupControl;
