import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

export const initI18next = () => {
	i18next
		.use( Backend )
		.use( initReactI18next )
		.init( {
			debug: window.isDev,
			ns: "translation",
			load: 'languageOnly',
			lng: 'rus',
			supportedLngs: [ 'rus', 'belg', 'dev' ],
			backend: {
				loadPath: !!window.isDev ? '/locales/{{lng}}/{{ns}}.json' : '/new/locales/{{lng}}/{{ns}}.json',
			},
			interpolation: {
				// экранирование уже есть в React, поэтому отключаем
				escapeValue: false,
			},
		});
};

export default i18next;
