
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import CheckBox from "../CheckBox/CheckBox";
import ClearBtn from "src/Components/ClearBtn/ClearBtn";
import { ReactComponent as ArrowIcon } from './Arrow.svg';
import { RdcOptionType, RdcOptionsType, UpdateListStates, ValueType } from ".";
import { useOutsideClick } from "src/Hooks";
import { OffsetsType } from "src/Types";
import { getOffsetElement } from "src/Utils/DomUtils";
import st from "./RdcSelect.module.scss";

interface IRdcSelectProps extends React.AreaHTMLAttributes<HTMLDivElement> {

	name?: string;
	isWait?: boolean;
	multiple?: boolean;
	placeholder?: string;
	disabled?: boolean;
	isInvalid?: boolean;
	defaultOpen?: boolean;
	autoFocus?: boolean;
	rtl?: boolean;

	showClearBtn?: boolean;
	value?: ValueType | Array<ValueType> | null;
	setValue: React.Dispatch<React.SetStateAction<ValueType|ValueType[]>>;
	options?: RdcOptionsType;

	search?: {
		placeholder?: string;
		minSearchLength?: number;
		debouncedDelay?: number;
		onSearch?: ( tern: string ) => void;
	};

	creatable?: {
		placeholder?: string;
		onCreate: ( newValue: string ) => void;
	};

	onUpdateList?: ( setState: ( state: UpdateListStates ) => void ) => void;

	onClose?: () => void;
};

const RdcSelect: React.FC<IRdcSelectProps> = (props) => {
	
	const { 
		name, value, setValue, options = [], className,
		rtl = false, disabled = false, placeholder, isInvalid = false,
		defaultOpen = false, showClearBtn = false,
		creatable, search, onUpdateList,
		multiple = false, isWait = false, autoFocus = false, onClose, ...other
	} = props;

	const wrapperRef = useOutsideClick<HTMLDivElement>( (event) => {
		// Закрываем его
		event.stopPropagation();
		!!onClose && onClose();
		setIsOpen(false);
	}, "click" );

	const listRef = useRef<HTMLDivElement>(null);
	const timerRef = useRef<NodeJS.Timeout>();
	const [ preparedOptions, setPreparedOptions ] = useState<OptionType[]>([]);
	const [ isOpen, setIsOpen ] = useState<boolean>( !disabled && defaultOpen);
	const [ textValue, setTextValue ] = useState<string>('');
	const [ filteredData, setFilteredData ] = useState<OptionType[]>([]);
	const [ updateListState, setUpdateListState ] = useState<UpdateListStates>(UpdateListStates.Idle);
	const [ listPosition, setListPosition ] = useState<OffsetsType>();

	useEffect( () => {
		if ( options.length ) {
			const list = options.map( prepareOption );
			setPreparedOptions( list );
		}
	}, [ options ] );

	useEffect(() => {
		if ( !!search && ( search?.minSearchLength === -1 || textValue.length >= (search?.minSearchLength || 3) ) ) {
			if ( !!search?.onSearch ) {
				const time = search.debouncedDelay ?? 500;
				if ( timerRef.current ) {
					clearTimeout( timerRef.current );
					timerRef.current = undefined;
				}
				timerRef.current = setTimeout(() => {
					if ( !!search.onSearch ) 
						search.onSearch( textValue );
				}, time );
			}
			else {
				const fileterd = preparedOptions.filter( onApplyFilter );
				setFilteredData( fileterd );
			}
		} 
		else { 
			setFilteredData( preparedOptions );
		}
	// eslint-disable-next-line
	}, [ preparedOptions, textValue ] );

	useEffect( () => {
		if ( !isOpen ) return;
		if ( !!listRef.current && !!wrapperRef.current ) {
			const offset = getOffsetElement( wrapperRef.current );
			if ( ( offset.top + listRef.current.clientHeight + offset.height ) > window.document.body.clientHeight ) {
				offset.top -= listRef.current.clientHeight + 1;
				listRef.current.classList.add( st.revertBody );
				wrapperRef.current.classList.add( st.revertHeder );
			} 
			else {
				offset.top += offset.height;
			}
			setListPosition( offset );
		}
	// eslint-disable-next-line
	}, [ isOpen ] );

	const onApplyFilter = ( ( item: OptionType ) => {
		const term = textValue.toLowerCase() ?? "";
		if ( !term ) return true;
		return item?.title.toLocaleLowerCase().indexOf( term ) !== -1;
	} );

	const getIsChecked = ( item: RdcOptionType ): boolean => {
		const id = typeof item !== "object" ? item : item?.id;
		if ( !!multiple ) {
			return (value as Array<any>).includes(id);
		} 
		return value === id;
	};

	const onChangeHandler = ( item: OptionType ) => {
		if ( multiple ) {
			const valueArray = [ ...(value as string[]) ];
			const index = valueArray.indexOf( item.id );
			if ( index === -1 ) {
				valueArray.push( item.id );
			}
			else {
				valueArray.splice( index, 1);
			}
			setValue(valueArray);
		}
		else {
			setValue( item.id );
			!!onClose && onClose();
			setIsOpen(false);
		}
	};

	const onChangeTextHandler = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		const val = e.target.value;
		setTextValue(val);
	}; 

	const onShowListChange = () => {
		const newValue = !isOpen;
		if ( !disabled ) {
			setIsOpen( newValue );
		}
		if ( !newValue && !!onClose ) {
			onClose();
		}
		setTextValue( "" );
	}

	const onClearHandler = ( e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setValue( multiple ? [] : '');
		setTextValue("");
	};

	const getPlaceHolderText = () => {
		const length = search?.minSearchLength || 3 - textValue?.length || 0;
		if ( isWait ) 
			return "Ведется поиск...";
		if ( length > 0 && length < 3 ) 
			return `Введите ещё ${length} символа`;
		else 
			return "";
	};

	const renderOption = ( item: OptionType, index: number ) => {
		const isChecked = getIsChecked( item.id );
		const itemClassses = classNames( "rdccs__listitem", {
			"item-checked": isChecked
		});
		return (
			<li key={index} className={itemClassses} id={ "selectItemFor_" + name + "_val-" + (!!item.id ? item.id : "empty") } onClick={ () => onChangeHandler(item)}>
				{ 
					!!multiple &&
					<CheckBox name={item.id} value={isChecked} />
				}
				<span className="body" title={item.title}>{item.title}</span>
			</li>
		);
	};

	const getStylesForList = (): React.CSSProperties => {
		const styles = { 
			left: 0, 
			top: 0, 
			zIndex: '900',
			width: 100
		};
		if ( !!listPosition ) {
			styles.left = listPosition.left;
			styles.top = listPosition.top;
			styles.width = listPosition.width;
		}
		return styles;
	};

	const onClickUpdateList = ( e: React.MouseEvent<HTMLSpanElement, MouseEvent> ) => {
		e.stopPropagation();
		if ( !!onUpdateList ) {
			// Если текущее состояния не равно 0 
			// ( состояния - отправлен запрос или получен ответ менее чем 2 минуты назад )
			if ( updateListState !== UpdateListStates.Idle ) {
				// выходим ничего не делая, дабы не грузить сервер повторными запросами
				return;
			}
			onUpdateList( setUpdateListState ); 
		}
	};

	/** Возвращаем HTML-содержимое кнопки в зависимости от состояния */
	const getUpdateListBtnParams = () => {
		const result = {
			classNameIcon: '',
			text: ''
		};
		switch( updateListState ) {
			case 1: {
				result.classNameIcon = st.iconSpinner;
				result.text = "  Список обновляется...";
				break;
			}
			case 2: {
				result.classNameIcon = st.iconComplete;
				result.text = "  Список успешно обновлен";
				break;
			}
			case 0:
			default: {
				result.classNameIcon = st.iconReload;
				result.text = "  Обновить список";
				break;
			}
		}
		return result;
	};

	const renderUpdateListItem = () => {
		if ( !onUpdateList ) return null;
		const res = getUpdateListBtnParams();
		return (
			<div 
				id='list-reload' 
				aria-disabled={updateListState !== 0}
				className={st.reloadList}
				onClick={onClickUpdateList}
				style={ updateListState === 2 ? { color:  "var( --rdc-green-color, #2D9E4D)" } : undefined }
			>
				<span className={res.classNameIcon} />
				{ res.text }
			</div>
		);
	};

	const renderList = () => (
		<div ref={listRef} className="rdccs__body" style={getStylesForList()}>
			{
				!!getPlaceHolderText() &&
				<p className="rdcc__body__placeholder">{getPlaceHolderText()}</p>
			}
			{
				!filteredData.length &&
				<p className="rdcc__body__placeholder error">По Вашему запросу ничего не найдено</p>
			}
			<ul className="rdccs__body__listitems">
				{ filteredData.map( renderOption )}
			</ul>
			{ renderUpdateListItem() }
		</div>
	);

	const getText = (): string => {
		if ( isOpen ) 
			return textValue;
		else {
			if ( !!value ) {
				if ( multiple && Array.isArray( value ) ) {
					const titleList = preparedOptions.filter( opt => value.includes( opt.id ) ).map( o => o.title );
					return titleList.join( ', ');
				}
				return preparedOptions.find( o => o.id === value )?.title || "";
			}
		}
		return "";
	};

	const getPlaceholder = () => {
		if ( isOpen ) {
			if ( !!creatable && !!creatable.placeholder )
				return creatable?.placeholder || "";
			else if ( !!search && !!search?.placeholder )
				return search?.placeholder || "";
		}
		return placeholder || "";
	};

	const renderPlaceholder = () => (
		<input 
			value={getText()}
			placeholder={getPlaceholder()}
			type="text" 
			readOnly={ !creatable && !search }
			autoFocus={autoFocus}
			className="rdccs__header__input"
			onChange={onChangeTextHandler}
		/>
	);

	const classes = classNames( st.RdcControl, className, { 
		[st.rtl]: rtl, 
		[st.opened]: isOpen,
		[st.isInvalid]: isInvalid
	});

	return (
		<>
		<div ref={wrapperRef} id={name} className={classes} {...other} onClick={onShowListChange}>
			<ArrowIcon height={33} width={32} />
			{ renderPlaceholder() }
			<div className="rdccs__header-toolbar">
				{
					!!isWait && <div className="spiner" />
				}
				{
					( !!showClearBtn && !!value ) &&
					<ClearBtn onClick={onClearHandler} />
				}
			</div>
			{ isOpen && createPortal( renderList(), document.body) }
		</div>
		</>
	);
};

type OptionType = {
	id: string;
	title: string;
};

const prepareOption = ( item: RdcOptionType ): OptionType => {
	if ( typeof item !== "object" ) {
		return {
			id: item.toString(),
			title: item.toString()
		};
	} 
	else {
		return {
			id: item.id.toString(),
			title: item?.title || item.id.toString()
		};
	}
};

export default RdcSelect;
