import { HTMLAttributes, useRef, useState } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import { useInputHandler, useOutsideClick } from "src/Hooks";
import ClearBtn from "../ClearBtn/ClearBtn";
import api from "src/API";
import { GarAddressType, GarHintType } from "src/Types/ReDoc/Gar";
import { getRelativeOffsetBy } from "src/Utils";
import st from "./GarField.module.scss";

interface IGarFieldProps extends HTMLAttributes<HTMLDivElement> {
	isOwner?: boolean;
	name: string;
	initValue?: string;
	onChangeAddress?: ( address: GarAddressType ) => void;
	placeHolder?: string;
	disable?: boolean;
	sendOnlyChange?: boolean;
};

const GarField: React.FC<IGarFieldProps> = ( props ) => {

	const { name, title, initValue = "", className, style, onChangeAddress, isOwner = false, disable = false, placeHolder, sendOnlyChange = true } = props;

	const wrapperRef = useRef<HTMLDivElement>(null);
	const modalRef = useOutsideClick<HTMLDivElement>( () => setIsOpen(false) );
	const inputProps = useInputHandler( initValue, onChange, 500 );
	const [ currentId, setCurrentId ] = useState<string>('');
	const [ , setCurrentAddress ] = useState<GarAddressType|null>(null);
	const [ options, setOptions ] = useState<Array<GarHintType>>([]);
	const [ isWait, setIsWait ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ msg, setMsg ] = useState<string>("");

	/** Запрос на получение подсказок */
	const getHints = ( val: string) => {
		setIsWait(true);
		api.Gar.getHints( val )
			.then( result => {
				if ( result.hasError ) {
					setMsg( result.errorMessage || "" );
					return;
				} 
				const msg = !!result.result.length ? "" : "Не найдено";
				setMsg(msg);
				setOptions( result.result );
			})
			.finally( () => setIsWait(false) );
	};

	/** Получаем данные адреса */
	const getAddress = ( url: string ) => {
		setIsWait(true);
		const id = url.substring(url.lastIndexOf('/') + 1);
		api.Gar.getAddress( id )
			.then( result => {
				if ( result.hasError ) {
					setMsg( result.errorMessage || "" );
					return;
				} 
				setMsg("");
				setCurrentAddress( result.result );
				if ( !!onChangeAddress && result.result ) {
					onChangeAddress( result.result );
				}
			})
			.finally( () => setIsWait(false) );
	};

	/** Вызывается при клике на результат поиска */
	const onChangeHandler = ( item: GarHintType ) => {
		setCurrentId( item.id );
		setIsOpen(false);
		getAddress( item.id );
	};

	/** Вызывается при вводе в поле поиска */
	function onChange ( searchValue: string ) {
		if ( !searchValue || disable ) return;
		if ( !isOpen ) setIsOpen(true);
		if ( searchValue.length < 3 ) {
			setMsg( "Введите минимум 3 символа" );
			return;
		}
		getHints( searchValue );
	};

	/** Вызывается при очистке поля поиска */
	const onClearHandler = () => {
		inputProps.addition.setValue("");
		if( isOpen ) setIsOpen(false);
		setOptions([]);
		setCurrentAddress(null);
	};

	/** Вызывается при клике на поле поиска */
	const onClickInputHandler = () => {
		if ( !inputProps.value ) return;
		if ( sendOnlyChange && !isOpen ) {
			setIsOpen( true );
		}
		else if ( !sendOnlyChange ) {
			onChange( inputProps.value );
		}
	};

	const getIsChecked = ( item: GarHintType ): boolean => {
		return !!currentId && currentId === item.id;
	};

	const renderOption = ( item: GarHintType, index: number ) => {
		const isChecked = getIsChecked(item);
		const itemClassses = cn( "rdccs__listitem", {
			"item-checked": isChecked
		});
		return (
			<li key={index} className={itemClassses} id={item.id} onClick={ () => onChangeHandler(item)}>
				<span title={item.text}>{item.text}</span>
			</li>
		);
	};

	const getStylesForList = () => {
		if ( !isOwner ) return {};
		const styles = { 
			left: 0, 
			top: 0, 
			zIndex: '999',
			width: 100
		};
		if ( !!wrapperRef.current ) {
			const offset = getRelativeOffsetBy( wrapperRef.current, "rdc" );
			styles.left = wrapperRef.current.offsetLeft;
			styles.top = (wrapperRef.current.offsetTop + wrapperRef.current.clientHeight) - offset.scrollTop;
			styles.width = wrapperRef.current.clientWidth;
		}
		return styles;
	};

	const renderList = () => (
		<div ref={modalRef} className="rdccs__body" style={getStylesForList()}>
			{ !!msg && <p className="rdcc__body__placeholder error">{msg}</p> }
			<ul className="rdccs__body__listitems">
				{ !!options?.length && options.map( renderOption )}
			</ul>
		</div>
	);

	const id =  "gar_" + name;
	return (
		<div ref={wrapperRef} className={cn( st.gar_field, className )} style={style}>
			<label htmlFor={id} className={st.gar_label}>{title}</label>

				<span className={cn(st.gar_icon, "icon-search")}></span>
				<input 
					id={id} 
					type="text" 
					disabled={disable} 
					className={cn(st.gar_input, (isOpen ? st.opened : '') )}
					onClick={onClickInputHandler}
					placeholder={placeHolder}
					{...inputProps} 
				/>
				{
					!!isWait && <div className={cn(st.gar_spinner,"spiner")} />
				}
				{ !!inputProps.value && <ClearBtn className={st.gar_clearbtn} onClick={onClearHandler}/> }
			
			{ isOpen && (isOwner ? createPortal( renderList(), document.body) : renderList()) }
		</div>
	);
};

export default GarField;
