
import classNames from 'classnames';
import st from './RdcCard.module.scss';
import { SearchInput } from 'src/Components';

interface IRdcCardHeaderProps extends React.AreaHTMLAttributes<HTMLDivElement> {
	title: string;
	titleCentered?: boolean;
	searchEnable?: boolean;
	defaultSearch?: string;
	searchPlaceholder?: string;
	debounceDelay?: number; 
	disabled?: boolean;
	onSearch?: ( text: string ) => void;
};

const RdcCardHeader: React.FC<IRdcCardHeaderProps> = ( { className, title, titleCentered = false, children, searchEnable = false, searchPlaceholder, defaultSearch, onSearch, ...otherProps } ) => (
	<div className={classNames(st.rdc_card__header, className)} {...otherProps}>
		<h2 className={classNames(st.rdc_card__header__title, "headline-default", { [st.rdc_card__header__title_centered]: titleCentered } )}>
			{title}
		</h2>
		{children}
		{ searchEnable && <SearchInput placeholder={searchPlaceholder} defaultSearch={defaultSearch} onSearch={onSearch} /> }
	</div>
);

export default RdcCardHeader;
