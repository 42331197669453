
import { FC, useEffect, useState } from "react";
import { Modal, Toolbar, Button } from "src/Components";
import { useModal } from "src/Hooks";
import { Rotate, ScanConfigType, ScanFileType, ScanSettingType, ScanVersionType } from "src/Types/Scan";
import API from "src/API";
import SettingsPanel from "./SettingsPanel";
import ScanPreview from "./ScanPreview";
import st from "./Scan.module.scss";

interface IScanModalProps {
	config: ScanConfigType;
	setBlobFunction: ( files: ScanFileType, count: number ) => void;
	onClose: () => void;
};

// Настройки по умолчанию
const initSettings: ScanSettingType = {
	Dpi: "300",
	Format: "2",
	Mode: "1",
	Quality: "50",
	UseADF: false
};

const ScanModal: FC<IScanModalProps> = ( props ) => {

	const { config, onClose, setBlobFunction } = props;

	const [ showError ] = useModal();
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ enable, setEnable ] = useState<boolean>(false);
	const [ version, setVersion ] = useState<ScanVersionType>();
	const [ setting, setSetting ] = useState<ScanSettingType>(initSettings);

	const [ scanList, setScanList ] = useState<string[]>([]);

	// Проверка доступности модуля и наличия версии в конфиге
	useEffect( () => {
		if ( !config.scanModuleVersion ) {
			showError('Не задана версия модуля сканирования в серверных настройках');
			return;
		}
		setIsLoading( true );
		API.Scan.checkModule( config )
			.then( r => {
				if ( !r ) return;
				API.Scan.getVersion( config )
					.then( res => {
						setVersion( res );
						if ( +res.version < +config.scanModuleVersion ) {
							showError('Обновите модуль сканирования пройдя по ссылке в окне описания');
							return;
						}
						setEnable( true );
					})
					.finally( () => setIsLoading(false) );
			})
			.finally( () => setIsLoading(false) );

		return () => {
			if ( enable ) clear();
		};
	// eslint-disable-next-line
	}, [] );

	/** Перед закрытием окна очищаем список сканов */
	const onCloseHandler = () => {
		clear();
		onClose();
	};

	/** Сканирование */
	const createScan = () => {
		setIsLoading(true)
		API.Scan.scan( config, setting )
			.then( result => setScanList( pr => [ ...pr, ...result ] ) )
			.finally( () => setIsLoading(false) );
	};

	/** Очистка листа сканирования или удаление скана... */
	const clear = ( id?: string ) => {
		setIsLoading( true );
		API.Scan.clear( config, scanList, id )
			.then( result => {
				if ( result ) {
					if ( !!id )
						setScanList( prev => {
							const list = [ ...prev ];
							const index = list.findIndex( i => i === id );
							if ( index !== -1 ) {
								list.splice( index, 1 );
							}
							return list;
						});
					else
						setScanList([]);
				}
			})
		.finally( () => setIsLoading( false ));
	};

	/** Поворот документа / страницы */
	const rotateHandler = ( to: Rotate, id?: string ) => {
		setIsLoading(true);
		API.Scan.rotate( config, to, scanList, id )
			.then( result => {
				if ( !id ) {
					if (Array.isArray(result.id)) {
						setScanList( result.id );
					}
				} 
				else {
					const newList = [ ...scanList ];
					const index = newList.findIndex( x => x === id );
					newList.splice(index, 1);
					newList.splice(index, 0, result.id );
					setScanList( newList );
				}
			})
			.finally( () => setIsLoading(false) );
	};

	/** Формируем документ */
	const createDoc = () => {
		setIsLoading( true );
		API.Scan.saveSettings( setting );
		API.Scan.createDocument( config, setting, scanList )
			.then( result => {
				if ( result.hasError ) {
					showError( "Ошибка формирования документа" );
					return;
				}
				setBlobFunction( result, scanList.length);
				clear();
			})
			.finally( () => setIsLoading( false ) );
	};

	// Рисуем тулбар
	const renderToolbar = () => (
		<Toolbar padding="0" className={st.toolbar} alignItems="center">
			{ !!scanList.length && <p>Страниц: {scanList.length}</p> }
			<Button outline onClick={onCloseHandler}>Отменить</Button>
			{
				enable && 
				<>
					<Button disable={!enable || !setting.SourceName} onClick={createScan}>Сканировать</Button>
					<Button variant="green" disable={!scanList.length} onClick={createDoc}>&#10004; Сформировать документ</Button>
				</>
			}
		</Toolbar>
	);

	// Рисуем тело модалки
	const renderBody = () => (
		<div className={st.body} >
			<SettingsPanel
				enable={enable}
				version={version?.versionAsse}
				config={config}
				setting={setting} 
				setSettings={setSetting}
			/>
			<div className={st.content}>
				<ScanPreview 
					isLoading={isLoading} 
					enable={enable} 
					config={config} 
					settings={setting} 
					listId={scanList}
					removePage={clear}
					rotate={rotateHandler}
				/>
				{ renderToolbar() }
			</div>
		</div>
	);

	return (
		<Modal 
			isOpen 
			onClose={onCloseHandler} 
			color="light" 
			title="Модуль сканирования"
			className={st.modal}
			renderBody={renderBody}
		/>
	);
};

export default ScanModal;
