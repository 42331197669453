import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type UseCashedDictionaryHandlerNew<T> = [ 
	/** Объект данных */
	T,
	/** Функция получение свойства по ключу */
	(key: string) => any,
	/** Функция устанавливает свойство по ключу */
	(key: string, value: any) => void,
	/** Установка всего объекта данных */
	Dispatch<SetStateAction<T>>,
	/** Сброс данных ( обнуление ) */
	() => void
];

/**
 * Хук позволяющий использовать объект с данными, который кэшируется в локальной памяти браузера
 * @param cashKey Ключ в локальной памяти
 * @param initialValue Объект инициализации по умолчанию
 */
const useCashedDictionary = <T>( cashKey: string, initValue?: T ): UseCashedDictionaryHandlerNew<T> => {

	const getObjectFromCash = () => {
		const strData = window.RDC.localStorage.getItem(cashKey);
		if ( !!strData ) {
			return JSON.parse(strData) as T;
		}
		else if ( !!initValue ) {
			return initValue;
		}
		return {} as T;
	};

	const [ data, setData ] = useState<T>(getObjectFromCash());

	useEffect( () => {
		window.addEventListener("localStorageUpdate", onUpdateStorage );
		return () => window.removeEventListener("localStorageUpdate", onUpdateStorage );
	// eslint-disable-next-line
	}, [ cashKey ] );

	const onUpdateStorage = ( event: CustomEventInit ) => {
		const { type, key, value } = event.detail;
		if ( key !== cashKey ) return;
		if ( type === "update" ) {
			if ( !value ) {
				setData( {} as T );
			}
			else {
				setData( JSON.parse(value) );
			} 
		}
	};

	useEffect( () => {
		setData(getObjectFromCash())
	// eslint-disable-next-line
	}, [cashKey]);

	/** Возвращает значение по ключу */
	const getValue = ( key: string ): any => {
		return (data as any)?.[key] ?? '';
	};

	const setValue = ( key: string, value: any) => {
		const newData = {
			...data,
			[key]: value
		};
		const strData = JSON.stringify(newData);
		window.RDC.localStorage.setItem( cashKey, strData );
	};

	const setDataHandler: Dispatch<SetStateAction<T>> = ( value ) => {
		let newData: T;
		if ( typeof value === "function" ) {
			/// TODO: Костыль! Надобно разобраться с типами и сделать по человечески
			newData = (value as any)(data);
		} 
		else {
			newData = value as T;
		}
		const strData = JSON.stringify(newData);
		window.RDC.localStorage.setItem( cashKey, strData );
		return newData;
	};

	/** Сбрасывает весь объект */
	const resetDictionary = () => window.RDC.localStorage.setItem(cashKey, '{}' );

	return [ data, getValue, setValue, setDataHandler, resetDictionary ];
};

export default useCashedDictionary;
