import { FC, HtmlHTMLAttributes, MouseEvent, TouchEvent } from "react";
import classNames from "classnames";
import { useResizePanel } from "src/Hooks";
import st from "./RdcSubMenuContainer.module.scss";

interface IRdcSubMenuContainerProps extends HtmlHTMLAttributes<HTMLDivElement> {
     /** Флаг указывающий на состояния панели ( свернута/развернута ) */
    expanded: boolean;
    /** Флаг запрещающий изменение размеров панели */
    disableResizing?: boolean;
    /** Минимальный размер панели ( в px ) */
    minWidth?: number;
    /** Максимальный размер панели ( в px ) */
    maxWidth?: number;
};

 /** Минимальный размер панели ( в px ) */
const RdcSubMenuContainer: FC<IRdcSubMenuContainerProps> = ( props ) => {

    const { id, className, expanded, children,
        disableResizing = false,
        minWidth = 300,
        maxWidth = 600,
        ...otherProps 
    } = props;

    const [ panelRef, onStartResize, dragging ] = useResizePanel( (!disableResizing && expanded), 20, minWidth, maxWidth );

    const onMouseDown = (e: MouseEvent) => {
		e.preventDefault();
		if ( !!expanded && !disableResizing ) {
			onStartResize(e.clientX);
		}
	};
	
	const onTouchStart = (e: TouchEvent) => {
		if ( !!expanded && !disableResizing ) {
			onStartResize(e.targetTouches[0].clientX);
		}
	};

    const wrapperClasses = classNames( st.rdc_submenu__wrapper, className, { [st.expanded]: expanded } );

    return (
        <div className={st.rdc_submenu} id={id}>
            <div className={wrapperClasses} ref={panelRef} {...otherProps}>
                <div 
					onMouseDown={onMouseDown} 
					onTouchStart={onTouchStart} 
					className={classNames( st.rdc_submenuResize, { 
                        [st.disabled]: disableResizing,
                        [st.active]: dragging
                    })} 
				/>
                { children }
            </div>
        </div>
    );
};

export default RdcSubMenuContainer;
