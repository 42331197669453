
export class StepQuestionsService {

	constructor( data, requestClass = "MfcUiModule", serviceList = [] ) {
		this.currentService = { ...data };
		this.serviceList = [...serviceList];
		this.requestClass = requestClass;

		//
		this.QuestionTypeEnum = {
			QuestionFile: 1,
			QuestionQuestion: 2,
			QuestionReject: 3,
			QuestionQuestionAuto: 4,
			QuestionEmpty: 5,
			QuestionService: 6,
			QuestionDynamicForm: 7
		};
		Object.freeze(this.QuestionTypeEnum);

		this.LinkTypeEnum = {
			And: 1,
			Or: 2,
			Empty: 3,
			Velocity: 4
		};
		Object.freeze(this.LinkTypeEnum);

		for ( let i = 0; i < this.serviceList.length; i++ ) {
			const service = this.serviceList[i];
			if (service.rejectInfos != null) {
				//преобразование причин отказа в обычный объект из key value массива, если они есть
				const rejectDict = service.rejectInfos;
				service.rejectInfos = { };
				for ( let index = 0; index < rejectDict.length; index++ ) {
					service.rejectInfos[rejectDict[index].key[0].toLowerCase() + rejectDict[index].key.slice(1)] = rejectDict[index].value;
				}
			}
		}

		if ( !!this.currentService.rejectInfos ) {
			//преобразование причин отказа в обычный объект из key value массива, если они есть
			const rejectDict = this.currentService.rejectInfos;
			this.currentService.rejectInfos = {};
			for ( let index = 0; index < rejectDict.length; index++ ) {
				this.currentService.rejectInfos[ rejectDict[index].key[0].toLowerCase() + rejectDict[index].key.slice(1)] = rejectDict[index].value;
			}
		}
	};

	onAnswerChanged = async (answer, questions, currentService) => new Promise( (resolve, reject) => {
		let dependsQuestions = [];
		const response = {};
		response.hasReject = false;
		response.rejectMessage = '';
		response.haveToGoNext = false;
		if ( currentService ) {
			this.currentService = this.serviceList.find( x => x.id === currentService );
		}
		// список связей типа вопрос вопрос - оно же targetQuestionsList в десктопе
		if (!Array.isArray(this.currentService.linkList)) {
			this.currentService.linkList = Object.entries(this.currentService.linkList).map( x => ({ key: x[0], value: x[1] }) );
		}
		const targetQuestionsList = this.currentService.linkList.filter( (item) => item.value.questionsLinkType === this.QuestionTypeEnum.QuestionQuestion );

		// переносим все ответы в список для упрощения проверки выполнения условий и сбрасываем все предупреждения
		for ( let i = 0; i < questions.length; i++ ) {
			const item = questions[i];
			if (!item.isMultiAnswers) {
				if (item.questionId === answer.questionId)
					item.singleAnswer = answer.answerId;
				item.selectedAnswers = [item.singleAnswer];
			} 
			else if (item.questionId === answer.questionId) {
				if (Array.isArray(answer.answerId)) {
					item.selectedAnswers = answer.answerId;
				} 
				else {
					if ( !item.selectedAnswers.some( x => x === answer.answerId ) ) {
						item.selectedAnswers.push(answer.answerId);
					} else {
						const index = item.selectedAnswers.indexOf(answer.answerId);
						item.selectedAnswers.splice(index, 1);
					}
				}
			}

			item.selectedAnswers = item.selectedAnswers.filter( (x, index, list) => x && x.length > 0 && list.indexOf(x) === index );
			item.warningMessage = '';
			item.hasWarning = false;
		}

		//здесь был большой кусок непонятной логики, m.vasilenko его удалил и заменил своим
		for (let j = 0; j < targetQuestionsList.length; j++) {
			const currentLink = targetQuestionsList[j];
			if (currentLink.value.questionId !== answer.questionId)
				continue;

			//это зависимый вопрос, судьба отображения которого решается
			const targetQuestion = questions.find( x => x.questionId === currentLink.value.linkObjectId );

			const allTargetQuestionLinks = targetQuestionsList.filter( x => x.value.linkObjectId === targetQuestion.questionId );
			let showTargetQuestion = false;
			if (allTargetQuestionLinks.length > 1) {
				//есть несколько условий отображения вопроса targetQuestionVm, поэтому отдельная обработка
				showTargetQuestion = this.getQuestionVisibility(this.currentService, allTargetQuestionLinks, questions);
			}
			else {
				// получаем модель родительского вопроса - это тот вопрос, который сейчас затриггерили
				const changedQuestion = questions.find( x => x.questionId === answer.questionId );
				//Всё просто, всего одна зависимость, это работает старая логика
				showTargetQuestion = currentLink.value.answerList.some( x => changedQuestion.selectedAnswers.some( k => k === x ) );
			}

			// проверяем есть ли выбранный ответ среди ответов, которые  НЕ приведут к скрытию вопроса
			targetQuestion.isVisible = showTargetQuestion;
			// если выбран ответ, который приводит к скрытию другого вопроса
			if (!showTargetQuestion) {
				targetQuestion.singleAnswer = '';
				targetQuestion.selectedAnswers = [];
				const newDependsQuestions = this.hideDependsQuestions(targetQuestion, targetQuestionsList, questions);
				dependsQuestions = dependsQuestions.concat(newDependsQuestions);
			}
			dependsQuestions.push(targetQuestion);
		}

		const processResponse = () => {
			response.list = dependsQuestions;
			const hasErrorMsgList = this.getRejectList(questions); // получаем сообщения об отказе

			// если условие отказа оказания услуги не выполнено
			if (hasErrorMsgList.length === 0) {
				for (let t = 0; t < questions.length; t++) {
					const model = questions[t];
					model.isReject = false;
				}
			} 
			else {
				for (let b = 0; b < hasErrorMsgList.length; b++) {
					const hasErrorMsg = hasErrorMsgList[b];
					if (!hasErrorMsg.value?.isWarning) {
						//получение недоступно; определяем, какие вопросы нужно скрыть
						const model = questions.find( (x) => x.questionId === hasErrorMsg.key );
						const k = questions.indexOf(model);
						//делаем видимым первый вопрос с отказом
						model.isReject = true;
						model.isVisible = true;
						//скрываем все вопросы, находящиеся ниже reject.Item1
						for (let i = k + 1; i < questions.length; i++) {
							questions[i].isReject = true;
							questions[i].isVisible = false;
						}
						response.hasReject = true;
						response.rejectMessage = hasErrorMsg.value.rejectText;
						break;
					} else {
						// проверяем выполнения условий на отображение предупреждений
						const warningList = this.getWarnings(questions);
						for (let p = 0; p < warningList.length; p++) {
							const item = warningList[p];
							if (!item.value?.isWarning)
								continue;
							const model1 = questions.find( (x) => x.questionId === item.key );
							model1.warningMessage = "Предупреждение: " + item.value.rejectText;
							model1.hasWarning = true;
						}
					}
				}
			}
			response.rejectList = questions;
			resolve(response);
		};

		//если есть связи нового образца, обработаем их, потому что они приоритетнее?
		if (this.currentService.linkList.some( x => x.value.linkType === this.LinkTypeEnum.Velocity && x.value.textCondition && x.value.textCondition.length > 0 )) {
			this.processCheckedAnswerVelocityLinkedObjects(questions, dependsQuestions, targetQuestionsList)
				.then( (responseVelocityLink) => {
					for (let i = 0; i < questions.length; i++) {
						const destItem = questions[i];
						const newItem = responseVelocityLink.questions.find( (x) => x.questionId === destItem.questionId );
						Object.assign(destItem, newItem);
					}
					for (let i = 0; i < dependsQuestions.length; i++) {
						const destItem = dependsQuestions[i];
						const newItem = responseVelocityLink.dependsQuestions.find( (x) => x.questionId === destItem.questionId );
						Object.assign(destItem, newItem);
					}
					for (let i = 0; i < targetQuestionsList.length; i++) {
						const destItem = targetQuestionsList[i];
						const newItemId = Object.keys(responseVelocityLink.targetQuestionsList).find( (x) => x === destItem.key );
						const newItem = responseVelocityLink.targetQuestionsList[newItemId];
						Object.assign(destItem, newItem);
					}
					processResponse();
				})
				.catch( () => reject() );
		} 
		else {
			processResponse();
		}
	});

	onAnswerChangedAuto = (questionsResponse, questions) => {
		questionsResponse.hasReject = false;
		questionsResponse.rejectMessage = "";

		// const selectedAnswer = questions.find( (x) => x.selectedAnswers.length > 0 || !!x.singleAnswer );
		// const answer = { questionId: selectedAnswer.questionId };

		// переносим все ответы в список для проверки выполнения условий
		for (let i = 0; i < questions.length; i++) {
			const item = questions[i];
			item.selectedAnswers = [item.singleAnswer];
			item.selectedAnswers = item.selectedAnswers.filter( (x, index, list) => x && x.length > 0 && list.indexOf(x) === index );
		}

		// получаем сообщения об отказе
		const hasErrorMsgList = this.getRejectList(questions);

		// если условие отказа оказания услуги не выполнено
		if ( !hasErrorMsgList.length ) {
			for ( let i = 0; i < questions.length; i++ ) {
				const model = questions[i];
				model.IsReject = false;
			}
		} 
		else {
			for ( let i = 0; i < hasErrorMsgList.length; i++ ) {
				const hasErrorMsg = hasErrorMsgList[i];
				if ( !hasErrorMsg.value?.isWarning ) {
					//получение недоступно; определяем, какие вопросы нужно скрыть
					const model = questions.find( (x) => x.questionId === hasErrorMsg.key );
					const k = questions.indexOf(model);
					//делаем видимым первый вопрос с отказом
					model.isReject = true;
					model.isVisible = true;
					//скрываем все вопросы, находящиеся ниже reject.Item1
					for ( let i = k + 1; i < questions.length; i++ ) {
						questions[i].isReject = true;
						questions[i].isVisible = false;
					}

					questionsResponse.hasReject = true;
					questionsResponse.rejectMessage = hasErrorMsg.value.rejectText;
					break;
				} 
				else {
					// проверяем выполнения условий на отображение предупреждений
					const warningList = this.getWarnings(questions);
					for ( let j = 0; j < warningList.length; j++ ) {
						const item = warningList[j];
						if ( !item.value?.isWarning )
							continue;
						const model1 = questions.find( (x) => x.questionId === item.key );
						model1.warningMessage = "Предупреждение: " + item.value.rejectText;
						model1.hasWarning = true;
					}
				}
			}
		}
		questionsResponse.rejectList = questions;
	};

	getRejectList = ( questions ) => {
		const rejectTextList = [];
		const rejectList = [];
		for (let i = 0; i < questions.length; i++) {
			const model = questions[i];
			for (let j = 0; j < this.currentService.linkList.length; j++) {
				const link = this.currentService.linkList[j];
				if ( link.value.questionsLinkType === this.QuestionTypeEnum.QuestionReject && link.value.questionId === model.questionId ) {
					const reject = {
						key: link.key,
						value: link.value
					};
					rejectList.push(reject);
				}
			}
		}

		const twoQuestionLinkList = [];
		for (let k = 0; k < this.currentService.linkList.length; k++) {
			const link = this.currentService.linkList[k].value;
			if (link.questionsLinkType === this.QuestionTypeEnum.QuestionQuestionAuto
				&& this.currentService.linkList.find( (x) => x.key === link.questionId ).value.questionsLinkType === this.QuestionTypeEnum.QuestionReject
				&& link.linkType === this.LinkTypeEnum.And)
				twoQuestionLinkList.push(link);
		}

		const tempReject = [];
		const rejectSuccess = [];
		const rejectFalse = [];
		const result = [];

		for (let z = 0; z < rejectList.length; z++) {
			const reject = rejectList[z];
			for (let l = 0; l < twoQuestionLinkList.length; l++) {
				const link = twoQuestionLinkList[l];
				if (reject.key === link.questionId
					&& this.currentService.linkList.find( (x) => x.key === link.linkObjectId ).value.questionsLinkType === this.QuestionTypeEnum.QuestionReject
					&& link.linkType === this.LinkTypeEnum.And) {
					tempReject.push(link.questionId);
					tempReject.push(link.linkObjectId);
					if (questions.some( (p) => p.isVisible && p.selectedAnswers.length > 0	&& p.questionId === reject.value.questionId	
						&& questions.some( (p) => p.isVisible && p.selectedAnswers.length > 0
									&& p.questionId === this.currentService.linkList.find( (x) => x.key === link.linkObjectId ).value.questionId
						))) 
					{
						const linkQuestion = questions.find( (p) => p.questionId === reject.value.questionId );
						const linkObject = questions.find( (p) => p.questionId === this.currentService.linkList.find( (x) => x.key === link.linkObjectId ).value.questionId );
						const rejectLinkObject = rejectList.find( (x) => x.key === link.linkObjectId );
						if (reject.value.answerList.some( (p) => linkQuestion.selectedAnswers.some( (k) => k === p ) )
							&& rejectLinkObject
							&& rejectLinkObject.value.answerList.some( (p) => linkObject.selectedAnswers.some( (k) => k === p ) )) {
							const rejectObject = this.currentService.rejectInfos[reject.value.linkObjectId[0].toLowerCase() + reject.value.linkObjectId.slice(1)];
							if (!rejectTextList.includes(rejectObject.rejectText))
								rejectTextList.push(rejectObject.rejectText);
							rejectSuccess.push(link);
							const newReject = {
								key: linkObject.questionId,
								value: rejectObject
							};
							result.push(newReject);
						}
						else {
							rejectFalse.push(link);
						}
					}
					else {
						rejectFalse.push(link);
					}
				}
			}

			for (let q = 0; q < rejectSuccess.length; q++) {
				const rSuccess = rejectSuccess[q];
				for (let t = 0; t < rejectFalse.length; t++) {
					const rFalse = rejectFalse[t];
					if (rFalse.linkObjectId === rSuccess.linkObjectId
						|| rFalse.linkObjectId === rSuccess.questionId
						|| rFalse.questionId === rSuccess.linkObjectId
						|| rFalse.questionId === rSuccess.questionId) {
						const index = rejectTextList.indexOf(this.currentService.rejectInfos[reject.value.linkObjectId[0].toLowerCase() + reject.value.linkObjectId.slice(1)].rejectText);
						rejectTextList.splice(index, 1);
					}
				}
			}

			const question = questions.find( (p) => p.questionId === reject.value.questionId );
			if (!tempReject.includes(reject.key)
				&& questions.some( (p) => p.questionId === reject.value.questionId )
				&& question.selectedAnswers.length > 0
				&& reject.value.answerList.some( (p) => question.selectedAnswers.some( (k) => k === p ) )) {
				const resultItem = {
					key: question.questionId,
					value: this.currentService.rejectInfos[reject.value.linkObjectId[0].toLowerCase() + reject.value.linkObjectId.slice(1)]
				};
				result.push(resultItem);
			}
		}
		return result;
	};

	/** ищет и возвращает предупреждения */
	getWarnings =  (questions) => {
		const rejectList = [];
		for (let i = 0; i < questions.length; i++) {
			const model = questions[i];
			if (!model.isVisible)
				continue;
			for (let j = 0; j < this.currentService.linkList.length; j++) {
				const link = this.currentService.linkList[j];
				if (link.value.questionsLinkType === this.QuestionTypeEnum.QuestionReject
					&& link.value.questionId === model.questionId) {
					const reject = {
						key: link.key,
						value: link.value
					};
					rejectList.push(reject);
				}
			}
		}

		const twoQuestionLinkList = [];
		for (let q = 0; q < this.currentService.linkList.length; q++) {
			const link = this.currentService.linkList[q].value;
			if (link.questionsLinkType === this.QuestionTypeEnum.QuestionQuestionAuto
				&& this.currentService.linkList.find( (x) => x.key === link.questionId ).value.questionsLinkType === this.QuestionTypeEnum.QuestionReject
				&& link.linkType === this.LinkTypeEnum.And)
				twoQuestionLinkList.push(link);
		}

		const tempReject = [];
		const rejectSuccess = [];
		const rejectFalse = [];
		const result = [];

		for (let t = 0; t < rejectList.length; t++) {
			const reject = rejectList[t];
			for (let z = 0; z < twoQuestionLinkList.length; z++) {
				const link = twoQuestionLinkList[z];
				if (reject.key === link.value.questionId
					&& this.currentService.linkList.find( (x) => x.key === link.linkObjectId ).value.questionsLinkType === this.QuestionTypeEnum.QuestionReject
					&& link.value.linkType === this.LinkTypeEnum.And) {
					tempReject.push(link.value.questionId);
					tempReject.push(link.value.linkObjectId);
					if (questions.some( (p) => p.isVisible	&& p.selectedAnswers.length > 0 && p.questionId === reject.value.questionId
							&& questions.some( (p) => p.isVisible
									&& p.selectedAnswers.length > 0
									&& p.questionId === this.currentService.linkList.find( (x) => x.key === link.value.linkObjectId ).value.questionId
							)
					)) {
						const linkQuestion = questions.find( (p) => p.questionId === reject.value.questionId );
						const linkObject = questions.find( (p) => p.QuestionId === this.currentService.linkList.find( (x) => x.key === link.value.linkObjectId ).value.questionId );
						const rejectQuestionObject = rejectList.find( (x) => x.key === linkObject.questionId );
						if (reject.value.answerList.some( (p) => linkQuestion.selectedAnswers.some( (k) => k === p ) )
							&& rejectQuestionObject
							&& rejectQuestionObject.value.answerList.some( (p) => linkObject.selectedAnswers.some( (k) => k === p ) )) {
							rejectSuccess.push(link);
							if (!result.some( (x) => x.key === linkObject.questionId)) {
								const newReject = {
									key: linkObject.questionId,
									value: this.currentService.rejectInfos[reject.value.linkObjectId[0].toLowerCase() + reject.value.linkObjectId.slice(1)]
								};
								result.push(newReject);
							}
						}
						else {
							rejectFalse.push(link);
						}
					}
					else {
						rejectFalse.push(link);
					}
				}
			}

			for (let s = 0; s < rejectSuccess.length; s++) {
				const rSuccess = rejectSuccess[s];
				for (let k = 0; k < rejectFalse.length; k++) {
					const rFalse = rejectFalse[k];
					if (rFalse.linkObjectId === rSuccess.linkObjectId
						|| rFalse.linkObjectId === rSuccess.questionId
						|| rFalse.questionId === rSuccess.linkObjectId
						|| rFalse.questionId === rSuccess.questionId) {
						const item = result.find( (x) => x.key === rSuccess.questionId )
						if (item) {
							result.splice(result.indexOf(item), 1);
						}
					}
				}
			}

			const question = questions.find( (p) => p.questionId === reject.value.questionId );
			if (!tempReject.includes(reject.key)
				&& questions.some( (p) => p.isVisible
						&& p.questionId === reject.value.questionId
						&& question.selectedAnswers.length > 0
						&& reject.value.answerList.some( (p) => question.selectedAnswers.some( (k) => k === p ) )
				)) {
				if (!result.some( (x) => x.key === question.questionId )) {
					const lowerLinkObjectId = reject.value.linkObjectId[0].toLowerCase() + reject.value.linkObjectId.slice(1);
					const newReject = {
						key: question.questionId,
						value: this.currentService.rejectInfos[lowerLinkObjectId]
					};
					result.push(newReject);
				}
			}
		}
		return result;
	};

	getQuestionVisibility =  (serviceInfo, allTargetQuestionLinks, questions) => {
		//Все ключи связей, относящихся к вопросу
		const allTargetQuestionLinkKeys = allTargetQuestionLinks.map( (x) => x.key );
		let showTargetQuestion = false, conditionOrExist = false, conditionAndExist = false, conditionOr = false, conditionAnd = false;

		for (let i = 0; i < serviceInfo.linkList.length; i++) {
			const link = serviceInfo.linkList[i];
			//поиск условия. В данный момент, реализация поддерживает либо все И, либо все ИЛИ
			if (allTargetQuestionLinkKeys.includes(link.value.questionId) || allTargetQuestionLinkKeys.includes(link.value.linkObjectId)) {
				if (link.value.linkType === this.LinkTypeEnum.And)
					conditionAndExist = true;
				if (link.value.linkType === this.LinkTypeEnum.Or)
					conditionOrExist = true;
			}
		}
		//Обработка условий И
		if (conditionAndExist) {
			const conditionAndList = [];
			for (let j = 0; j < allTargetQuestionLinks.length; j++) {
				const linkObject = allTargetQuestionLinks[j];
				const outQuestionAnswer = questions.find( (x) => x.questionId === linkObject.value.questionId );
				if (outQuestionAnswer && outQuestionAnswer.selectedAnswers.length > 0) {
					//для мультиответных вопросов достаточно, чтобы только требуемый ответ был выбран, остальные не важны
					const intersect = outQuestionAnswer.selectedAnswers.filter( (x) => linkObject.value.answerList.includes(x) );
					const eq = intersect.length === linkObject.value.answerList.length;
					conditionAndList.push(eq);
				}
				else
					conditionAndList.push(false);
			}
			conditionAnd = conditionAndList.every( (x) => x );
		}
		//Обработка условий ИЛИ
		if (conditionOrExist) {
			for (let p = 0; p < allTargetQuestionLinks.length; p++) {
				const linkObject = allTargetQuestionLinks[p];
				const outQuestionAnswer = questions.find( (x) => x.questionId === linkObject.value.questionId );
				if (outQuestionAnswer && outQuestionAnswer.selectedAnswers.length > 0) {
					//для мультиответных вопросов достаточно, чтобы только требуемый ответ был выбран, остальные не важны
					const intersect = outQuestionAnswer.selectedAnswers.filter( (x) => linkObject.value.answerList.includes(x) );
					const eq = intersect.length === linkObject.value.answerList.length;
					if (eq)
						conditionOr = true;
				}
			}
		}

		if (conditionOrExist)
			showTargetQuestion = conditionOr;
		if (conditionAndExist)
			showTargetQuestion = conditionAnd;
		if (conditionOrExist && conditionAndExist)
			showTargetQuestion = conditionOr && conditionAnd; //Этот вариант не будет корректно работать, но он и не требуется
		return showTargetQuestion;
	};

	hideDependsQuestions =  (model, questionQuestionList, questions) => {
		const dependsQuestionsIds = [];
		let dependsQuestions = [];

		for (let i = 0; i < questionQuestionList.length; i++) {
			const item = questionQuestionList[i];
			if (item.value.questionId !== model.questionId)
				continue;
			const modelDependsQuestion = questions.find( (p) => p.questionId === item.value.linkObjectId );

			if (modelDependsQuestion) {
				// Может быть ситуация, когда на текущий вопрос ссылаются несколько вопросов.
				// Чтобы однозначно определить, нужно ли в данном случае скрыть текущий вопрос, необходимо найти все вопросы, которые ссылаются на него,
				// и если хотя бы один вопрос является видимым, то и текущий вопрос должен отображаться.
				const someRelatedQuestionIds = questionQuestionList
					.filter( (p) => p.value.linkObjectId === modelDependsQuestion.questionId)
					.map( (link) => link.value.questionId );

				const anyVisibleQuestion = questions
					.filter( (p) => someRelatedQuestionIds.includes(p.questionId) )
					.find( (p) => !!p.isVisible );

				if ( !anyVisibleQuestion ) {
					modelDependsQuestion.isVisible = false;
					modelDependsQuestion.singleAnswer = '';
					modelDependsQuestion.selectedAnswers.splice(0, modelDependsQuestion.selectedAnswers.length);
					dependsQuestions.push(modelDependsQuestion);
				}
			}

			dependsQuestionsIds.push(item.value.linkObjectId);

		}
		if (dependsQuestionsIds.length > 0) {
			const newQuestions = this.hideDependsQuestionFromList(dependsQuestionsIds, questionQuestionList, questions);
			dependsQuestions = dependsQuestions.concat(newQuestions);
		}
		return dependsQuestions;
	};

	hideDependsQuestionFromList =  (dependQuestions, questionQuestionList, questions) => {
		const dependsQuestions = [];
		for (let ii = 0; ii < 100; ii++) {
			const temp = [];
			for (let j = 0; j < dependQuestions.length; j++) {
				const recId = dependQuestions[j];
				for (let k = 0; k < questionQuestionList.length; k++) {
					const item = questionQuestionList[k];
					if (item.value.questionId !== recId)
						continue;

					const modelDependsQuestion = questions.find( (p) => p.questionId === item.value.linkObjectId );
					if (modelDependsQuestion) {
						// Может быть ситуация, когда на текущий вопрос ссылаются несколько вопросов.
						// Чтобы однозначно определить, нужно ли в данном случае скрыть текущий вопрос, необходимо найти все вопросы, которые ссылаются на него,
						// и если хотя бы один вопрос является видимым, то и текущий вопрос должен отображаться.
						const someRelatedQuestionIds = questionQuestionList
							.filter( (p) => p.value.linkObjectId === modelDependsQuestion.questionId)
							.map( (link) => link.value.questionId );

						const anyVisibleQuestion = questions
							.filter( (p) => someRelatedQuestionIds.includes(p.questionId) )
							.find( (p) => !!p.isVisible );

						if (!anyVisibleQuestion) {
							modelDependsQuestion.isVisible = false;
							modelDependsQuestion.singleAnswer = '';
							modelDependsQuestion.selectedAnswers.splice(0, modelDependsQuestion.selectedAnswers.length);
							dependsQuestions.push(modelDependsQuestion);
						}
					}

					temp.push(item.value.linkObjectId);
				}
			}
			if (temp.length > 0) {
				dependQuestions = temp;
				continue;
			}
			break;
		}

		return dependsQuestions;
	};

	processCheckedAnswerVelocityLinkedObjects = async (questions, dependsQuestions, targetQuestionsList) => new Promise( (resolve, reject) => {

		const model = {
			ServiceRecId: this.currentService.recId,
			Questions: JSON.stringify(questions),
			DependsQuestions: JSON.stringify(dependsQuestions),
			TargetQuestionsList: JSON.stringify(targetQuestionsList)
		}
		const url = "/" + this.requestClass + "/Wizzard/Step3/ProcessChecked";

		fetch( url, { method: "POST", headers: { credentials: "include" }, body: model })
			.then( (response) => {
				if ( response.status === 403 ) {
					//Перенаправляем на страницу аутентификации.
					let returnUrl = window.location.pathname + window.location.hash;
					returnUrl = encodeURIComponent(returnUrl);
					const redirectUrl = '/new#/login?returnUrl=' + returnUrl;
					window.location.href = redirectUrl;
				}
				else if (response.hasError) {
					reject(response.errorMessage);
				} 
				else {
					resolve(response);
				}
			})
			.catch( jqXHR => reject( "Не удалось получить с сервера данные. Подробности: " + jqXHR.message) );
	});

};

export default StepQuestionsService;
