import { Response, oListItemType } from "src/Types/ReDoc";
import { TypeDomainParams } from "src/Types";
import { EQueueConfigType } from "src/Types/ReDoc/EQueue";
import { DamaskQueueStateType, DamaskStatusType, UserPultType } from "src/Types/ReDoc/Damask";

type StatusType = Promise<Response<DamaskStatusType>>;

export default class EQueueAPI {

	static _urls = {
		loadConfig: "/Nvx.ReDoc.DamaskWebApiModule/PultController/LoadConfig",
		saveConfig: "/Nvx.ReDoc.DamaskWebApiModule/PultController/SaveConfig",
		getSavedUserPult: "/Nvx.ReDoc.DamaskWebApiModule/PultController/GetSavedUserPult",
		savePult: "Nvx.ReDoc.DamaskWebApiModule/PultController/SavePult",
		getStations: "/Nvx.ReDoc.DamaskWebApiModule/PultController/GetStations",
		getOfficeStations: "/Nvx.ReDoc.DamaskWebApiModule/PultController/GetOfficeStations?officeId=",
		getPauseReasons: "/Nvx.ReDoc.DamaskWebApiModule/PultController/GetPauseReasons",
		// Авторизация
		login: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Login",
		start: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Start",
		//Получает идентификатор пульта текущего пользователя
		pultId: "/Nvx.ReDoc.DamaskWebApiModule/AdminController/LoadPultId",
		//Получает состояние пульта
		pultStatus: "/Nvx.ReDoc.DamaskWebApiModule/PultController/PultStatus",
		//Завершает сеанс взаимодействия с сервером электронной очереди
		finish: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Finish",
		//Осуществляет попытку вызова следующего человека из очереди
		call: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Call",
		//Производит повторный вызов текущего клиента
		recall: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Recall",
		// CallById - Осуществляет попытку вызова следующего человека из очереди по номеру билета
		callById: "/Nvx.ReDoc.DamaskWebApiModule/PultController/CallById",
		//Начинает обслуживание текущего клиента
		beginServing: "/Nvx.ReDoc.DamaskWebApiModule/PultController/BeginServing",
		//Завершает обслуживание текущего клиента
		endServing: "/Nvx.ReDoc.DamaskWebApiModule/PultController/EndServing",
		//Отменяет обслуживание текущего клиента
		drop: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Drop",
		// Откладывает обслуживание текущего клиента
		delay: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Delay",
		beginPause: "/Nvx.ReDoc.DamaskWebApiModule/PultController/BeginPause",
		//Возобновление работы после паузы
		endPause: "/Nvx.ReDoc.DamaskWebApiModule/PultController/EndPause",
		// Перенаправляет посетителя на другое окно
		redirect: "/Nvx.ReDoc.DamaskWebApiModule/PultController/Redirect",
		//Состояние очереди
		qeueState: "/Nvx.ReDoc.DamaskWebApiModule/PultController/QueueState"
	};

	/** Получение конфигурации ЭО  */
	static getConfig = async (): Promise<Response<EQueueConfigType>> => {
		try {
			const url = window.host + this._urls.loadConfig;
			const request = await fetch( url, { credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при получении настроек ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при получении настроек ЭО.', e);
		}
	};

	/** Сохранение конфигурации ЭО  */
	static setConfig: ( params: TypeDomainParams ) => Promise<Response<null>> = async ( params ) => {
		const frmData = new FormData();
		frmData.append( "login", params.login );
		frmData.append( "password", params.password );
		try {
			const url = window.host + this._urls.saveConfig;
			const request = await fetch( url, { method: "POST", credentials: "include", body: frmData });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при сохранении настроек ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при сохранении настроек ЭО.', e);
		}
	};

	/** Возвращает сохраненный пульт ( ид окна и его наименование ) */
	static getUserPult = async (): Promise<UserPultType> => {
		try {
			const url = window.host + this._urls.getSavedUserPult;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				const data = await request.json();
				return {
					pultId: data.id,
					pultName: data.text
				};
			}
			throw new Error('Ошибка при получении сохраненного пульта ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при получении сохраненного пульта ЭО.', e);
		}
	};

	/** Сохраняет пульт */
	static setUserPult = async ( pult: UserPultType ): Promise<Response<null>> => {
		const frmData = new FormData();
		frmData.append( "pultId", pult.pultId );
		frmData.append( "pultName", pult.pultName );
		try {
			const url = window.host + this._urls.savePult;
			const request = await fetch( url, { method: "POST", credentials: "include", body: frmData });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при сохранении пульта ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при сохранении пульта ЭО.', e);
		}
	};

	/** Авторизация к ЭО  */
	static login = async (): Promise<Response<number>> => {
		try {
			const url = window.host + this._urls.login;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка входа электронной очереди.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка входа электронной очереди.', e);
		}
	};

	/** Запрос за списком рабочих мест  */
	static getOfficeStations = async ( officeId: string): Promise<Response<oListItemType[]>> => {
		try {
			const url = window.host + this._urls.getOfficeStations + officeId;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения списка рабочих мест ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения списка рабочих мест ЭО.', e);
		}
	};

	/** Запрос за списком рабочих мест  */
	static getStations = async (): Promise<Response<oListItemType[]>> => {
		try {
			const url = window.host + this._urls.getStations;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения списка рабочих мест ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения списка рабочих мест ЭО.', e);
		}
	};

	/** Старт ЭО  */
	static start = async ( pultId: string ): StatusType => {
		try {
			const frmData = new FormData();
			frmData.append( "pultId", pultId );
			const url = window.host + this._urls.start;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка старта ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка старта ЭО.', e);
		}
	};

	/** Получение состояния очереди ЭО  */
	static getQueueState = async (): Promise<Response<DamaskQueueStateType>> => {
		try {
			const url = window.host + this._urls.qeueState;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения состояния очереди ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения состояния очереди ЭО.', e);
		}
	};

	/** Запрос состояния ЭО  */
	static getStatus = async (): StatusType => {
		try {
			const url = window.host + this._urls.pultStatus;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения статуса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения статуса ЭО.', e);
		}
	};

	/** Старт перерыва ЭО  */
	static beginPause = async ( reason: string,  idQ?: string ): StatusType => {
		const frmData = new FormData();
		frmData.append( "reason", reason );
		if ( !!idQ ) {
			frmData.append( "idQ", idQ );
		}
		try {
			const url = window.host + this._urls.beginPause;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запросе на перерыв статуса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запросе на перерыв ЭО.', e);
		}
	};

	/** Остановка перерыва ЭО  */
	static endPause = async (): StatusType => {
		try {
			const url = window.host + this._urls.endPause;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка запроса на окончание перерыва ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка запроса на окончание перерыва ЭО.', e);
		}
	};

	/** Получает список разрешённых причин перерыва с сервера ЭО  */
	static getPauseReasons = async (): Promise<Response<oListItemType[]>> => {
		try {
			const url = window.host + this._urls.getPauseReasons;
			const request = await fetch( url, { method: "GET", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка получения списка разрешённых причин перерыва ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка получения списка разрешённых причин перерыва ЭО.', e);
		}
	};

	/** Вызов ЭО  */
	static call = async (): StatusType => {
		try {
			const url = window.host + this._urls.call;
			const request = await fetch( url, { method: "POST", credentials: "include" });
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка вызова клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка вызова клиента ЭО.', e);
		}
	};

	/** Осуществляет попытку вызова следующего человека из очереди по номеру билета */
	static callById = async ( idQ: string ): StatusType => {
		const frmData = new FormData();
		frmData.append( "idQ", idQ);
		try {
			const url = window.host + this._urls.callById;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				body: frmData
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка вызова клиента по ИД ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка вызова клиента ИД ЭО.', e);
		}
	};

	/** Производит повторный вызов текущего клиента */
	static recall = async (): StatusType => {
		try {
			const url = window.host + this._urls.recall;
			const request = await fetch( url, { method: "POST", credentials: "include" } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка повторного вызова клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка повторного вызова клиента ЭО.', e);
		}
	};

	/** Начало обслуживания ЭО  */
	static beginServing = async (): StatusType => {
		try {
			const url = window.host + this._urls.beginServing;
			const request = await fetch( url, { method: "POST", credentials: "include" } );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка начала обслуживания клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка начала обслуживания клиента ЭО.', e);
		}
	};

	/** Завершает обслуживание текущего клиента  */
	static endServing = async ( idQ: string ): StatusType => {
		const frmData = new FormData();
		frmData.append( "idQ", idQ );
		try {
			const url = window.host + this._urls.endServing;
			const request = await fetch( url, { 
				method: "POST",
				credentials: "include",
				body: frmData
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка завершения обслуживания клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка завершения обслуживания клиента ЭО.', e);
		}
	};

	/** Отменяет обслуживание текущего клиента  */
	static drop = async ( idQ: string ): StatusType => {
		const frmData = new FormData();
		frmData.append( "idQ", idQ );
		try {
			const url = window.host + this._urls.drop;
			const request = await fetch( url, { 
				method: "POST",
				credentials: "include",
				body: frmData
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при отмене обслуживания клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при отмене обслуживания клиента ЭО.', e);
		}
	};

	/** Откладывает обслуживание текущего клиента  */
	static delay = async ( time: string ): StatusType => {
		try {
			const url = window.host + this._urls.delay;
			const request = await fetch( url, { 
				method: "POST",
				credentials: "include",
				body: JSON.stringify( { time } )
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при откладывании обслуживания клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при откладывании обслуживания клиента ЭО.', e);
		}
	};

	/** Перенаправляет посетителя на другое окно  */
	static redirect = async ( windowNumber: number, priority: "Low" | "Default" | "High", callback: boolean ): StatusType => {
		const frmData = new FormData();
		frmData.append( "window", windowNumber.toString() );
		frmData.append( "priority", priority );
		frmData.append( "callback", callback.toString() );
		try {
			const url = window.host + this._urls.redirect;
			const request = await fetch( url, { 
				method: "POST",
				credentials: "include",
				body: frmData
			} );
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка при перенаправлении клиента ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка при перенаправлении клиента ЭО.', e);
		}
	};

	/** Завершение работы с сервером ЭО  */
	static finish = async ( idQ?: string ): StatusType => {
		let frmData: FormData | undefined;
		if ( !!idQ ) {
			frmData = new FormData();
			frmData.append( "idQ", idQ );
		}
		try {
			const url = window.host + this._urls.finish;
			const request = await fetch( url, { 
				method: "POST", 
				credentials: "include",
				body: frmData
			});
			if ( request.status === 200 ) {
				return await request.json();
			}
			throw new Error('Ошибка завершении сеанса ЭО.' );
		}
		catch( e: any ) {
			console.warn(e);
			throw new Error('Ошибка завершении сеанса ЭО.', e);
		}
	};

};