import classNames from "classnames";
import { ReDocTrober } from "src/Components";
import './content-container.scss';

interface IContentContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode
	fluid?: boolean;
	isLoading: boolean;
	isRenderContentWhenLoading?: boolean;
	renderSubMenu?: React.FC;
	renderContent?: React.FC;
};

const ContentContainer: React.FC<IContentContainerProps> = ({ isLoading, isRenderContentWhenLoading = false, fluid = true, children, renderSubMenu, renderContent, className, ...otherProps}) => {

	const classes = classNames('rdc_content-container', { 'rdc-fluid': fluid });

	if ( isLoading && !isRenderContentWhenLoading ) {
		return (
			<div className={classes}>
				<ReDocTrober size='xl' className='m-auto'/>
			</div>
		);
	}

	return (
		<div className={classNames(classes, className)}>
			{ isLoading && <ReDocTrober size='xl' className='m-auto'/> }
			{ !!renderSubMenu && renderSubMenu( {...otherProps}) }
			{ !!children && children }
			{ !!renderContent && renderContent(otherProps) }
		</div>
	);
};

export default ContentContainer;