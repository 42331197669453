import { OffsetsType } from "src/Types";

/**
 * Возвращает смещение элемента относительно карточки с таблицей
 * @param {HTMLElement} element Элемент ввода данных
 * @param {string} parentElement Родительский элемент
 * @returns Координаты смещения относительно карточки таблицы
 */
export const getOffsetElement = ( element: HTMLElement, parentClass?: string ): OffsetsType => {
	let node: any = element;
	// Получаем размеры элемента
	const rect = node.getBoundingClientRect();
	
	const offset = {
		top: 0,
		left: 0,
		scroll: 0,
		scrollTop : 0,
		height: rect.height,
		width: rect.width
	};

	const isRootElement = ( el: HTMLElement | Element ) => {
		if ( !el ) return false;
		else if ( !!parentClass )
			return el.classList.contains(parentClass);
		else 
			return el.tagName === "BODY";
	};

	// Зацикливаемся если элемент не нулевой и не содержит класс card
	while ( !!node && !isRootElement(node)) {
		// прибавляем смещение элемента
		offset.top += node.offsetTop;
		offset.scroll += node.scrollLeft;
		offset.scrollTop += node.scrollTop;
		offset.left += node.offsetLeft;
		// переключаемся на родительский элемент
		node = node.offsetParent;
	}
	return offset;
};
