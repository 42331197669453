import axios from "axios";
import downloadjs from 'downloadjs';
import { PresetListType, RegistryRequestBodyType, PresetSectionType, PresetType, RegistryDataType } from "src/Types/Preset";
import { ResponseType, ListJsonResponseType, ResponseCountType } from "src/Types";
import BaseApi from "./BaseApi";

/**
 * API для работы с пресетами
 */
export default class APIPreset extends BaseApi {

	/**
	 * Возвращает список доступных реестров
	 * @param section Наименование секции
	 */
	static getPresetList = async ( section: PresetSectionType ): Promise<ResponseType<PresetListType>> => {
		const url = window.host + '/registry?section=' + section;
		const request = await axios.get( url , { withCredentials: true });
		if ( this.checkUnauthorized( request ) ) {
			throw new Error("Ошибка запроса на список реестра. Авторизационные данные устарели" );
		}
		else {
			return {
				hasError: false,
				errorMessage: '',
				result: request.data
			}
		}
	};

	/**
	 * Возвращает описание реестра по идентификатору
	 * @param id RecId пресета
	 */
	static getPreset = async ( id: string): Promise<ResponseType<PresetType>> => {
		const url = window.host + '/registry/' + id;
		const request = await axios.get( url , { withCredentials: true });
		if ( this.checkUnauthorized( request ) ) {
			throw new Error("Ошибка запроса на получение реестра. Авторизационные данные устарели" );
		}
		else {
			return {
				hasError: false,
				errorMessage: '',
				result: request.data
			}
		}
	};

	/**
	 * Запрос на сохранения/обновления пресета
	 * @param nameOrIdPreset ReaId или наименование ( в случае обновления ) пресета 
	 * @param preset Объект пресета
	 * @param isReplace Флаг указывающий на обновление пресета
	 */
	static savePreset = async ( nameOrIdPreset: string, preset: PresetType, isReplace: boolean ): Promise<ResponseType<null>> => {
		const url = window.host + '/registry' + (isReplace ? ( "/" + nameOrIdPreset ) : '');
		const method = isReplace ? axios.put : axios.post;
		const request = await method( url, preset, { withCredentials: true } );
		if ( this.checkUnauthorized( request ) ) {
			throw new Error("Ошибка запроса сохранения реестра. Авторизационные данные устарели" );
		}
		else {
			return {
				hasError: request.data.hasError,
				errorMessage: request.data.errorMessage,
				result: request.data.result
			}
		}
	};

	/**
	 * Запрос на удаление пресета
	 * @param id RecId пресета
	 */
	static removePreset = async ( id: string ): Promise<ResponseType<null>> => {
		const url = window.host + '/registry/' + id;
		const request = await axios.delete( url, { withCredentials: true } );
		if ( this.checkUnauthorized( request ) ) {
			throw new Error("Ошибка запроса на удаление реестра. Авторизационные данные устарели" );
		}
		else {
			return {
				hasError: request.data.hasError,
				errorMessage: request.data.errorMessage,
				result: request.data.result
			}
		}
	};

	/**  */
	static searchList = async ( section: string, body: RegistryRequestBodyType ): Promise<ListJsonResponseType<RegistryDataType>> => {
		const url = window.host + "/registry/" + section + "/data";
		try {
			const request = await axios.post( url , body, { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка при поиске по реестру. Авторизационные данные устарели" );
			}
			else {
				if ( request.data.hasError ) {
					return {
						hasError: !!request.data.hasError,
						errorMessage: request.data.errorMessage || "",
						list: [],
						count: 0,
						page: 0
					}
				}
				return {
					hasError: !!request.data.hasError,
					errorMessage: request.data.errorMessage || "",
					count: request.data.list.length,
					list: request.data.list,
					page: request.data.page
				}
			}
		}
		catch (error: any) {
			throw new Error("Ошибка при поиске по реестру." );
		}
	};

	/**  */
	static count = async ( section: string, body: RegistryRequestBodyType ): Promise<ResponseCountType> => {
		const url = window.host + "/registry/" + section + "/count";
		try {
			const request = await axios.post( url , body, { withCredentials: true });
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка запроса по количеству записей реестра. Авторизационные данные устарели" );
			}
			else {
				return {
					hasError: request.data.hasError,
					errorMessage: request.data.errorMessage,
					result: request.data.result
				}
			}
		}
		catch (error: any) {
			throw new Error("Ошибка запроса за количеством записей." );
		}
	};

	/**  */
	static export = async ( preset: PresetType,  body: RegistryRequestBodyType, recIDs: Array<string> ): Promise<ResponseType<null>> => {
		const url = window.host + "/registry/" + preset.section + "/export";
		const result: ResponseType<null> = {
			hasError: true,
   			errorMessage: "Ошибка экспорта.",
			result: null
		};
		const requestBody = {
			...body,
			recIDs
		};
		try {
			const request = await fetch( url, {
				method: "POST",
				credentials: "include",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(requestBody)
			});
			if ( this.checkUnauthorized( request ) ) {
				throw new Error("Ошибка запроса экспорта реестра. Авторизационные данные устарели" );
			}
			if ( request.status === 200 ) {

				const answerHeader = request.headers.get("Content-Type");
				if ( answerHeader?.includes("application/json") ) {
					const res = await request.json();
					result.hasError = res.hasError;
					result.errorMessage = res.errorMessage;
					return result;
				}	

				let filename = "Дела";
				const blob = await request.blob();
				const disposition = request.headers.get("Content-Disposition");
				
				if ( disposition && disposition.indexOf('attachment') !== -1 ) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if ( matches != null && matches[1] ) {
						filename = matches[1].replace(/['"]/g, '');
						//для кирилицы и спец. символов
						filename = decodeURIComponent(escape(filename));
					}
				}
				if ( !!blob ) {
					downloadjs(blob, filename);
					result.hasError = false;
				}
			}
			return result;
		}
		catch( error: any ) {
			result.errorMessage = error.message || "Ошибка запроса экспорта реестра.";
			result.result = error;
			return result;
		}
	};

};
