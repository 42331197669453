
export const changeTheme = () => {
	window.RDC.localStorage.setItem("theme", isCustomTheme() ? "main" : "belg" );
	window.RDC.localStorage.setItem("lang", !isCustomTheme() ? "rus" : "belg" );
	window.location.reload();
};

export const getCurrentTheme = () => window.RDC.localStorage.getItem("theme") || "main";

export const isCustomTheme = () => getCurrentTheme() !== "main";


export const getUrlVars = (url, param = '?') => {
	if (!url) url = window.location.href;
	if (param !== '#') param = '?';

	///<summary>Расширение jQuery, позволяющее получать параметры строки запроса.</summary>
	let vars = [], hash;
	var hashes = url.slice(url.indexOf(param) + 1).split('&');
	for (var i = 0; i < hashes.length; i++) {
		//hash = hashes[i].split('='); // для параметров в которых есть = такой вариант не подходит
		const indexOfEq = hashes[i].indexOf('=');
		hash = [hashes[i].substring(0, indexOfEq), hashes[i].substring(indexOfEq + 1)];
		const lower = hash[0].toLowerCase();
		if (!vars[lower] && !vars[hash[0]]) {
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
			if (lower !== hash[0]) {
				vars.push(lower);
				vars[lower] = hash[1];
			}
		}
	}
	return vars;
};

//Возвращает значение конкретного параметра строки запроса.
export const getUrlVar = (name) => {
	return getUrlVars()[name.toLowerCase()];
};

/**
 * Возвращает смещение элемента относительно карточки с таблицей
 * @param {*} element Элемент ввода данных
 * @param {*} parentElement Родительский элемент
 * @returns Координаты смещения относительно карточки таблицы
 */
export const getOffsetBy = ( element, parentClass ) => {
	// Получаем размеры элемента
	const rect = element.getBoundingClientRect();
	let offset = {
		top: 0,
		left: 0,
		scroll: 0,
		scrollTop : 0,
		height: rect.height,
		width: rect.width
	};
	let el = element;
	// Зацикливаемся если элемент не нулевой и не содержит класс card
	while ( !!el && !el.classList.contains(parentClass)) {
		// прибавляем смещение элемента
		offset.top += parseInt(el.offsetTop);
		offset.left = parseInt(el.offsetLeft);
		// переключаемся на родительский элемент
		el = el.offsetParent;
	}
	el = element;
	// Зацикливаемся если элемент не нулевой и не содержит класс card
	while ( !!el && !el.classList.contains(parentClass)) {
		offset.scroll += parseInt(el.scrollLeft);
		offset.scrollTop += parseInt(el.scrollTop);
		// переключаемся на родительский элемент
		el = el.parentElement;
	}

	return offset;
}

/**
 * Возвращает смещение элемента относительно карточки с таблицей
 * @param {*} element Элемент ввода данных
 * @param {*} parentElement Родительский элемент
 * @returns Координаты смещения относительно карточки таблицы
 */
export const getFullOffsetBy = ( element, parentClass ) => {
	// Получаем размеры элемента
	const rect = element.getBoundingClientRect();
	let offset = {
		top: 0,
		left: 0,
		scroll: 0,
		scrollTop : 0,
		height: rect.height,
		width: rect.width
	};
	let el = element;
	// Зацикливаемся если элемент не нулевой и не содержит класс card
	while ( !!el && !el.classList.contains(parentClass)) {
		// прибавляем смещение элемента
		offset.top += parseInt(el.offsetTop);
		offset.left = parseInt(el.offsetLeft);
		// переключаемся на родительский элемент
		el = el.offsetParent;
	}
	el = element;
	// Зацикливаемся если элемент не нулевой и не содержит класс card
	while ( !!el && !el.classList.contains(parentClass)) {
		offset.scroll += parseInt(el.scrollLeft);
		offset.scrollTop += parseInt(el.scrollTop);
		// переключаемся на родительский элемент
		el = el.parentElement;
	}

	return offset;
}

/**
 * Возвращает смещение элемента относительно карточки с таблицей ( for Relative )
 * @param {*} element Элемент ввода данных
 * @param {*} parentElement Родительский элемент
 * @returns Координаты смещения относительно карточки таблицы
 */
export const getRelativeOffsetBy = ( element, parentClass ) => {
	// Получаем размеры элемента
	const rect = element.getBoundingClientRect();
	let offset = {
		top: 0,
		left: 0,
		scroll: 0,
		scrollTop : 0,
		height: rect.height,
		width: rect.width
	};

	// Зацикливаемся если элемент не нулевой и не содержит класс card
	while ( !!element && !element.classList.contains(parentClass)) {
		// прибавляем смещение элемента
		offset.top += parseInt(element.offsetTop);
		offset.scroll += parseInt(element.scrollLeft);
		offset.scrollTop += parseInt(element.scrollTop);
		offset.left += parseInt(element.offsetLeft);
		// переключаемся на родительский элемент
		element = element.parentElement;
	}
	return offset;
}

/**
 * Возвращает глобальное смещение элемента
 * @param {*} element Элемент
 * @returns Объект с полями left и Top
 */
export const getGlobalOffset = (element) => {

	let offset = {
		top: 0,
		left: 0
	};

	while (!!element) {
		offset.top += parseInt(element.offsetTop);
		offset.left += parseInt(element.offsetLeft);
		element = element.offsetParent;
	}

	return offset;
}

/** Вспомогательная функция определяющая равно ли значение нулю */
export const isEmpty = ( value ) => {
	if ( !value && value !== false )
		return true;
	else if ( Array.isArray(value) )
		return !value.length;
	else 
		return !value;
}; 
