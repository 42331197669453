import { getCertificate, getAllCertificates, Certificate } from "crypto-pro-js";
import { ICertificateInfo } from '../Types/Certificate';
import { getUserCertificates } from "crypto-pro";

//#region Константы.
const CADESCOM_CADES_BES = 1;
const CAPICOM_CURRENT_USER_STORE = 2;
const CAPICOM_MY_STORE = "My";
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;

const INFO_NAME = 0;
const INFO_COMPANY_NAME = 1;
const INFO_EMAIL = 2;
const INFO_CITY = 3;
//#endregion

interface IResult {
	data: any;
	success: boolean; 
	message: string;
};

interface IBase64Cer {
	base64: string;
	subjectName: string;
};

class APICertificate {

	/**
	 * Метод получения списка сертификатов
	 * */
	static getCertList = async (): Promise<ICertificateInfo[]> => {
		const certList = await getAllCertificates(false);
		if (!certList.length) {
			console.warn("Не удалось получить список сертификатов.");
		}
		// Генерируем массив с промисами преобразующих сертификаты в объекты только с нужной нам инфой
		const promises = certList.map( (c) => this.prepareCertInfo(c));
		// Выполняем все промисы и возвращаем результаты
		return await Promise.all( promises ); 
	};

	static prepareCertInfo = async ( oCertificate: Certificate ): Promise<ICertificateInfo> => {
		const subjectInfo = await oCertificate.getOwnerInfo();
		const issuerInfo = await oCertificate.getIssuerInfo(); 

		//Создаем объект хранящий нужную информацию о сертификате.
		const certInfo: ICertificateInfo = {

			thumbprint: oCertificate.thumbprint,

			subjectName: subjectInfo[INFO_NAME]?.description,
			subjectCompanyName: subjectInfo[INFO_COMPANY_NAME]?.description,
			subjectEmail: subjectInfo[INFO_EMAIL]?.description,
			subjectCity: subjectInfo[INFO_CITY]?.description,

			issuerName: issuerInfo[INFO_NAME]?.description,
			issuerCompanyName: issuerInfo[INFO_COMPANY_NAME]?.description,
			issuerEmail: issuerInfo[INFO_EMAIL]?.description,
			issuerCity: issuerInfo[INFO_CITY]?.description,

			validToDate: new Date(oCertificate.validTo),
			isValid: await oCertificate.isValid(),
			valid: new Date(oCertificate.validTo) > new Date()
		};

		return certInfo;
	};

	/** Проверка работы плагина Крипто-Про  */
	static validateCryptSystem = async () => {
		await getUserCertificates();
		return true;
	};

	/** Возвращает сертификат в формате Base64 и имя владельца сертификата  */
	static getCertInfoByThumbprint = async (thumbprint:string): Promise<IBase64Cer> => {
		const cert = await getCertificate(thumbprint);
		return {
			base64: await cert.exportBase64(),
			subjectName: (await cert.getOwnerInfo())[INFO_NAME].description
		}
	};

	/** Метод подписи хэш */
	static async signHash(hash:string, hashAlgId:number, thumbprint:string): Promise<IResult> {
		const certResult = await this._getCertificate(thumbprint);
		if (!certResult.success) {
			throw new Error("Ошибка при получении сертификата. " + certResult.message);
		}

		const initResult = await this._initializeHashedData(hashAlgId, hash);
		if (!initResult.success) {
			throw new Error("Ошибка при инициализации хэш. " + initResult.message);
		}

		const signResult = await this._createSignHash(certResult.data, (initResult.data as string));
		if (!signResult.success) {
			throw new Error("Ошибка при вычислении подписи. " + signResult.message);
		}
		return signResult;
	}

	/**
	 * Метод создания хэш для подписи
	 * @param oCertificate
	 * @param oHashedData
	 */
	static _createSignHash = async (oCertificate: Certificate, oHashedData: string): Promise<IResult> => {
		// Возвращаемый результат метода
		const mr = { data: {}, success: false, message: "" };

		try {
			// Создаем объект CAdESCOM.RawSignature
			const oSignedData = await window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

			// Создаем объект CAdESCOM.CPSigner
			const oSigner = await window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
			await oSigner.propset_Certificate(oCertificate);

			mr.data = await oSignedData.SignHash(oHashedData, oSigner, CADESCOM_CADES_BES);
			mr.success = true;
		} 
		catch (err: any) {
			mr.success = false;
			mr.message = err.message;
		}
		return mr;
	}

	/**
	 * Метод инициализации объекта CAdESCOM.HashedData
	 * @param hashAlg
	 * @param sHashValue
	 */
	static _initializeHashedData = async (hashAlg: number, sHashValue: string): Promise<IResult>  => {
		// Возвращаемый результат метода
		const mr = { data: {}, success: false, message: "" };
		try {
			// Создаем объект CAdESCOM.HashedData
			const oHashedData = await window.cadesplugin.CreateObjectAsync("CAdESCOM.HashedData");

			// Инициализируем объект заранее вычисленным хэш-значением
			// Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
			oHashedData.propset_Algorithm(hashAlg);
			oHashedData.SetHashValue(sHashValue);

			mr.data = await oHashedData;
			mr.success = true;
		} 
		catch (e: any) {
			mr.success = false;
			mr.message = e.message;
		}

		return mr;
	}

	/**
	 * Метод получения сертификата по отпечатку
	 * @param thumbprint отпечаток
	 */
	static async _getCertificate(thumbprint: string): Promise<IResult> {
		// Возвращаемый результат метода
		const mr = { data: {}, success: false, message: "" };

		try {
			const oStore = await window.cadesplugin.CreateObjectAsync("CAdESCOM.Store");
			await oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE, CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

			const oCertificates = await oStore.Certificates;
			const certs = await oCertificates.Find(CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint);

			var count = await certs.Count;
			if ( !count ) {
				oStore.Close();
				mr.success = false;
				mr.message = "Не удалось найти сертификата по отпечатку: " + thumbprint + "Пустой список.";
				return mr;
			}

			const cert = await certs.Item(1);
			oStore.Close();

			mr.data = await cert;
			mr.success = true;

		} 
		catch (e:any) {
			mr.success = false;
			mr.message = e.message;
		}

		return mr;
	}

};

export default APICertificate;