import { useEffect, useState } from "react";
import { IconButton, Modal } from "src/Components";
import { useAppDispatch, useEQSelector } from "src/Hooks";
import { oListItemType } from "src/Types/ReDoc";
import { damaskGetPauseReasons } from "src/Slicers/eQueue/DamaskThunks";
import SimpleSelect, { EnumItemType, ValueType } from "src/Components/Controls/SimpleSelect";
import { ReactComponent as CapIcon } from '../Assets/cup.svg';
import { IModalProps } from ".";
import { EQueueType } from "src/Types/ReDoc/EQueue";
import { enterGetPauseReasons } from "src/Slicers/eQueue/EnterThunks";
import "./modals.scss";
import "./RedirectModal/RedirectModal.scss";

const ChoicePauseReasonsModal: React.FC<IModalProps> = ( { type, isOpen, onClose, onAction } ) => {

	const [ winNumber, setWinNumber ] = useState<ValueType|ValueType[]>("");

	const dispatch = useAppDispatch();
	const choices = useEQSelector( type, "pauseReasons" ) || [];

	useEffect( () => {
		if ( type === EQueueType.Damask )
			dispatch( damaskGetPauseReasons() );
		else 
			dispatch( enterGetPauseReasons() );
	// eslint-disable-next-line 
	}, [] );

	const renderBody: React.FC = ( props ) => (
		<div className="modal_wrapper modal_wrapper-redirect">
			<p className="rdc_modal__content__text body_large">Вы действительно хотите уйти на перерыв?</p>
			<p className="headline">Выберите причину перерыва</p>
			<SimpleSelect isOwnerList options={getEnumsByChoiceses(choices)} value={winNumber} setValue={setWinNumber} placeholder="Выберите причину..." />
		</div>
	);

	const renderModalToolbar = () => {
		return (
			<>
				<IconButton outline onClick={onClose}>Отмена</IconButton>
				<IconButton disable={!winNumber} color="error" onClick={ () => onAction( 'beginPause', winNumber )} >Уйти</IconButton> 
			</>
		)
	};

	return (
		<Modal
			isOpen={isOpen}
			title="Уйти на перерыв"
			onClose={onClose}
			color="error"
			renderIcon={<CapIcon />}
			className="not_scrolled"
			renderFooter={renderModalToolbar()}
			renderBody={renderBody}
		/>
	);
};

const getEnumsByChoiceses = ( items: oListItemType[] ): Array<EnumItemType> => {
	return items.map( (item: any) => ({
		id: item.id,
		title: !!item.text ? item.text : item.name 
	}));
};

export default ChoicePauseReasonsModal;
