import { AxiosResponse } from "axios";
import { Zoom, toast } from "react-toastify";

export default class BaseApi {

    static redirectToAuthorize = ( url: string = window.location.href ) => {
		this.showToast( false, "Авторизация просрочена. Осуществляется переход на страницу авторизации." );
		setTimeout(() => {
			window.location.href = window.host + "/new#/login?returnUrl=" + url;
		}, 2000 );
	};

    static checkUnauthorized = ( request: AxiosResponse<any, any> | Response, showMessage: boolean = false ): boolean => {
		if ( [ 401, 403 ].includes(request.status) ) {
			if ( showMessage ) {
				this.showToast( false, "Авторизация просрочена. Осуществляется переход на страницу авторизации." );
			}
			this.redirectToAuthorize();
		}
		return false;
	};

    static handleErrors = (response: any) => {
		if ( this.checkUnauthorized( response ) ) {
			throw new Error("Ошибка при получении списка вложений. Авторизационные данные устарели" );
		}
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	};

	/** Отправка Get запроса */
	static getJson = async <T>( url: string, headers: Record<string, string> = {}, withCredentials: boolean = false ): Promise<T> => new Promise( ( resolve, reject ) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url , true);
		xhr.withCredentials = withCredentials;
		if ( !!headers ) {
			for ( const key in headers ) {
				if ( Object.prototype.hasOwnProperty.call( headers, key ) ) {
					const element = headers[key];
					xhr.setRequestHeader( key, element );
				}
			}
		}
		xhr.onload = ( e: ProgressEvent  ) => {
			resolve( JSON.parse( xhr.response ) as T );
		};
		xhr.onerror = ( e ) => {
			console.info( 'Ошибка ' + xhr.status );
			reject( xhr.responseText );
		};
		xhr.send();
	});

	static fetch = async ( input: URL | string, init?: RequestInit ): Promise<Response> => new Promise( ( resolve, reject ) => {
		const xhr = new XMLHttpRequest();
		xhr.open( init?.method || 'GET', input , true);
		xhr.withCredentials = init?.credentials === "include";
		xhr.responseType = 'arraybuffer';
		if ( !!init?.headers ) {
			const headers = init.headers as any;
			for ( const key in headers ) {
				if ( Object.prototype.hasOwnProperty.call( headers, key ) ) {
					const element = headers[key];
					xhr.setRequestHeader( key, element );
				}
			}
		}
		xhr.onload = ( e: ProgressEvent  ) => {
			resolve( new Response( xhr.response, { 
				headers: { 
					"Content-Disposition": xhr.getResponseHeader("Content-Disposition") || "",
					"Content-Type": xhr.getResponseHeader("Content-Type") || ""
				} 
			}));
		};
		xhr.onerror = ( e ) => {
			console.info( 'Ошибка ' + xhr.status );
			reject( xhr.responseText );
		};
		xhr.send( init?.body as XMLHttpRequestBodyInit  );
	});

    static showToast( isSuccess: boolean, message: string, timeout: number|false = 3000 ) {

		const func = isSuccess ? "success" : "error";

		toast[func]( message, {
			position: "top-center",
			autoClose: timeout,
			hideProgressBar: true,
			closeOnClick: true,
			className: isSuccess ? "rdc_toast-success" : "rdc_toast-error",
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "colored",
			transition: Zoom,
			closeButton: false
		});

	};
};