import { ChangeEventHandler, Dispatch, FC, HTMLAttributes, SetStateAction, useRef } from "react";
import classNames from "classnames";
import { ClearBtn, FileIcon } from "src/Components";
import Button from "src/Components/Button";
import { Scan } from "src/Modules";
import st from "./FileInput.module.scss";

interface IFileInputProps extends HTMLAttributes<HTMLInputElement> {
	name: string;
	isRequired?: boolean;
	disabled?: boolean;
	title?: string;
	
	multiple?: boolean;
	accept?: string;
	clear?: boolean;

	allowScan?: boolean;
	values: Array<any>;
	setValue: Dispatch<SetStateAction<FileList | null>>;
};

/** Компонента файлового инпута */
const FileInput: FC<IFileInputProps> = ( props ) => {

	const { name, isRequired = false, multiple = false, allowScan = false, clear = true, disabled = false, title = "", accept,
		values = [], setValue,
		className, ...otherProps
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	/** Эмулирует клик по инпуту ( открывает окно выбора файла ) */
	const onChooseFile = () => {
		if ( !!inputRef.current ) {
			inputRef.current.click();
		}
	};

	/** Сохранение выбранных файлов ( метаданных ) */
	const onChangeValueHandler: ChangeEventHandler<HTMLInputElement> = ( e ) => {
		const files = !!e.target?.files && e.target.files.length ? e.target.files : null;
		if ( !!files ) {
			values.push.apply(values, files as any );
			setValue( (values as unknown) as FileList );
		}
	};

	/** Прикладываем результат сканирования */
	const setBlobFunctionHandler = async ( response: any, count: number ) => {
		const file = new File( [ await response.result.arrayBuffer() ], response.filename, { type: response.result.type } );
		values.push.apply( values, [ file ] as any );
		setValue( (values as unknown) as FileList );
	};

	/** Удаление ранее выбранного файла */
	const onDeleteFile = ( file: File, index: number ) => {
		if ( index !== -1 ) {
			const newFiles = [ ...values ];
			newFiles.splice( index, 1 );
			setValue( (newFiles as unknown) as FileList );
		}
	};

	const classes = classNames( st.rdcControl, className, {
		"disable": disabled
	});

	/** Отображение файла */
	const renderFile = ( file: File, index : number ) => {
		const { type, name, size } = file;
		return (
			<div key={ "fileItem_" + index } className={st.file}>
				<div className="icon">
					<FileIcon file={file} />
				</div>
				<div className={st.info}>
					<h5 className="headline-small">{ name }</h5>
					<span className="body-xsmall">{ getMb(size) + "     " + type }</span>
				</div>
				<div className={st.tools}>
					<ClearBtn title="Удалить" onClick={ () => onDeleteFile( file, index ) } />
				</div>
			</div>
		);
	};

	return (
		<div className={classes} {...otherProps}>
			<div className={st.controls}>
				<label className={classNames( "label", "body_large", { [st.asterix]: isRequired } )}><b>{title}</b></label>
				<input
					ref={inputRef}
					hidden
					multiple={multiple}
					accept={accept}
					type='file'
					onChange={onChangeValueHandler}
					disabled={disabled}
				/>
				{
					( !values?.length || multiple ) &&
					<div className={st.toolbar}>
						<Button iconClass="icon-plus" onClick={onChooseFile}>Загрузить</Button>
						{ !!allowScan && <Scan enable={true} setBlobFunction={setBlobFunctionHandler} /> }
						{ ( !!values?.length && clear ) && <ClearBtn onClick={() => setValue(null)} /> }
					</div>
				}
				</div>
			{
				( !!values && !!values.length ) &&
				<div className={st.files}>
					{ Array.from(values).map( renderFile ) }
				</div>
			}
		</div>
	);
};

const getMb = ( size: number ) => (size / 1024 / 1024).toFixed(1) + " Мб"

export default FileInput;
