import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useEQSelector, useOutsideClick } from "src/Hooks";
import { EQActionType, EQStateType, PointType } from "../..";
import { EQActionBtn } from "../../EQActionBtn/EQActionBtn";
import { EQueueType, WidgetType } from "src/Types/ReDoc/EQueue";
import "./EQWidget.scss";

interface IEQWidgetProps {
	state: EQStateType;
	type: EQueueType;
	onAction: (action: string) => void;
	onClose: () => void;
	position?: PointType | null;
};

const EQWidget: React.FC<IEQWidgetProps> = ({ type, position, state, onClose, onAction }) => {
	
	const pultStatus = useEQSelector( type, "widgetState" );
	const ticket = useEQSelector( type, "ticket" );
	const queueCount = useEQSelector( type, "allCount" );
	const pQueueCount = useEQSelector( type, "queuePersonCount" );
	const modalRef = useOutsideClick<HTMLDivElement>(onClose);
	const timerRef = useRef<NodeJS.Timeout>();
	const [ time, setTime ] = useState<string>('');

	useEffect( () => {
		if ( pultStatus !== WidgetType.Working || !state ) {
			if ( !!timerRef.current ) {
				clearInterval(timerRef.current);
				setTime("");
			}
			onClose();
			return;
		}
	// eslint-disable-next-line
	}, [ pultStatus, state ] );

	// Timer For Working
	useEffect( () => {
		if ( pultStatus === WidgetType.Working ) {
			setTime( calcTime(ticket?.startTime) );
			if ( !timerRef.current ) {
				console.log( "create Interval", timerRef );
				timerRef.current = setInterval( () => {
					const _time = calcTime(ticket?.startTime);
					setTime( _time );
				}, 1000);
			}
			return;
		}
		else if ( !!timerRef.current ) {
			console.log( "clear Interval", timerRef );
			clearInterval(timerRef.current);
			timerRef.current = undefined;
			setTime("");
		}
	// eslint-disable-next-line
	}, [ pultStatus ] );

	const styles = !!position ? { position: "absolute", ...position } : {};
	const count = `Ожидают ${queueCount} человек`;
	const number = ticket?.number || "";
	
	const getText = () => {
		const texts = {	title: '',	name: '' };
		const isEnter = type === EQueueType.Enter;
		switch ( pultStatus ) {
			case WidgetType.Working: {
				texts.title = time;
				texts.name = ticket.description;
				break;
			}
			case WidgetType.Calling: {
				texts.title = number;
				texts.name = ticket.description;
				break;
			}
			case WidgetType.Waiting:
			case WidgetType.OnPause: {
				if ( isEnter ) {
					texts.title = !!number ? number : `Персональная очередь - ${pQueueCount} ч.`;
					texts.name = count;
				} 
				else {
					texts.title = count;
					texts.name = number;
				}
				break;
			}
		}
		return texts;
	};

	const texts = getText();
	const actions = type === EQueueType.Enter ? state.actions?.filter( a => a.name !== "asc-redirect" ) : state?.actions;
	return (
		<div ref={modalRef} style={styles} className={"enter-eq__modal extend"}>
			{ModalHeader(state)}
			{ModalBody( texts.title, texts.name )}
			{ !!actions && ModalToolbar( type, actions, onAction)}
		</div>
	);
};

const ModalToolbar = ( type: EQueueType, actions: Array<EQActionType>, onClick: (action: string) => void ) => (
	<div className="modal__toolbar">
		{
			actions.map( action => (
				<EQActionBtn key={"eq_action_" + action.name} action={action} onClick={onClick} />
			))
		}
	</div>
);

const ModalBody = ( title: string = '', name: string = "" ) => (
	<>
		{ 
			!!title && 
			<div className="modal__body">
				<h3 className="modal__body__ticket" title={title}>{title}</h3>
			</div>
		}
		<div className="modal__body desc">
			<p className={ "modal__body__desc" } title={name}>{ name }</p>
		</div>
	</>
);

const ModalHeader = ( state: EQStateType ) => (
	<div className="modal__header" style={{ backgroundColor: state.color }}>
		<h4 className="modal__header__title">{state.text}</h4>
	</div>
);

const calcTime = ( startServingTime?: string ): string => {
	if ( !startServingTime ) return '';
	const now = moment.utc().milliseconds(0);
	const start = moment(startServingTime);
	const diff = moment.utc( now.diff(start) );
	return diff.format( "HH:mm:ss" );
};

export default EQWidget;
