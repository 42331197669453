import React, { useState, useMemo, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { useReactTable,	getCoreRowModel, ColumnDef, flexRender, TableOptions, CellContext, 
	ColumnOrderState, Row, ColumnFiltersState, ExpandedState, 
} from '@tanstack/react-table';
import { Table as BTable } from 'reactstrap';
import _ from 'lodash';
import classNames from 'classnames';
import RowToolbarCell from './Components/RowToolbar';
// Types
import { ColumnType, ITableRowToolbar, TableColumnSizing, TableFilter, TableSort } from '.';
import { SotringType } from 'src/Types';
import { FileType } from 'src/Types/File';
import { useContextMenuHelper, useManyClickHandlers } from 'src/Hooks';
import SortIcon from './Components/SortIcon';
import { ContextMenuWindow, IconButton, EmptyPanel, ContextMenuItemType } from '../';
import Filter from './Components/TableFilter/TableFilter';
import TableCell from './Components/TableCell/TableCell';
import { HeaderEmptyBox } from './Components/ExpandColumn/HeaderEmptyBox';
import { ExpandColumnCell } from './Components/ExpandColumn/ExpandColumnCell';
import { ReactComponent as ClearFilterIcon } from './assets/clearFilter.svg';
import './NewGrid.scss';

export type DataGridRefType = typeof NvxDataGrid;

export interface NvxDataGridProps {
	name: string;
	// height?: string | number;
	marginTop?: number; 
	width?: string | number;
	className?: string;
	styles?: React.CSSProperties;

	isConstructor?: boolean;
	
	isWait?: boolean;

	dataKey?: string;
	data: Array<any>;
	columns: Array<ColumnType>;

	allowFilters?: boolean;
	noDataText?: string;
	checkBoxesMode?: boolean;
	minColumnSize?: number;
	rowExpanding?: boolean;
	isMultipleSelect?: boolean;
	selectedRecords?: Array<string>;

	sort?: TableSort;
	filter?: TableFilter;
	columnSizing?: TableColumnSizing;
	rowToolbar?: ITableRowToolbar;
	
	onPrepareContextMenu?: ( data: any, selectedRecords: Array<string> ) => ContextMenuItemType[];
	/**
	 * Вызывается при клике по строке с данными
	 * @param id Идентификатор строки с данными
	 * @returns True - выбрать строку, иначе False
	 */
	onClick?: ( id: string ) => boolean;
	onDblClick?: ( id: string ) => boolean | void;
	setSelectedRecords?: Dispatch<SetStateAction<string[]>>;
	bottomToolbarRender?: () => React.ReactNode;
	onSelectionChanged?: ( state: Array<string> ) => void; 
	columnsPrepared?: ( columns: Array<ColumnType> ) => Array<ColumnType>;
	onGetRowClasses?: (rowData: any, isExpanded: boolean) => string;
	clearFilters?: () => void;
};

const NvxDataGrid: React.FC<NvxDataGridProps> = (props) => {

	const { marginTop = 80, data = [], columns, noDataText, dataKey = 'id', sort, filter, columnSizing, styles = {}, minColumnSize = 100, isWait = false,
		isMultipleSelect = false, allowFilters = false, rowToolbar, selectedRecords = [], rowExpanding = false, className = "", isConstructor = false,
		onDblClick, bottomToolbarRender, onSelectionChanged, setSelectedRecords, clearFilters, onGetRowClasses, onPrepareContextMenu, onClick
	} = props;

	const wrapperRef = useRef(null);
	const timer = useRef(null);
	const [ preparedColumns, setPreparedColumns ] = useState<ColumnDef<FileType>[]>([]);
	const [ columnVisibility, setColumnVisibility ] = useState({});
	const [ columnOrder, setColumnOrder ] = useState<ColumnOrderState>([]);
	const [ isActiveRowToolbarOn, setIsActiveRowToolbarOn ] = useState<string>('');
	const [ scroll, setScroll ] = useState<number>(0);
	const [ contextMenuInfo, onContextMenuHandler, hideContextMenu ] = useContextMenuHelper( !!onPrepareContextMenu );
	const [ rowToolbarMenuInfo, onRowToolbarClickHandler, hideTBMenu ] = useContextMenuHelper( !!rowToolbar && !!rowToolbar.onPrepareMenu );

	const onScrolled = ( e: any ) => {
		if ( !e.target ) return;
		hideContextMenu();
		hideTBMenu();
		if ( !e.target.scrollLeft ) return;
		if ( !!timer.current ) {
			clearTimeout( timer.current );
		} 
		else {
			setTimeout(() => {
				setScroll( e.target.scrollLeft );
			}, 200 );
		}	
	};

	type ExpandStateType = { [Tkey: string]: boolean };
	const [expandState, setExpandState] = React.useState<ExpandStateType>({})
	const toggleExpanded = ( recid: string ) => setExpandState( prev => ({
		...prev,
		[recid]: !prev[recid]
	}));
	const getIsExpanded = (recid: string) => expandState[recid];

	const getColumnInitialWidth = ( column: ColumnType ): number => {
		if ( !columnSizing || !columnSizing.state || !columnSizing.state[column.id] ) {
			const allWidth = !!wrapperRef.current ? (wrapperRef.current as any).state.scaledWidth : table.getTotalSize();
			return !!allWidth ? (allWidth / table.getVisibleFlatColumns().length) - 35 : 0;
		}
		if ( !!columnSizing && !!columnSizing.state[column.id] )
			return columnSizing.state[column.id];
		return !!column.width ? +column.width : minColumnSize;
	};

	useEffect( () => {
		hideContextMenu();
		hideTBMenu();
	// eslint-disable-next-line
	}, [ data ] );

	// Приводит изначальный формат описания колонок к формату ReactTable
	useEffect(() => {
		const cols = columns.map( ( column: ColumnType ) => ({
			...column,
			accessorKey: column.id,
			enableSorting: column.sortable,
			type: column.type || 'string',
			size: !!column.width ? +column.width : getColumnInitialWidth(column),
			minSize: minColumnSize,
 			maxSize: Number.MAX_SAFE_INTEGER,
			enableColumnFilter: column.filterable,
			header: () => headerCellRender(column),
			cell: cellRender,
			footer: (props: any) => props.column.id,
		}));
		setPreparedColumns(cols);
	// eslint-disable-next-line
	}, [columns]);

	// Генерируем объект описывающий видимость колонок
	useEffect(() => {
		const cols = _.sortBy(columns.filter( c => c.isVisible !== false ), [(o) => o.position]).map( (c: ColumnType) => c.id );
		setColumnOrder(cols);
		setColumnVisibility(
			columns.reduce((accumulator:any, value: any) => {
				return {...accumulator, [value.id]: value.isVisible !== false };
			  }, {})
		);
	}, [columns]);

	/** Функция изменения состояния сортировки */
	const onSortChange = ( header: any ) => {
		if ( !!sort ) {
			const newSortState: SotringType = {
				column: header.id,
				sorttype: getNextSortDir(header.id)
			};
			sort.setState( newSortState );
		}
	};
	/** Вычисляет следующие состояние сортировки  */
	const getNextSortDir = ( colId: string ) => {
		if ( !sort ) return undefined;
		if ( !sort.state )
			return "ASC";
		else if ( sort.state.column !== colId || !sort.state.sorttype )
			return "ASC";
		else if ( sort.state.sorttype === "ASC" )
			return "DESC";
		else if ( sort.state.sorttype === "DESC" )
			return undefined;
	};
	/** Возвращает текущее направление сортировки  */
	const getSortDir = ( header: any ) => {
		if ( !sort ) return undefined;
		if ( header.id === sort.state?.column )
			return sort.state?.sorttype;
		return false;
	}

	/** Отрисовываем ячейку таблицы с данными  */
	const cellRender = (props: CellContext<FileType, any>) => {
		const column = columns.find( c => c.id === props.column.id );
		return (
			<TableCell 
				isExpand={getIsExpanded(props.row.original.id)} 
				column={column} 
				data={props.row.original}
				value={props.getValue()}
				width={ props.column.getSize()  }
			/>
		);
	};

	const headerCellRender = (column: ColumnType) => {
		return (
			<div className="header_cell">
				<span>
					<b>{column.title}</b>
				</span>
			</div>
		);
	};

	/** Обработчик выбора строки таблицы */
	const onRowSelect = ( e: any, data: any ) => {
		if ( !!setSelectedRecords ) {
			setTimeout( () => {
				setSelectedRecords( prev => {
					let nextStateSelect = [...prev];
					if ( nextStateSelect.includes(data[dataKey]) && isMultipleSelect ) {
						const index = nextStateSelect.findIndex( i => i === data[dataKey] );
						nextStateSelect.splice( index, 1 );
					} 
					else if (!!isMultipleSelect) {
						nextStateSelect.push(data[dataKey]);
					}
					else {
						nextStateSelect = [data[dataKey]];
					}
					if ( !!onSelectionChanged ) {
						onSelectionChanged(nextStateSelect);
					}
					return nextStateSelect;
				});
			}, 100 );
		}
	};

	/** Функция определяет последняя ли колонка */
	const isLastColumn = ( id: string ): boolean => columnOrder.findIndex( c => c === id ) === (columnOrder.length - 1);

	/** Возвращает ширину колонки  */
	const getColumnWidth = (header: any) => {
		const isLast = isLastColumn(header.id);
		if ( isLast ) {
			const tableWidth = (wrapperRef.current as any).state.scaledWidth;
			const width = tableWidth - table.getTotalSize();
			return width;
		}
		return header.getSize();
	};

	/////////////////////// EVENTS ///////////////////////////////////////////////////////////////////////

	const singleClickHandler = ( e: any ) => {
		let needSelect = true;
		if ( !!onClick ) 
			needSelect = onClick( e.params );
		if ( needSelect ) {
			onRowSelect( e, e.params );
		}
	};

	const doubleClickHandler = ( e: any ) => {
		let needSelect = true;
		if ( !!onDblClick ) {
			needSelect = !!onDblClick(e.params);
		}
		if ( needSelect ) {
			onRowSelect( e, e.params );
		}
	};

	const clickHandler = useManyClickHandlers(singleClickHandler, doubleClickHandler);

	const onPrepareContextMenuHandler = ( data: unknown ): ContextMenuItemType[] => {
		if ( !!onPrepareContextMenu ) {
			return onPrepareContextMenu( data, selectedRecords );
		}
		return [];
	};

	const onPrepareRowTbMenuHandler = ( data: unknown ): ContextMenuItemType[] => {
		if ( !!rowToolbar?.onPrepareMenu ) {
			return rowToolbar?.onPrepareMenu( data, selectedRecords );
		}
		return [];
	};

	/////////////////////// EVENTS ///////////////////////////////////////////////////////////////////////

	/** Рендер ячейки шапки таблицы  */
	const renderHeaderCell = (header: any) => {
		const isResizing = header.column.getIsResizing();
		const headerCellClasses = classNames( "header_cell-wrapper", {
			'cursor-pointer select-none': header.column.getCanSort()
		});
		const colSpan = (isLastColumn(header.column.id) && !!renderRowToolbar ) ? 2 : header.colSpan;
		return (
			<th 
				key={header.id} 
				colSpan={ colSpan } 
				style={{ position: 'relative', minWidth: (minColumnSize + 'px'), width: getColumnWidth(header) }}
				className={classNames('rdct_header-cell', { 'isResizing': isResizing})}
			>
			{
				header.isPlaceholder? null : (
					<div className={headerCellClasses}>
						<IconButton id={"btn_sort-" + header.id} outline disable={!header.column.getCanSort()} onClick={ () => onSortChange(header)}>
							<SortIcon dir={getSortDir(header)}  />
						</IconButton>
						{flexRender( header.column.columnDef.header, header.getContext())}
					</div>
				)
			}
			{
				header.column.getCanResize() && 
				<div
					onMouseDown={header.getResizeHandler()}
					onTouchStart={header.getResizeHandler()}
					className={`resizer ${isResizing ? 'isResizing' : ''}`}>
				</div>
			}
			</th>
		);
	};

	/** Рендер ячейки шапки таблицы  */
	const renderHeaderFilterCell = (header: any, ownerWidth: number) => {
		const isFiltarable = header.column.getCanFilter();
		const isResizing = header.column.getIsResizing();
		const colSpan = (isLastColumn(header.column.id) && !!renderRowToolbar ) ? 2 : header.colSpan;
		return (
			<th 
				key={"filter_"+header.id } 
				colSpan={colSpan} 
				className={classNames('rdct_header_filter-cell', { 
						"filtrable": isFiltarable,
						'isResizing': isResizing
					} 
				)}
			>
			{
				!header.isPlaceholder &&
				<div className='rdct_header_filter__wrapper'>
					{ 
						isFiltarable && 
						<Filter 
							isConstructor={isConstructor}
							width={getColumnWidth(header)} 
							columnTypes={columns} 
							tableColumn={header.column} 
							table={table} 
							ownerWidth={ownerWidth}
						/> 
					}
				</div>
			}
			{
				header.column.getCanResize() && 
				<div
					onMouseDown={header.getResizeHandler()}
					onTouchStart={header.getResizeHandler()}
					className={`resizer ${isResizing ? 'isResizing' : ''}`}>
				</div>
			}
			</th>
		);
	}

	const renderClearFilter = () => {
		if ( !clearFilters && !rowExpanding ) 
			return null; 
		else if ( !clearFilters ) 
			return ( <HeaderEmptyBox width={32} /> );

		return (
			<th className='clear_filter' style={{ width: '32px' }} >
				<IconButton id="btn_clearfilters" data-tooltip-id="rdc_tooltip" data-tooltip-content="Сбросить все фильтры" outline onClick={() => clearFilters() }>
					<ClearFilterIcon />
				</IconButton>
			</th>
		);
	};

	/**  Рендер шапки таблицы */
	const renderTableHeader = (ownerWidth: number) => table.getHeaderGroups().map( (headerGroup, index) => (
		<thead key={ headerGroup.id + index} className='rdct_header' id={'rdc_table_header_' + index}>
			<tr key={ headerGroup.id + '_titles'} className='rdct_header-row'>
				{ renderClearFilter() }
				{ headerGroup.headers.map(renderHeaderCell) }
				<HeaderEmptyBox width={32} />
			</tr>
			{
				allowFilters &&
				<tr key={ headerGroup.id + '_filters'} className='rdct_header_filter-row'>
					{ rowExpanding && <HeaderEmptyBox width={32} /> }
					{ headerGroup.headers.map( (header) => renderHeaderFilterCell(header, ownerWidth) )}
					<HeaderEmptyBox width={32} />
				</tr>
			}
		</thead>
	));

	/**  Рендер ячейки таблицы */
	const renderCell = (cell: any) => (
		<td key={cell.id} width={getColumnWidth(cell.column)} >
			{flexRender(cell.column.columnDef.cell,	cell.getContext())}
		</td>
	);

	const renderRowToolbar = ( data: any ) => {
		if ( !!rowToolbar && !!rowToolbar.onPrepareMenu ) 
			return (
				<RowToolbarCell 
					active = { isActiveRowToolbarOn === data[dataKey] }
					data = { data }
					options = { rowToolbar }
					onClick={ (e) => {
						setIsActiveRowToolbarOn(data[dataKey]);
						onRowToolbarClickHandler( e, data );	
					}}
				/>
			)
	};

	/**  Рендер строки таблицы */
	const renderRow = (row: any) => {
		const recid = row.original[dataKey]; 
		const isExpand = getIsExpanded(recid);
		const externClass = !!onGetRowClasses ? onGetRowClasses(row, !!isExpand) : '';
		const rowClasses = classNames('rdc_datarow', externClass, { 
			'row_selected': !!selectedRecords && selectedRecords.includes(recid),
			'row_expanded': isExpand
		});
		return (
			<tr 
				key={row.id} 
				id={recid} 
				onClick={ (e) => clickHandler(e, row.original) }
				onContextMenu={ e => onContextMenuHandler( e, row.original ) }
				className={rowClasses}
			>
				{ rowExpanding && <ExpandColumnCell rowId={recid} isExpand={isExpand} onExpand={toggleExpanded}/> }			
				{ row.getVisibleCells().map( (cell: any) => renderCell(cell)) }
				{ renderRowToolbar( row.original ) }
			</tr>
		);
	};

	/**  Рендер строк таблицы */
	const renderTableRows = (rows: Row<FileType>[]) => {
		if ( !!rows.length )
			return rows.map(row => renderRow(row));
		else 
			return null;
	}

	const renderLoading = () => (
		<div className="rdc_table__emptypanel">
			<div className="rdct_table-loader rdc_spiner" />
			<p className="emptypanel_text">Ищем дела...</p>
		</div>
	);

	const renderNoData = () => (
		<EmptyPanel className="rdc_table__emptypanel">
			{noDataText}
		</EmptyPanel>
	);

	const renderMessages = ( rowsCount?: number ) => {
		if ( isWait )
			return renderLoading();
		else if ( !rowsCount )
			return renderNoData();
		else 
			return null;
	};

	// Опции таблицы
	const gridOptions: TableOptions<FileType> = useMemo( () => {
		const option: TableOptions<FileType> = {
			data,
			state: {
				columnVisibility,
				columnOrder,
				columnFilters: !!filter ? filter.state : [],
				expanded: (expandState as ExpandedState),
				columnSizing: !!columnSizing && columnSizing.state ? columnSizing.state : undefined
			},
			onColumnFiltersChange: !!filter ? filter.setState : () => {},
			onColumnVisibilityChange: setColumnVisibility,
			onColumnOrderChange: setColumnOrder,
			getCoreRowModel: getCoreRowModel(),
			columns: preparedColumns,
			enableColumnResizing: true,
			columnResizeMode: 'onChange'
		};

		if ( !!columnSizing && !!columnSizing?.setColumnSize ) {
			option.onColumnSizingChange = columnSizing.setColumnSize;
		}

		return option;
	// eslint-disable-next-line
	}, [preparedColumns, data, columnSizing?.state] );

	const table = useReactTable(gridOptions);
	const rows = table.getRowModel().rows;

	if ( !preparedColumns.length )
		return null;

	const getTotalSize = (): number => {
		const w = table.getTotalSize();
		return ( w - 64 );
	}

	const renderTable = (props: any) => {
		const { height, width, scaledWidth } = props;
		return (
			<div id="rdct_table" onScrollCapture={onScrolled} className={classNames("rdc_datagrid_wrapper new_datagrid_wrapper body-small", className)} style={{ height: (height-marginTop), width, ...styles }}>
				<BTable hover responsive style={{ minWidth: ( width - 15 ), width: getTotalSize() }}> 
					{renderTableHeader(width)}
					<tbody>
						{renderTableRows(rows)}
						<tr className='row_toolbar nothovered'>
							<td className='more_btn' style={{ transform: `translate( calc(-50% + ${(scaledWidth/2)+scroll}px), 0` }}>
								{ ( !!data.length && !!bottomToolbarRender ) && bottomToolbarRender()}
							</td>
						</tr>
					</tbody>
				</BTable>
				{ renderMessages(rows.length) }
			</div>
		);
	};

	return (
		<>
			<AutoSizer ref={wrapperRef}>
				{renderTable}
			</AutoSizer>
			{ 
				!!rowToolbar?.onPrepareMenu && 
				<ContextMenuWindow
					right={false} 
					paddingX={-25} 
					onPrepareContextMenu={onPrepareRowTbMenuHandler} 
					{...rowToolbarMenuInfo} 
					onClose={ () => setIsActiveRowToolbarOn('') }
					onOutSideClick={ (e) => {	
						setIsActiveRowToolbarOn('');
						hideTBMenu();
					}} 
				/> 
			}
			{ 
				!!onPrepareContextMenu && 
				<ContextMenuWindow 
					onClose={ () => setIsActiveRowToolbarOn('') } 
					onPrepareContextMenu={onPrepareContextMenuHandler} 
					{...contextMenuInfo} 
				/> 
			}
		</>
	);
};

export type { ColumnFiltersState };

export default NvxDataGrid;
