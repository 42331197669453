import { useEffect, useState } from "react";
import classNames from "classnames";
import { useOutsideClick } from "src/Hooks";
import { ContextMenuItemType, Point } from "../types";
import { ContextMenuItem } from "../MenuItem/ContextMenuItem";
import styles from "./ContextMenuWindow.module.scss";

export interface IContextMenuWindowProps {
	id?: string;
	isEmpty: boolean;
    position: Point;
    data: any;
    right?: boolean;
    paddingX?: number; 
    paddingY?: number; 
    onPrepareContextMenu: ( data: unknown ) => Array<ContextMenuItemType>;
	onOutSideClick?: ( e: MouseEvent | React.MouseEvent ) => void;
	onClose?: ( e?: MouseEvent | React.MouseEvent ) => void;
};

export interface IExtendMenuItem extends ContextMenuItemType {
	_extended: boolean;
}

export const ContextMenuWindow: React.FC<IContextMenuWindowProps> = ( props ) => {

	const { isEmpty, id, position, data, right = true, paddingX = 10, paddingY = 0, onPrepareContextMenu, onOutSideClick, onClose } = props;

	const onOutSideClickHandler = ( e: MouseEvent| React.MouseEvent ) => {
		if ( !!onOutSideClick ) 
			onOutSideClick(e);
		else 
			setShow(false);
	};

	const [ show, setShow ] = useState<boolean>(!isEmpty);
	const [ isRight, setIsRight ] = useState<boolean>(right);
	const [ items, setItems ] = useState<IExtendMenuItem[]>([]);
	const [ pos, setPos ] = useState<Point>(position);
	const wrapperRef = useOutsideClick<HTMLDivElement>( onOutSideClickHandler );

	useEffect(() => {
		if ( isEmpty ) {
			setShow(false);
			!!onClose && onClose();
		} 
		else {
			const _items = onPrepareContextMenu( data );
			setItems( _items.map(getExtendedItem) );
			setShow( !!_items.length );
		}
	// eslint-disable-next-line
	}, [ position, data, isEmpty ] );

	useEffect( () => {
		if ( !wrapperRef?.current ) return;
		if ( show && !isEmpty  ) {
			const _pos = { 
				x: position.x + paddingX,
				y: position.y + paddingY
			};
			let isRight = right;
			const bounding = wrapperRef.current.parentElement?.getBoundingClientRect();
			const parentX = bounding?.right || 0;
			const parentY = bounding?.bottom || 0;
			const sizeX = _pos.x + wrapperRef.current.clientWidth;
			const sizeY = _pos.y + wrapperRef.current.clientHeight;
			
			if ( sizeX > parentX ) {
				isRight = false;
			} 
			else if ( (bounding?.left || 0) < wrapperRef.current.clientWidth ) {
				isRight = true;
			}

			if ( sizeY > parentY ) {
				const diff = sizeY - parentY;
				_pos.y -= ( diff + 25 );
			}
			setIsRight( isRight );
			setPos( _pos );
		}
	// eslint-disable-next-line
	}, [show, isEmpty, position] );

	const onClickHandler = ( e: any, item: IExtendMenuItem ) => {
		if ( item.autoClose ) {
			setShow(false);
			!!onOutSideClick && onOutSideClick(e);
			!!onClose && onClose( e );
		}
		if ( !!item.onClick ) {
			item.onClick(e);
		}
		if ( item.tag === "a" && !!onOutSideClick ) {
			onOutSideClick(e);
		}
		if ( item?.subItems?.length || !!item?.subMenu ) {
			
			setItems( prev => {
				const newList = [ ...prev.map( i => ({ ...i, _extended: false }) ) ];
				const index = newList.findIndex( i => i.label === item.label );
				newList[index]._extended = !item._extended;
				return newList;
			});
		}
	};

	/** Упаковываем объект с стилями */
	const getStyles = () => ({
		left: pos.x,
		top: pos.y
	});

	if ( !show ) return null;

	return (
		<div ref={wrapperRef} id={id} className={classNames(styles.rdc_cmenu__window, isRight ? '' : styles.rdc_cmenu__window_left )} style={getStyles()}>
			<ul className={styles.rdc_cmenu__buttons}>
				{
					items.map( (item, index) => <ContextMenuItem key={index} item={item} onClick={ e => onClickHandler(e, item) } /> )
				}
			</ul>
		</div>
	);
};

const getExtendedItem = ( item: ContextMenuItemType ): IExtendMenuItem => ({
	...item,
	_extended: false
});
