import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { EQueueConfigType, WidgetType } from 'src/Types/ReDoc/EQueue';
import { EQModalStates } from 'src/Views/EQueue';
import { DenyReasonType, EnterPauseReasonType, EnterPultStatusType, EnterQueueItemType, EnterStateType } from 'src/Types/ReDoc/Enter';
import { enterGetDenyReasons, enterGetHoldMode, enterGetPauseReasons, enterGetQueue, enterGetQueueState } from './EnterThunks';

interface IEnterSlicerState {
	// Loading states
	loading: 'idle' | 'pending' | 'succeeded' | 'failed';
	error?: string;
	// Common
	config: EQueueConfigType;
	// Visual 
	widgetState: WidgetType;
	modalState: EQModalStates;

	queueCount: number;
	queuePersonCount: number;
	allCount: number;
	pauseReasons?: Array<EnterPauseReasonType>;
	queue: Array<EnterQueueItemType>;
	holdMode: number;
	denyReasons: Array<DenyReasonType>;

	ticket?: {
		id: string;
		number: string;
		inviteTime?: string;
		startTime?: string;
		description: string;
	}

	status: EnterStateType,
};

const initialState: IEnterSlicerState = {
	loading: 'idle',
	modalState: EQModalStates.None,
	widgetState: WidgetType.None,
	config: {
		contactId: '',
		login: '',
		password: '',
		officeId: -1,
		pultId: '',
		pultName: '',
		queueServerAddress: ''
	},
	queueCount: 0,
	queuePersonCount: 0,
	allCount: 0,
	status: {
		pultStatus: EnterPultStatusType.Offline,
		message: ''
	},
	queue: [],
	denyReasons: [],
	holdMode: 0
};

export const enterSlicer = createSlice({
	name: 'eQueueEnter',
	initialState,
	reducers: {
		setConfig: ( state, action: PayloadAction<EQueueConfigType> ) => {
			state.config = action.payload;
		},
		updateState: ( state, action: PayloadAction<EnterStateType> ) => {
			state.status = action.payload;
			const ticket = action.payload.ticket;
			if ( !!ticket  ) {
				state.ticket = {
					id: ticket.id,
					number: ticket.number,
					description: ticket.operation
				};
				if ( !!ticket.inviteTime ) {
					state.ticket.inviteTime = getDateTime( ticket.inviteTime ).toJSON();
				}
				if ( !!ticket.startTime ) {
					state.ticket.startTime = getDateTime( ticket.startTime ).toJSON();
				}
			} 
			else
				state.ticket = undefined; 
			state.widgetState = getWidgetStatus( action.payload.pultStatus );
		},
		askEndServiceModal: ( state, action: PayloadAction<EnterStateType> ) => {
			state.modalState = EQModalStates.AskComplete;
		},
		closeModal: ( state ) => {
			state.modalState = 0;
		},
		// Открытие формы входа
        openLoginForm: ( state ) => {
            state.modalState = EQModalStates.Login;
        },
		// Модалка об уходе на перерыв
		askCapModal: ( state ) => {
			state.modalState = EQModalStates.AskCap;
		},
		// Модалка завершения перерыва
		cancelCapModal: ( state ) => {
			state.modalState = EQModalStates.AskExitCap;
		},
		// Модалка выходе из ЭО
		askExitModal: ( state ) => {
			state.modalState = EQModalStates.Exit;
		},
		// Вызов по номеру
		inviteByNumberModal: ( state ) => {
			state.modalState = EQModalStates.AscCallByNumber;
		},
		exit: ( state, action: PayloadAction<EnterPultStatusType> ) => {
			state.status.pultStatus = action.payload;
			state.modalState = 0;
			state.widgetState = 0;
		},
		askCancelCall: ( state ) => {
			state.modalState = EQModalStates.AskCancelCall;
		},
		ascDelay: ( state ) => {
			state.modalState = EQModalStates.AskPostPone;
		},
		ascRedirect: ( state ) => {
			state.modalState = EQModalStates.AskRedirect;
		}
	},
	extraReducers: (builder) => {
		builder.addCase( enterGetQueueState.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( enterGetQueueState.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				if ( !action.payload.hasError ) {
					state.queueCount = action.payload.result.queueCount;
					state.queuePersonCount = action.payload.result.personalCount;
					state.allCount = action.payload.result.queueCount + action.payload.result.personalCount;
					state.loading ='succeeded';
				}
				else {
					state.error = action.payload.errorMessage;
					state.loading = "failed";
				}
			}
			return state;
		});
		builder.addCase( enterGetQueueState.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
		// 
		builder.addCase( enterGetPauseReasons.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( enterGetPauseReasons.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.loading ='succeeded';
				state.pauseReasons = action.payload.result as Array<EnterPauseReasonType>;
			}
		});
		builder.addCase( enterGetPauseReasons.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
		// 
		builder.addCase( enterGetQueue.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( enterGetQueue.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.loading ='succeeded';
				state.queue = action.payload as Array<EnterQueueItemType>;
			}
		});
		builder.addCase( enterGetQueue.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
		// 
		builder.addCase( enterGetHoldMode.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( enterGetHoldMode.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.loading ='succeeded';
				state.holdMode = action.payload.result;
			}
		});
		builder.addCase( enterGetHoldMode.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
		//
		builder.addCase( enterGetDenyReasons.pending, (state) => {
			state.loading = "pending";
		});
		builder.addCase( enterGetDenyReasons.fulfilled, (state, action) => {
			if ( !!action.payload && typeof action.payload !== "string" ) {
				state.loading ='succeeded';
				state.denyReasons = action.payload.result as Array<EnterQueueItemType>;
			}
		});
		builder.addCase( enterGetDenyReasons.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "failed";
		});
	}
});

const getWidgetStatus = ( status: number ): WidgetType => {
	switch( status ) {
		case EnterPultStatusType.Calling: return WidgetType.Calling;
		case EnterPultStatusType.OnPause: return WidgetType.OnPause;
		case EnterPultStatusType.Waiting: return WidgetType.Waiting;
		case EnterPultStatusType.Working: return WidgetType.Working;
		default:
		case EnterPultStatusType.Offline: return WidgetType.None;
	} 
};

const getDateTime = ( value: string ): moment.Moment => {
	return moment( value, "HH:mm:ss" ).subtract( {  hours: 2, seconds: 8 } );
};

export const infoModalActions = enterSlicer.actions;
export default enterSlicer;
